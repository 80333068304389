import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './BackgammonGame.css';
import audioManager from './AudioManager';

const BackgammonGame = () => {
    const [renderTrigger, setRenderTrigger] = useState(false);
    const { socket, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [game, setGame] = useState(null);
    const [boardState, setBoardState] = useState([]);
    const [dice, setDice] = useState([5, 5]);
    const [isRolling, setIsRolling] = useState(false);
    const [selectedChecker, setSelectedChecker] = useState(null);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [opponents, setOpponents] = useState([]);
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const DEFAULT_VOLUME = 0.8;
    const isPlayerWaitingRef = useRef(false);
    const [isSwapping, setIsSwapping] = useState(false);
    const [usedDice, setUsedDice] = useState([0, 0]); 
    const [isAnimating, setIsAnimating] = useState(false);
    const [animatingCheckers, setAnimatingCheckers] = useState({});
    const [moves, setMoves] = useState([]); 
    const [isMoveConfirmed, setIsMoveConfirmed] = useState(false);
    const [isMoveBlocked, setIsMoveBlocked] = useState(false);
    const capturedCheckersRef = useRef([]);
    
    const [backgammonShowTextMenu, setBackgammonShowTextMenu] = useState(false);
    const [backgammonShowSettingsMenu, setBackgammonShowSettingsMenu] = useState(false);
    const [backgammonPlayerMessage, setBackgammonPlayerMessage] = useState(null);
    const [backgammonOpponentMessage, setBackgammonOpponentMessage] = useState(null);
    const [isLeaveGamePopupVisible, setIsLeaveGamePopupVisible] = useState(false);
    const [isSoundOn, setIsSoundOn] = useState(true);
    audioManager.setSoundOn(isSoundOn);
    audioManager.enableSoundOnUserInteraction();

    useEffect(() => {
        const handleTouchStart = (event) => {
            event.preventDefault(); 
        };
    
        const handleClickOutside = (event) => {
            const extendedButtons = document.querySelector('.backgammon-settings-expanded');
            const textDropdown = document.querySelector('.backgammon-dropdown-menu');
    
            if (extendedButtons && !extendedButtons.contains(event.target)) {
                setBackgammonShowSettingsMenu(false);
            }
    
            if (textDropdown && !textDropdown.contains(event.target)) {
                setBackgammonShowTextMenu(false);
            }
        };
    
        document.addEventListener("pointerdown", handleClickOutside);
        document.addEventListener("touchstart", handleTouchStart, { passive: false });
    
        return () => {
            document.removeEventListener("pointerdown", handleClickOutside);
            document.removeEventListener("touchstart", handleTouchStart);
        };
    }, []);
    
    
    



    const handleBackgammonMessageSelect = (message) => {

    setBackgammonPlayerMessage(message);
    setTimeout(() => setBackgammonPlayerMessage(null), 2500);

    // Emit the message to the opponent via WebSocket
    socket.emit('backgammon-message', { message, gameId: game._id, userID: user._id });

    setBackgammonShowTextMenu(false);
  };

    // Listen for opponent's message
    useEffect(() => {
      socket.on('backgammon-message', ({ message, userID }) => {
          if(user._id !== userID){
            setBackgammonOpponentMessage(message);
        setTimeout(() => setBackgammonOpponentMessage(null), 2500);
      }
      });
  
      return () => {
        socket.off('backgammon-message');
      };
    }, []);

    // Function to handle leaving the game
    const handleLeaveGame = (e) => {
        e.stopPropagation();
        setIsLeaveGamePopupVisible(true);
    };

    const handleConfirmLeave = async (gameId) => {
        if (!gameId) {
            setIsLeaveGamePopupVisible(false);
            return;
        } 
       
        try {
            const response = await fetch('/api/leave-backgammon-game', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setIsLeaveGamePopupVisible(false);
     
            } else {
                console.error('Failed to leave game:', data.message);
            }
        } catch (error) {
            console.error('Error leaving game:', error);
        }
    };

    // Function to close the leave game popup
    const handlePopupClose = () => {
        setIsLeaveGamePopupVisible(false);
    };

    // Function to toggle sound
    const handleSoundToggle = (e) => {
        e.stopPropagation();
        setIsSoundOn((prev) => !prev);
    };



    function playBackgammonSound(soundName) {
        audioManager.setVolume(DEFAULT_VOLUME);
        audioManager.playSound(soundName);
    }

    
    const boardRef = useRef([]);  // Store the board data in a ref

    
    useEffect(() => {
        if (!game) return;

        boardRef.current = game.board;  // Storing the initial board state in the ref
    }, [game?.board]);

    
    useEffect(() => {
        if(!game) return;
        setMoves([]);  
        markUnusedDice(game);
        setIsMoveConfirmed(false); 
    }, [game?.turn]);

      useEffect(() => {
            if (!game || !user) return;

                const opponentId = game.userIds.find((id) => id !== user._id);
                const isPlayerTurn = game.turn === user._id && game.phase === 'move-checker' ;
                const isOpponentTurn = game.turn === opponentId && game.phase === 'move-checker' ;
        
                const playerTimer = document.querySelector('.backgammon-player-avatar .backgammon-timerP');
                const opponentTimer = document.querySelector('.backgammon-opponent-avatar .backgammon-timerO');
               
                if (playerTimer) {
                    if (isPlayerTurn) {
                        playerTimer.style.transition = `stroke-dashoffset ${25}s linear`;
                        setTimeout(() => {
                            playerTimer.style.strokeDashoffset = '0';
                        }, 20); 
                       
                    } else {     
                        playerTimer.style.strokeDashoffset = '188';
                        playerTimer.style.transition = 'none';
                    }
                }
                if (opponentTimer) {
                    if (isOpponentTurn) {
                        opponentTimer.style.transition = `stroke-dashoffset ${25}s linear`;
                        setTimeout(() => {
                            opponentTimer.style.strokeDashoffset = '0';
                        }, 20); 
                       
                    } else {
                        opponentTimer.style.strokeDashoffset = '188';
                        opponentTimer.style.transition = 'none';
                    }
                }
        }, [game?.turn]);


    const fetchGameState = useCallback(async () => {
            try {
                const response = await apiRequest('/api/current-game', {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                if (response.ok && data.game) {
                    if (data.game.status === 'completed') {
                        navigate('/game');
                        return;
                    }
                    console.log('data on fetch',data)
                    setIsAnimating(false);
                    markUnusedDice(data.game);
                    setMoves([]);
                    capturedCheckersRef.current = [];
                    setOpponents(data.opponents || []);
                    setGame(data.game);
                    setBoardState(data.game.board);
                    handleGamePhase(data);
                } else {
                    setGame(null);
                    navigate('/game');
                    return;
                }
            } catch (error) {
                console.error('Error fetching game state:', error);
                setGame(null);
                navigate('/game');
                return;
            }
        }, [navigate]);

        useEffect(() => {
            const handleVisibilityChange = () => {
                if (!document.hidden) {
                    fetchGameState();
                }
            };
        
            document.addEventListener('visibilitychange', handleVisibilityChange);
        
            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }, [fetchGameState]); 
    
        useEffect(() => {
            fetchGameState();
        }, [fetchGameState]);

     

     useEffect(() => {
            if (!user) {
                navigate('/login');
                return;
            }
    
            socket.on('connect', () => {
                fetchGameState();
            });
    
            
    
            socket.on('game-update', (data) => {
                if (!data.game) {
                    navigate('/game');
                    return;
                }

                if (data.game.phase === 'opponent-move'){
                    handleGamePhase(data);
                    return;
                }
    
                console.log('data on game update',data)
                setOpponents(data.opponents || []);
                if(!data.firstUpdate){
                    setGame(data.game);
                }
                
                setBoardState(data.game.board);
                setIsMoveBlocked(false);
                handleGamePhase(data);
    
            });
    
            return () => {
                socket.off('game-update');
                socket.off('connect');
                
            };
        }, [socket, user, navigate]);

    // handleGamePhase function to handle different game phases
const handleGamePhase = (data) => {
    
    switch (data.game.phase) {
        case 'game-start':
            
            handleRollAnimation(data.game);
            
            break;
        case 'roll-dice':

       
            
            break;

        case 'move-checker':
            setIsAnimating(false);
            setSelectedChecker(null); 
            if(!data.firstUpdate){
                handleRollAnimation(data.game);
            } else {
                handleDiceShiftAnimation(data.game);
            }
            
            break;
        
            case 'opponent-move':
                handleOpponentMoveAnimations(data.game);
                break;

        case 'game-completed':
            setIsGameEnd(true); // Set game to complete
            break;

        default:
            console.warn(`Unhandled game phase: ${data.game.phase}`);
    }
};

useEffect(() => {
    if (game && user && isGameEnd) {
        if (game.winners?.includes(user._id)) {
            triggerConfettiAnimation();
            audioManager.setVolume(0.8);
            audioManager.playSound('win');
        } else {
            audioManager.setVolume(0.8);
            audioManager.playSound('lose');
        }

        const timeout = setTimeout(() => {
            navigate('/game');
        }, 5000);

        return () => clearTimeout(timeout);
    }
}, [isGameEnd, game, user, navigate]);

const triggerConfettiAnimation = () => {
    const confettiContainer = document.createElement('div');
    confettiContainer.className = 'confetti-container';

    for (let i = 0; i < 200; i++) { 
        const confetti = document.createElement('div');
        confetti.className = 'confetti-piece';
        
      
        confetti.style.left = '50%';
        confetti.style.top = '50%'; 
        confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`; 
        confetti.style.transform = `rotate(${Math.random() * 360}deg)`; 

  
        const randomX = (Math.random() - 0.5) * window.innerWidth * 2; 
        const randomY = (Math.random() - 0.5) * window.innerHeight * 2;
        const duration = Math.random() * 1.4 + 0.8; 

        confetti.style.animation = `explode ${duration}s ease-out forwards`;
        confetti.style.setProperty('--randomX', `${randomX}px`); 
        confetti.style.setProperty('--randomY', `${randomY}px`); 

        confettiContainer.appendChild(confetti);
    }

    document.body.appendChild(confettiContainer);

    setTimeout(() => {
        document.body.removeChild(confettiContainer);
    }, 4000);  
};

const handleOpponentMoveAnimations = async (game) => {
    if (game.turn === user._id) return; // Exit if it's the current player's turn

    // Flatten the moveHistory array
    const opponentMoves = game.moveHistory.flat();

    for (const move of opponentMoves) {
        const checker = getCheckerAtPoint(move.currentPoint - 1, game, true); // Find the checker at the current point
        if (!checker) {
            console.error(`Checker not found at point ${move.currentPoint}`);
            continue; // Skip this move and continue with the next one
        }

        isMoveValid(checker, move.targetPoint, game, true);

        setIsAnimating(true);
        setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true })); 
    
  
        moveChecker(checker, move.targetPoint, game, capturedCheckersRef.current.length >0, () => { 
            setIsAnimating(false);   
            setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));      
        }, true);
    
        if (capturedCheckersRef.current.length >0) {
            const captured = capturedCheckersRef.current[0]; 
            setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: true }));
            setIsAnimating(true);   
        moveChecker(captured.checker, captured.bitAreaPoint, game, false, () => {
            capturedCheckersRef.current = [];
            setIsAnimating(false); 
            setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: false }));
        }, true);       
        }

      
        await new Promise((resolve) => {
            setTimeout(resolve, 600); 
        });
    }
};




const handleRollAnimation = (currentGame = null) => {
    if (isPlayerWaitingRef.current) return;

    setIsRolling(true);

    const interval = setInterval(() => {
        setDice([Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1]);
    }, 20);

    setTimeout(() => {
        clearInterval(interval);
        setDice([Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1]);
        setIsRolling(false);
        if (currentGame){
          setDice(currentGame.dice);

        } else {
            isPlayerWaitingRef.current = true;
        }
    }, 1000);
};


const handleDiceShiftAnimation = (currentGame) => {
    const isPlayerTurn = currentGame.turn === user._id;

    if (isPlayerTurn) {
        const losingDieIndex = 1;
        animateLosingDie(losingDieIndex, currentGame);
    } else {
        const playerLosingDieIndex = 1; // Player's first die
        animatePlayerLosingDie(playerLosingDieIndex, currentGame);
    }
};


const animateLosingDie = (losingDieIndex, currentGame) => {
    const dieElement = document.querySelector(`.backgammon-dice-container.opponent-dice .backgammon-dice${losingDieIndex + 1}`);
    
    if (!dieElement) {
        setGame(currentGame);
        return; 
    }

    // Calculate the shift distance
    const shiftX = 213.43;
    const shiftY = 0;

    dieElement.style.setProperty('--shift-x', `${shiftX}px`);
    dieElement.style.setProperty('--shift-y', `${shiftY}px`);
    dieElement.classList.add('shift-die');

    setTimeout(() => {
        dieElement.classList.remove('shift-die');
        setGame(currentGame);

    }, 400); 
};



const animatePlayerLosingDie = (playerLosingDieIndex, currentGame) => {
    const dieElement = document.querySelector(`.backgammon-dice-container.player-dice .backgammon-dice${playerLosingDieIndex + 1}`);
    
    if (!dieElement) {
        setGame(currentGame);
        return;
    }

    // Calculate the shift distance
    const shiftX = -129.7;
    const shiftY = 0;

    dieElement.style.setProperty('--shift-x', `${shiftX}px`);
    dieElement.style.setProperty('--shift-y', `${shiftY}px`);
    dieElement.classList.add('shift-die');

    setTimeout(() => {
        dieElement.classList.remove('shift-die');
        setGame(currentGame);
    }, 800);
};


const handleDiceClick = () => {
    if (isRolling || isSwapping || usedDice[0] || usedDice[1]) return;

    setIsSwapping(true);

    const dice1Element = document.querySelector('.backgammon-dice1');
    const dice2Element = document.querySelector('.backgammon-dice2');

    if (!dice1Element || !dice2Element) {
        console.error('Dice elements not found');
        setIsSwapping(false);
        return;
    }

    const dice1Position = dice1Element.getBoundingClientRect();
    const dice2Position = dice2Element.getBoundingClientRect();
    const shiftX = dice2Position.left - dice1Position.left;

    // Apply transform directly instead of using CSS class
    dice1Element.style.transition = 'transform 0.5s ease';
    dice2Element.style.transition = 'transform 0.5s ease';
    dice1Element.style.transform = `translateX(${shiftX}px)`;
    dice2Element.style.transform = `translateX(-${shiftX}px)`;

    // Wait for the transition to complete
    setTimeout(() => {
        setDice(([d1, d2]) => [d2, d1]); // Swap dice
        setIsSwapping(false);

        // Reset styles immediately after re-render
        requestAnimationFrame(() => {
            dice1Element.style.transition = '';
            dice2Element.style.transition = '';
            dice1Element.style.transform = '';
            dice2Element.style.transform = '';
        });
    }, 500); // Wait for the animation to complete
};

  
  
  const handlePointClick = (pointIndex, diceValue, currentGame = game) => {

    if (isSwapping) return;
    if (isAnimating) return;
    if (currentGame.phase === 'game-start') return;
    if (currentGame?.turn !== user._id || isRolling) return;  



    const pointNumber = pointIndex + 1;
    if (pointNumber < 1 || pointNumber > 26) return;

    const isDoubleDice = dice[0] === dice[1];
    

    const availableDice = usedDice[0] >= (isDoubleDice ? 2 : 1) ? (usedDice[1] >= (isDoubleDice ? 2 : 1) ? null : 1) : 0; // Decide the available dice to play

    if (availableDice === null) {
        return;  
    }

    const diceToUse = dice[availableDice];

    const checker = getCheckerAtPoint(pointIndex, currentGame);
    if (!checker) {
        return;
    }


    
    const playerColor = currentGame.playerColors[user._id]; 
    const isBlackPlayer = playerColor === 'black';

    const barPoint = isBlackPlayer ? 26 : 25;
    const barCheckers = boardRef.current[barPoint - 1]?.checkers || [];
    const isBarNotEmpty = barCheckers.length > 0;

    if (isBarNotEmpty && pointNumber !== barPoint) {
        return; 
    }

       

    let point;
    if(pointNumber === 26) {
        point = 25
    } else if (pointNumber === 25){
        point = 0
    } else {
        point = pointNumber
    }


    const targetPoint = isBlackPlayer?  point - diceToUse : point + diceToUse; 

     // If the player can bear off, move the checker to point 27 or 28 based on the color
     const canPlayerBearOff = canBearOff(currentGame,  user._id, pointNumber, targetPoint);
     if (canPlayerBearOff && (targetPoint > 24 || targetPoint < 1)) {

         setIsAnimating(true);
         setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true })); 


         const targetOnBar = isBlackPlayer ? 28 : 27;
         moveChecker(checker, targetOnBar, currentGame, capturedCheckersRef.current.length >0, () => { 
         setIsAnimating(false);   
         setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));      
         },false, targetPoint);

             // Increment the usage count for the dice
    setUsedDice(prevState => {
        const newUsedDice = [...prevState];
        newUsedDice[availableDice] += 1; // Increment the usage count
        return newUsedDice;
    });
         return;
     }

    const isMoveValidForDice = isMoveValid(checker, targetPoint, currentGame);

    if (!isMoveValidForDice && !isDoubleDice && usedDice[0] < 1 && usedDice[1] < 1){
        const otherDiceIndex = availableDice === 0 ? 1 : 0;
        const otherDiceValue = dice[otherDiceIndex]; 
        const targetPointForOtherDice = isBlackPlayer ? point - otherDiceValue : point + otherDiceValue;
    
        const canPlayerBearOffOtherDice = canBearOff(currentGame, user._id, pointNumber, targetPointForOtherDice);
        if (canPlayerBearOffOtherDice && (targetPointForOtherDice > 24 || targetPointForOtherDice < 1)) {

            setIsAnimating(true);
            setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true }));
    
            const targetOnBar = isBlackPlayer ? 28 : 27;
            moveChecker(checker, targetOnBar, currentGame, capturedCheckersRef.current.length > 0, () => {
                setIsAnimating(false);
                setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false })); 
            }, false, targetPointForOtherDice);
    
            // Mark the other dice as used
            setUsedDice(prevState => {
                const newUsedDice = [...prevState];
                newUsedDice[otherDiceIndex] += 1; // Increment the usage count for the dice used in the bear-off
                return newUsedDice;
            });
    
            return; // Exit after successful bear-off with the other dice
        }
     }

    if (!isMoveValidForDice) {
        
        const otherDiceIndex = availableDice === 0 ? 1 : 0; 
        const otherDiceValue = dice[otherDiceIndex]; 
        const targetPointForOtherDice = isBlackPlayer? point - otherDiceValue : point + otherDiceValue;

        if (usedDice[otherDiceIndex] < (isDoubleDice ? 2 : 1) && isMoveValid(checker, targetPointForOtherDice, currentGame)) {
            setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true }));
            setIsAnimating(true);
            moveChecker(checker, targetPointForOtherDice, currentGame, capturedCheckersRef.current.length >0, () => {
                    setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));
                    setIsAnimating(false);
            });
            if (capturedCheckersRef.current.length >0){
                const captured = capturedCheckersRef.current[0];  
                setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: true }));
                setIsAnimating(true);
            moveChecker(captured.checker, captured.bitAreaPoint, currentGame, false, () => {
                capturedCheckersRef.current = [];
                setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: false }));
                setIsAnimating(false); 
            });       
            }

            setUsedDice(prevState => {
                const newUsedDice = [...prevState];
                newUsedDice[otherDiceIndex] += 1; 
                return newUsedDice;
            });
            return;  
        } else {
            return;  
        }
    }

    
    

    setIsAnimating(true);
    setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true })); 

    // If the move is valid, perform the move
    moveChecker(checker, targetPoint, currentGame, capturedCheckersRef.current.length >0, () => { 
        setIsAnimating(false);   
        setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));      
    });

    if (capturedCheckersRef.current.length >0) {
        const captured = capturedCheckersRef.current[0]; 
        setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: true }));
        setIsAnimating(true);   
    moveChecker(captured.checker, captured.bitAreaPoint, currentGame, false, () => {
        capturedCheckersRef.current = [];
        setIsAnimating(false); 
        setAnimatingCheckers((prev) => ({ ...prev, [captured.checker._id]: false }));
    });       
    }

    

    // Increment the usage count for the dice
    setUsedDice(prevState => {
        const newUsedDice = [...prevState];
        newUsedDice[availableDice] += 1; // Increment the usage count
        return newUsedDice;
    });
    
    
    
    
};


const canBearOff = (game, playerId, currentPoint, targetPoint) => {
    const playerColor = game.playerColors[playerId]; 
    const isBlackPlayer = playerColor === 'black';
    let finalResult = false;

    const homePoints = !isBlackPlayer? [19, 20, 21, 22, 23, 24, 27] : [1, 2, 3, 4, 5, 6, 28]; // Home board points
    const result = boardRef.current?.every((point, index) => {
        if (point.checkers.length > 0  && point.checkers[0].owner.toString() ===  playerId.toString() && !homePoints.includes(index + 1)) {
            return false; // Checker outside home board
        }
        return true;
    });

     // If all checkers are in the home points, proceed to the next check
     if (result) {
        // Rule 1: If the target point is 25 for white or 0 for black, allow bear off
        if ((targetPoint === 25 && !isBlackPlayer) || (targetPoint === 0 && isBlackPlayer)) {
            finalResult = true;
        }
        // Rule 2: If the target point is beyond 25 for white or below 0 for black,
        // check if there are no checkers on points lower than the currentPoint for white players 
        // or on points bigger than the currentPoint for black players
        else if (
            (!isBlackPlayer && targetPoint > 25 && !boardRef.current.some((point, index) => index < currentPoint -1 && point.checkers.some(c => c.owner.toString() === playerId.toString()))) ||
            (isBlackPlayer && targetPoint < 0 && !boardRef.current.some((point, index) => index > currentPoint -1 && index <= 24 && point.checkers.some(c => c.owner.toString() === playerId.toString())))
        ) {
            finalResult = true;
        }
    }

    console.log(`Player ${playerId} can bear off: ${finalResult}`);
    return finalResult;
};





  
const getCheckerAtPoint = (pointIndex, currentGame, isOpponentAnimation = false) => {
   
    const point = boardRef.current[pointIndex];
    if (!point) return null;

    const checker = isOpponentAnimation 
    ? point.checkers.filter(c => c.owner.toString() !== user._id.toString()).pop()
    : point.checkers.filter(c => c.owner.toString() === user._id.toString()).pop();

    if (checker) {
        return { ...checker, point: pointIndex };
    }

    return null; 
};









const isMoveValid = (checker, targetPoint, currentGame = game, isOpponentAnimating = false) => {
    // Find the target point by matching its point number (1 to 24)
    const target = boardRef.current.find(point => point.point === targetPoint);
    if (!target || targetPoint > 24) return false;  // If the target point is invalid, return false



    const opponentCheckers = isOpponentAnimating 
    ? target.checkers.filter(c => {
        return c.owner.toString() === user._id.toString();  
    }) 
    : target.checkers.filter(c => {
        return c.owner.toString() !== user._id.toString();  
    });


    // If the target is occupied by more than one opponent checker, the point is secured, and the move is invalid
    if (opponentCheckers.length > 1) {
        return false;
    }

    const playerColor = currentGame.playerColors[user._id]; // Get the current player's color
    const isBlackPlayer = playerColor === 'black';

    let bitAreaPoint
    if(!isOpponentAnimating){
        bitAreaPoint = isBlackPlayer ? 25 : 26;
    } else{
        bitAreaPoint = isBlackPlayer ? 26 : 25;
    }
    

    // If the target is occupied by exactly one opponent checker, the move is valid (capture)
    if (opponentCheckers.length === 1) {
     
        const capturedChecker = opponentCheckers[0];
        capturedCheckersRef.current.push({
            checker: capturedChecker,
            bitAreaPoint: bitAreaPoint,  // Save the target bit area where it needs to be moved
            originalPoint: targetPoint
        });
        return true;
    }

    // If the target is empty or occupied by the player's own checkers, the move is valid
    return true;
};




const moveChecker = (checker, targetPoint, currentGame = game, isCaptured, callback, isOpponentMove = false, BearOffTarget = null) => {
    const currentPoint = boardRef.current.find((point) =>
        point.checkers.some((c) => c._id === checker._id)
    );
    const targetPointObj = boardRef.current.find((point) => point.point === targetPoint);

    if (!currentPoint || !targetPointObj) {
        console.error('Invalid move: point not found');
        callback();
        return;
    }

    const checkerElement = document.querySelector(`[data-checker-id="${checker._id}"]`);
    const targetElement = document.querySelector(`[data-point-id="${targetPoint}"]`);

    if (!checkerElement || !targetElement) {
        console.error('Checker or target element not found', checkerElement, targetElement);
        callback();
        return;
    }

    const currentPos = checkerElement.getBoundingClientRect();
    const targetPos = targetElement.getBoundingClientRect();

    const targetStackLength = isCaptured 
        ? targetPointObj.checkers.length - 1 
        : targetPointObj.checkers.length;

    const checkerHeight = 24.5;
    const maxCheckersWithoutOverlap = 4;
    const stackOffset = targetStackLength <= maxCheckersWithoutOverlap
        ? targetStackLength * checkerHeight 
        : maxCheckersWithoutOverlap * checkerHeight;

    const isCapturedPoint = targetPoint === 25 || targetPoint === 26;

    // Use the current player's perspective (isBlackPlayer) for rendering and animations
    const isBlackPlayer = currentGame.playerColors[user._id] === 'black';

    let targetY;
    if (isCapturedPoint) {
        targetY = targetPos.bottom - checkerHeight; // Force all checkers to the same position for captured points
    } else if (isBlackPlayer) {
        // Black player: mirrored logic
        if (targetPoint >= 13) {
            targetY = targetPos.top + stackOffset; // Points 13-24: stack top-down
        } else {
            targetY = targetPos.bottom - checkerHeight - stackOffset; // Points 1-12: stack bottom-up
        }
    } else {
        // White player: original logic
        if (targetPoint <= 12) {
            targetY = targetPos.top + stackOffset; // Points 1-12: stack top-down
        } else {
            targetY = targetPos.bottom - checkerHeight - stackOffset; // Points 13-24: stack bottom-up
        }
    }

    const shiftX = targetPos.left - currentPos.left + (targetElement.offsetWidth / 2) - (checkerElement.offsetWidth / 2);
    const shiftY = targetY - currentPos.top;

    // Force absolute positioning to prevent flicker
    checkerElement.style.position = 'absolute';

    // Animate movement using CSS transition
    checkerElement.style.transition = 'transform 0.4s ease';
    checkerElement.style.transform = `translate(${shiftX}px, ${shiftY}px)`;


    const targetForDice = BearOffTarget !== null ? BearOffTarget : targetPoint;

    const onTransitionEnd = () => {
        // Clean up the event listener
        checkerElement.removeEventListener('transitionend', onTransitionEnd);

        // Skip state updates for opponent moves
        
            // Update the board state AFTER animation completes
            requestAnimationFrame(() => {
                currentPoint.checkers = currentPoint.checkers.filter((c) => c._id !== checker._id);
                targetPointObj.checkers.push({ ...checker, point: targetPoint });
            
                if (!isOpponentMove) { 
                setMoves((prevMoves) => [
                    ...prevMoves, 
                    { checker, 
                    currentPoint: currentPoint.point, 
                    targetPoint,
                    diceUsed: isBlackPlayer && currentPoint.point === 26 
                    ? [Math.abs(targetForDice - (currentPoint.point - 1))]
                    : !isBlackPlayer && currentPoint.point === 25
                    ? [targetForDice]
                    : [Math.abs(targetForDice - currentPoint.point)] }
                ]);
             }   
                setBoardState([...boardRef.current]);
            
            });
       

        // Reset styles after React updates
        setTimeout(() => {
            checkerElement.style.transition = '';
            checkerElement.style.transform = '';
        }, 10);

        // Call the callback
        callback();
    };

    // Listen for the end of the CSS transition
    checkerElement.addEventListener('transitionend', onTransitionEnd);
};

  
const ongoingVoidAnimations = new Map(); // Track ongoing animations for each target point
const ongoingOutgoingAnimations = new Map(); // Track outgoing animations for each point

const reverseMoveChecker = (checker, currentPoint, targetPoint, currentGame, callback) => {
    const currentPointObj = boardRef.current.find((point) => point.point === currentPoint);
    const targetPointObj = boardRef.current.find((point) => point.point === targetPoint);

    if (!currentPointObj || !targetPointObj) {
        console.error('Invalid point for reverse move', currentPointObj, targetPointObj);
        callback();
        return;
    }

    const checkerElement = document.querySelector(`[data-checker-id="${checker._id}"]`);
    const currentElement = document.querySelector(`[data-point-id="${currentPoint}"]`);

    if (!checkerElement || !currentElement) {
        console.error('Checker or current point element not found');
        callback();
        return;
    }

    const currentPos = checkerElement.getBoundingClientRect();
    const currentTargetPos = currentElement.getBoundingClientRect();

    // Track ongoing incoming/outgoing animations
    const ongoingIncomingCount = ongoingVoidAnimations.get(currentPoint) || 0;
    const ongoingOutgoingCount = ongoingOutgoingAnimations.get(currentPoint) || 0;

    const targetStackLength = currentPointObj.checkers.length + ongoingIncomingCount - ongoingOutgoingCount;

    const checkerHeight = 24.5;
    const maxCheckersWithoutOverlap = 4;
    const stackOffset = targetStackLength <= maxCheckersWithoutOverlap
        ? targetStackLength * checkerHeight
        : maxCheckersWithoutOverlap * checkerHeight;

        const playerColor = currentGame.playerColors[user._id]; 
        const isBlackPlayer = playerColor === 'black';

        let targetY;
if (targetPoint === 25 || targetPoint === 26 || currentPoint === 25 || currentPoint === 26) {
    // Special case for points 25 and 26: ignore stackOffset
    if (isBlackPlayer) {
        // Black player: mirrored logic
        if (currentPoint >= 13) {
            targetY = currentTargetPos.top; // Points 13-24: stack top-down
        } else {
            targetY = currentTargetPos.bottom - checkerHeight; // Points 1-12: stack bottom-up
        }
    } else {
        // White player: original logic
        if (currentPoint <= 12) {
            targetY = currentTargetPos.top; // Points 1-12: stack top-down
        } else {
            targetY = currentTargetPos.bottom - checkerHeight; // Points 13-24: stack bottom-up
        }
    }
} else {
    // Normal case: account for stackOffset
    if (isBlackPlayer) {
        // Black player: mirrored logic
        if (currentPoint >= 13) {
            targetY = currentTargetPos.top + stackOffset; // Points 13-24: stack top-down
        } else {
            targetY = currentTargetPos.bottom - checkerHeight - stackOffset; // Points 1-12: stack bottom-up
        }
    } else {
        // White player: original logic
        if (currentPoint <= 12) {
            targetY = currentTargetPos.top + stackOffset; // Points 1-12: stack top-down
        } else {
            targetY = currentTargetPos.bottom - checkerHeight - stackOffset; // Points 13-24: stack bottom-up
        }
    }
}

    const shiftX = currentTargetPos.left - currentPos.left + (currentElement.offsetWidth / 2) - (checkerElement.offsetWidth / 2);
    const shiftY = targetY - currentPos.top;



    // Force absolute positioning to prevent flicker
    checkerElement.style.position = 'absolute';

    // Animate movement
    checkerElement.style.transition = 'transform 0.4s ease';
    checkerElement.style.transform = `translate(${shiftX}px, ${shiftY}px)`;

    // Increment ongoing animation counts
    ongoingVoidAnimations.set(currentPoint, ongoingIncomingCount + 1);
    ongoingOutgoingAnimations.set(targetPoint, (ongoingOutgoingAnimations.get(targetPoint) || 0) + 1);

    const onTransitionEnd = () => {
        checkerElement.removeEventListener('transitionend', onTransitionEnd);

        // Update board state after animation completes
        requestAnimationFrame(() => {
            targetPointObj.checkers = targetPointObj.checkers.filter((c) => c._id !== checker._id);
            currentPointObj.checkers.push({ ...checker, point: currentPoint });

            setBoardState([...boardRef.current]);

            // Reset styles after React updates
            setTimeout(() => {
                checkerElement.style.transition = '';
                checkerElement.style.transform = '';
                checkerElement.style.position = '';
            }, 0);

            // Decrement animation counters
            ongoingVoidAnimations.set(currentPoint, ongoingVoidAnimations.get(currentPoint) - 1);
            ongoingOutgoingAnimations.set(targetPoint, ongoingOutgoingAnimations.get(targetPoint) - 1);

            if (ongoingVoidAnimations.get(currentPoint) === 0) {
                ongoingVoidAnimations.delete(currentPoint);
            }
            if (ongoingOutgoingAnimations.get(targetPoint) === 0) {
                ongoingOutgoingAnimations.delete(targetPoint);
            }

            callback();
        });
    };

    // Listen for animation completion
    checkerElement.addEventListener('transitionend', onTransitionEnd);
};



useEffect(() => {
    if (!game) return;

    if (isPlayerWaitingRef.current) {
        isPlayerWaitingRef.current = false;
        setDice(game.dice);
    }

    if (game.turn.toString() !== user._id.toString()) return;

    let timeoutId;

    if (game.possibleMoves.length === 0) {
        timeoutId = setTimeout(() => {
            setUsedDice(prevState => {
                const newUsedDice = prevState.map(() => 1); // Mark all dice as used
                return newUsedDice;
            });
            handleAutoConfirm();
        }, 2500);
    } else {
        markUnusedDice(game);
    }


    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };
}, [game]); 


const markUnusedDice = (game) => {
    if (game.dice[0]!== game.dice[1] && game.possibleMoves[0]?.length < 2) {
        const availableDice = game.possibleMoves[0][0]?.diceUsed[0]; // Get the dice used in the only move
        const otherDiceIndex = availableDice === game.dice[0] ? 1 : 0; // Determine the other dice index
        
        setUsedDice([0, 0]);
        // Mark the other dice as used
        setUsedDice(prevState => {
            const newUsedDice = [...prevState];
            newUsedDice[otherDiceIndex] += 1;
            return newUsedDice;
        });
    }else{
        setUsedDice([0, 0]); 
    }
};



const handleMove = async (moves) => {
    playBackgammonSound('moveChecker');
    try {
        // Sending all moves (an array of move objects)
        await apiRequest('/api/move-backgammon-checker', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                gameId: game._id,
                moves: moves,  // Array of moves to be processed
            }),
        });
        
    } catch (error) {
        console.error('Error moving checker:', error);
    }
};


    const handleConfirmMove = () => {
        setIsMoveConfirmed(true); 
        handleMove(moves)       
    };

    const handleAutoConfirm = () => {
        setIsMoveBlocked(true); 
        handleMove([])       
    };
      
      
      
      const handleVoidMove = (currentGame = game) => {
        if (isAnimating || moves.length === 0) return;
    
        const movesToReverse = [...moves];
        const checkerMoves = {}; 

      
        movesToReverse.forEach((move) => {
            const { checker, targetPoint, currentPoint } = move;
            if (checkerMoves[checker._id]) {
                checkerMoves[checker._id].targetPoint = targetPoint;
            } else {
                checkerMoves[checker._id] = { targetPoint, currentPoint };
            }
        });

        Object.keys(checkerMoves).forEach((checkerId) => {
            const { targetPoint, currentPoint } = checkerMoves[checkerId];
            const checker = movesToReverse.find(move => move.checker._id === checkerId).checker;

            setIsAnimating(true);
            setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true }));
            reverseMoveChecker(checker, currentPoint, targetPoint, currentGame, () => {
                setIsAnimating(false); 
                setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));
            });

        });

        capturedCheckersRef.current = [];
        setMoves([]);  
        markUnusedDice(currentGame) ;
    };
    
    
    
    const calculateStepsToWin = (game, playerId) => {
        if (!game ) return 0;
    
        const playerColor = game.playerColors[playerId];
        const isBlackPlayer = playerColor === 'black';
        let totalSteps = 0;
    
        // Iterate through all points (1 to 26)
        for (let pointNumber = 1; pointNumber <= 26; pointNumber++) {
            const point = game.board[pointNumber - 1]; // Points are 0-indexed in the array
            if (point.checkers && point.checkers.length > 0 && point.checkers[0].owner === playerId) {
                let steps = 0;
    
                if (pointNumber <= 24) {
                    // Checkers on the board
                    if (isBlackPlayer) {
                        steps = pointNumber; // Black moves from 24 to 1
                    } else {
                        steps = 25 - pointNumber; // White moves from 1 to 24
                    }
                } else {
                    // Checkers in the bar (points 25 and 26)
                    steps = 25; // Each bar checker needs 25 steps (re-enter + bear off)
                }
    
                totalSteps += steps * point.checkers.length; // Multiply by number of checkers
            }
        }
    
        return totalSteps;
    };


    const renderGameEndPopup = () => {
        if (!isGameEnd || !game) return null;
    
        const winnersArray = game.winners || [];
        const losersArray = game.losers || [];
        const winnerId = winnersArray[0] || null;
        const loserId = losersArray[0] || null;
        const isUserWinner = winnerId === user._id;
        const endReason = game.endReason || null;
        
        // Calculate points based on borne-off checkers
        const whiteBorneOff = game.board[26]?.checkers?.length || 0; // Point 27 (0-based index 26)
        const blackBorneOff = game.board[27]?.checkers?.length || 0; // Point 28 (0-based index 27)
        
        const winnerPoints = game.playerColors[winnerId] === 'white' ? whiteBorneOff : blackBorneOff;
        const loserPoints = game.playerColors[loserId] === 'white' ? whiteBorneOff : blackBorneOff;
    
        return (
            <div className="backgammon-end-game-popup">
                <div className="backgammon-end-game-header">
                    <h3 className={isUserWinner ? "backgammon-win" : "backgammon-lose"}>
                        {isUserWinner ? (
                            <>
                                <span className="backgammon-end-game-emoji">🏆</span> You Won the Game!
                            </>
                        ) : (
                            <>
                                <span className="backgammon-end-game-emoji">😞</span> {game.usernames[winnerId]} Won the Game
                            </>
                        )}
                    </h3>
                </div>
    
                <div className="backgammon-end-game-results-table">
                    <div className="backgammon-end-game-results-row backgammon-end-game-header">
                        <div>Game Result</div>
                    </div>
                    
                    <div className="backgammon-end-game-results-row backgammon-end-game-score">
                        <div className={isUserWinner ? "backgammon-end-game-winner-score" : "backgammon-end-game-loser-score"}>
                            {isUserWinner ? winnerPoints : loserPoints}
                        </div>
                        <span className="backgammon-end-game-colon">:</span>
                        <div className={isUserWinner ? "backgammon-end-game-loser-score" : "backgammon-end-game-winner-score"}>
                            {isUserWinner ? loserPoints : winnerPoints}
                        </div>
                    </div>
    
                    <div className="backgammon-results-row backgammon-score-row">
                        <div className="backgammon-results-left">
                            <div className="backgammon-score-icon">
                                <img src="/icons/score-icon.svg" alt="Score" />
                            </div>
                            <div className="backgammon-current-score">{user.score}</div>
                        </div>
                        <div className={`backgammon-score-result ${isUserWinner ? 'backgammon-score-positive' : 'backgammon-score-negative'}`}>
                            {isUserWinner ? "+10" : "-5"}
                        </div>
                    </div>
    
                    <div className="backgammon-results-row backgammon-bet-row">
                        <div className="backgammon-results-left">Bet Amount</div>
                        <div className="backgammon-results-right">
                            <span className="backgammon-dollar-signBET">$</span>
                            <span className="backgammon-bet-amount">{game.betAmount}</span>
                        </div>
                    </div>
    
                    {isUserWinner && game.betAmount > 0 && (
                        <div className="backgammon-results-row backgammon-winning-row">
                            <div className="backgammon-results-left">Winnings</div>
                            <div className="backgammon-results-right">
                                <span className="backgammon-dollar-sign">$</span>
                                <span className="backgammon-winning-amount">{calculateWinnerGain(game.betAmount)}</span>
                            </div>
                        </div>
                    )}
                    
                    {isUserWinner && endReason && (
                        <div className="backgammon-end-reason-container">
                            <span className="backgammon-end-reason-icon">📝</span>
                            <span className="backgammon-end-reason-text">
                                <strong>Reason for End: </strong> {endReason}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    


    const calculateWinnerGain = (betAmount) => {
        switch (betAmount) {
            case 1:
                return 1.8;
            case 3:
                return 5;
            case 5:
                return 9;
            default:
                return 0; 
        }
    };

      
      


    const renderVoidConfirmButtons = (currentGame = game) => {
        if (isMoveConfirmed || currentGame.turn !== user._id) {
            return null;
        }
    
        const requiredMoves = currentGame.possibleMoves.length > 0 ? currentGame.possibleMoves[0]?.length : 0;
        const movesMadeCount = moves.filter(move => move.checker.owner === user._id).length;
        const playerColor = currentGame.playerColors[user._id];
        const borneOffPoint = playerColor === 'white' ? 27 : 28;
        const borneOffCount = currentGame.board[borneOffPoint - 1]?.checkers?.length || 0;
        const allCheckersBorneOff = borneOffCount >= 15;

        const confirmMoveEnabled = movesMadeCount >= requiredMoves || allCheckersBorneOff;
        const noAvailibleMoves = currentGame.possibleMoves.length === 0;
        if (noAvailibleMoves && !isMoveBlocked) return null;
    
        return (
            <>
                {movesMadeCount > 0 && (
                    <div
                    onPointerDown={() => handleVoidMove(game)}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            cursor: 'pointer',
                            zIndex: 1500,
                        }}
                    >
                        <img
                            src="/backgammonAssets/void-button.png"
                            alt="Void Move"
                            style={{ width: '32px', height: '32px' }}
                        />
                    </div>
                )}
    
                {confirmMoveEnabled && (
                    <div
                    onPointerDown={!isMoveBlocked ? handleConfirmMove : undefined} // Disable click if move is blocked
                        style={{
                            position: 'absolute',
                            left: '70%',
                            bottom: '49.5%', // Adjust vertically based on dice position
                            transform: 'translateY(50%)',
                            cursor: isMoveBlocked ? 'not-allowed' : 'pointer', // Change cursor if move is blocked
                            zIndex: 1500,
                        }}
                    >
                        <img
                            src={isMoveBlocked ? "/backgammonAssets/move-blocked.png" : "/backgammonAssets/confirm-button.png"}
                            alt={isMoveBlocked ? "Move Blocked" : "Confirm Move"}
                            style={{ width: '38px', height: '38px' }}
                        />
                    </div>
                )}
            </>
        );
    };


    const renderBackgammonControlButtons = () => {
        if (!game) return null;
    
        return (
            <div className="backgammon-game-buttonsIcons">
                {/* Text Button */}
                <button
                    className="backgammon-textIcon-buttonIcon"
                    onPointerDown={(e) => {
                        e.stopPropagation();
                        setBackgammonShowTextMenu(!backgammonShowTextMenu);
                    }}
                >
                    <img src={`/icons/icon-text.png`} alt="Text" />
                </button>
    
                {backgammonShowTextMenu && (
                    <div className="backgammon-dropdown-menu backgammon-text-menu">
                        <ul>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Hello!"); }}>Hello!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Good luck!"); }}>Good luck!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Well played!"); }}>Well played!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Thanks!"); }}>Thanks!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Oops!"); }}>Oops!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Too easy!"); }}>Too easy!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBackgammonMessageSelect("Hurry up!"); }}>Hurry up!</li>
                        </ul>
                    </div>
                )}
    
                {/* Settings Button */}
                <button
                    className={`backgammon-settingsIcon-buttonIcon ${backgammonShowSettingsMenu ? "active" : ""}`}
                    onPointerDown={(e) => {
                        e.stopPropagation();
                        setBackgammonShowSettingsMenu(!backgammonShowSettingsMenu);
                    }}
                >
                    <img src={`/icons/icon-settings.png`} alt="Settings" />
                </button>
    
                {/* Extra Buttons (Only Show When Settings is Active) */}
                {backgammonShowSettingsMenu && (
                    <div className="backgammon-settings-expanded">
                        {/* Leave Game Button */}
                        <button
                            className="backgammon-leaveGame-buttonExt"
                            onPointerDown={(e) => {
                                e.stopPropagation();
                                handleLeaveGame(e);
                            }}
                        >
                            <img src="/icons/icon-exit.png" alt="Leave Game" />
                        </button>
    
                        {/* Sound Toggle Button */}
                        <button
                            className="backgammon-soundToggle-buttonExt"
                            onPointerDown={(e) => {
                                e.stopPropagation();
                                handleSoundToggle(e);
                            }}
                        >
                            <img src={isSoundOn ? "/icons/icon-sound.png" : "/icons/icon-sound-off.png"} alt="Toggle Sound" />
                        </button>
                    </div>
                )}
            </div>
        );
    };
    
    const renderBackgammonPlayerMessage = () => {
        return (
            backgammonPlayerMessage && (
                <div className="backgammon-message-popup backgammon-player-message">
                    <div className="backgammon-message-popup-inner backgammon-player-message-inner">
                        {backgammonPlayerMessage}
                    </div>
                </div>
            )
        );
    };
    
    const renderBackgammonOpponentMessage = () => {
        return (
            backgammonOpponentMessage && (
                <div className="backgammon-message-popup backgammon-opponent-message">
                    <div className="backgammon-message-popup-inner backgammon-opponent-message-inner">
                        {backgammonOpponentMessage}
                    </div>
                </div>
            )
        );
    };


    const renderLeaveGamePopup = () => {
        return (
            isLeaveGamePopupVisible && (
                <div className="backgammon-leave-game-popup">
                    <div className="backgammon-leave-game-header">
                        <h3>Are you sure you want to leave the game?</h3>
                        <p>If you leave, your opponent will be declared the winner.</p>
                    </div>
                    <div className="backgammon-leave-game-buttons">
                        <button
                            className="backgammon-leave-game-btn"
                            onPointerDown={() => handleConfirmLeave(game?._id)}
                        >
                            Leave
                        </button>
                        <button
                            className="backgammon-leave-game-btn"
                            onPointerDown={handlePopupClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )
        );
    };

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="backgammon-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase();
        return <div className="backgammon-avatar-placeholder">{initial}</div>;
    };
    
    const renderOpponentArea = (game, user, opponents) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);
    
        if (!opponent) {
            return null;
        }

        const stepsToWin = calculateStepsToWin(game, opponent._id);

    
        return (
            <div className={`backgammon-opponent-wrapper ${game.turn === opponent._id ? 'highlighted-turn' : ''}`}>
                
                <div className="backgammon-opponent-area">
                {renderBackgammonOpponentMessage()}
                    <div className="backgammon-opponent-avatar">
                        {renderAvatar(opponent.nickname, opponent.photoUrl)}
    
                        <svg className="backgammon-timer-circleV"> {/* Use the same class name as in Carrom */}
                <defs>
                <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
                <circle className="backgammon-base" cx="50%" cy="50%" r="45%" />
                <circle className="backgammon-timerO" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
            </svg>
                    </div>
    
                    <div className="backgammon-opponent-info">
                        <div className="backgammon-name-and-flag">
                            <span className="backgammon-opponent-name">{opponent.nickname}</span>
                            {opponent.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="backgammon-country-flag"
                                />
                            )}
                        </div>
                        <span className="backgammon-opponent-score">Score: {opponent.score}</span>
                        <span className="backgammon-opponent-checkers">Steps to Win: {stepsToWin}</span>
                    </div>
                </div>
            </div>
        );
    };

    
    

    const renderBoardArea = () => {
        if (!boardRef.current || boardRef.current.length === 0 || !game) return; // Ensure board data exists
    
        const isPlayerTurn = game.turn === user._id; 
        const playerColor = game.playerColors[user._id]; 
        const isBlackPlayer = playerColor === 'black';
    
        // Reorder and rotate the points for the black player
        const reorderedPoints = isBlackPlayer
            ? [
                ...boardRef.current.slice(11, 23).reverse(),
                ...boardRef.current.slice(0, 11).reverse(), 
                ...boardRef.current.slice(23) 
            ]
            : boardRef.current; 
    
        return (
            <div className="backgammon-board-wrapper">
                <div className="backgammon-board-container">
                    <div className="backgammon-board-border">
                        <div className="backgammon-board">
                            <div className="board-grid"></div>
                            {renderGameEndPopup()}
                            {renderLeaveGamePopup()}

                            {reorderedPoints.map((point, index) => {
                                const totalCheckers = point.checkers.length;
                                const maxCheckersWithoutOverlap = 5;
                                const maxStackHeight = 98;
                                const isBearOffPoint = point.point === 27 || point.point === 28;

                                const isCapturedPoint = point.point === 25 || point.point === 26;

                                const adjustedY = isBlackPlayer && isCapturedPoint
                                ? point.point === 25 ? 135 : 233 
                                : point.y;
    
                                const overlapSize = isCapturedPoint
                                    ? 0 
                                    : totalCheckers > maxCheckersWithoutOverlap
                                        ? maxStackHeight / (totalCheckers - 1)
                                        : 24.5;
    
                                const isPointMoving = point.checkers.some(checker => animatingCheckers[checker._id]);
                                const checkerToPlay = getCheckerAtPoint(point.point -1, game);
                                const moveAllowed = checkerToPlay && !isRolling && game.turn.toString() === user._id.toString();
    
                                return (
                                    <div
                                        key={index}
                                        className={`backgammon-point backgammon-point-${point.point}`}
                                        data-point-id={point.point}
                                        style={{
                                            left: `${point.x}px`,
                                            top: isBlackPlayer && !isCapturedPoint && !isBearOffPoint ? `${400 - point.y - 120}px` : `${adjustedY}px`, 
                                            zIndex: isPointMoving ? 1000 : 1,
                                            height: isCapturedPoint ? '26px' : '120px',
                                            pointerEvents: isBearOffPoint || !moveAllowed ? 'none' : 'auto',
                                        }}
                                        onPointerDown={() => {
                                            !isBearOffPoint &&
                                            handlePointClick(point.point - 1, usedDice[0] ? dice[1] : dice[0], game);
                                        }}
                                    >
                                        {point.checkers.map((checker, checkerIndex) => {
                                            const isMoving = animatingCheckers[checker._id];
                                            const checkerColor = isBlackPlayer
                                            ? checker.color === 'black' ? 'white' : 'black' // Swap colors for the black player
                                            : checker.color;
    
                                            return (
                                                <div
                                                    key={checker._id}
                                                    className={`backgammon-checker ${checkerColor} ${selectedChecker?.point === point.point ? "stacked" : ""}`}
                                                    data-checker-id={checker._id}
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: isMoving ? 1000 : checkerIndex + 1,
                                                        ...(isBlackPlayer
                                                            ? (point.point >= 13
                                                                ? { top: `${checkerIndex * overlapSize}px` } 
                                                                : { bottom: `${checkerIndex * overlapSize}px` }) 
                                                            : (point.point <= 12
                                                                ? { top: `${checkerIndex * overlapSize}px` } 
                                                                : { bottom: `${checkerIndex * overlapSize}px` })) 
                                                    }}
                                                >
                                                    <img
                                                        src={`/backgammonAssets/checker-${checkerColor}.png`}
                                                        alt={`Checker ${checkerColor}`}
                                                        style={{
                                                            width: '24.5px',
                                                            height: '24.5px',
                                                        }}
                                                    />
    
                                                    {/* Show the total number on the top checker for points 25 & 26 if there's more than 1 checker */}
                                                    {!animatingCheckers[checker._id] && checkerIndex === totalCheckers - 1 &&
                                                        ((isCapturedPoint && totalCheckers > 1) || (!isCapturedPoint && totalCheckers > maxCheckersWithoutOverlap)) && (
                                                            <div
                                                                className="backgammon-checker-number"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    color: isBlackPlayer
                                                                    ? checker.color === 'black' ? 'black' : 'white' 
                                                                    : checker.color === 'black' ? 'white' : 'black', 
                                                                    fontSize: '11px',
                                                                    fontWeight: 'bold',
                                                                    zIndex: 1001,
                                                                }}
                                                            >
                                                                {totalCheckers}
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                       
                       
            
{((isPlayerTurn || game?.phase === 'game-start') && game?.phase !== 'opponent-move') && (
    <div className="backgammon-dice-container player-dice">
        <div
            className={`backgammon-dice1`}
            onPointerDown={() => handleDiceClick()} 
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : (usedDice[0] === 1 && dice[0] === dice[1]) ? 0.8 : usedDice[0] ? 0.6 : 1,
                transition: 'transform 0.5s ease',
                display: (game?.phase === 'game-start' && isPlayerTurn) || game?.phase !=='game-start' ? 'block' : 'none', // Show during game start phase
            }}
        >
            <img
                src={`/backgammonAssets/dice-${dice[0]}.png`} // White dice for current player
                alt={`Dice ${dice[0]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>

        <div
            className={`backgammon-dice2`}  
            onPointerDown={() => handleDiceClick()}
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : (usedDice[1] === 1 && dice[0] === dice[1]) ? 0.8 : usedDice[1] ? 0.6 : 1,
                transition: 'transform 0.5s ease',
                display: (game?.phase === 'game-start' && isPlayerTurn) ? 'none' : 'block', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/dice-${dice[1]}.png`} // White dice for current player
                alt={`Dice ${dice[1]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>
    </div>
)}

{((!isPlayerTurn || game?.phase === 'game-start')) && game?.phase !== 'opponent-move' && (
    <div className="backgammon-dice-container opponent-dice">
        

        <div
            className="backgammon-dice1"
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : 1,
                display: (game?.phase === 'game-start' && !isPlayerTurn) || game?.phase !== 'game-start' ? 'block' : 'none', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/OpponentDice-${dice[0]}.png`} // Black dice for opponent
                alt={`Opponent Dice ${dice[0]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>

      
        <div
            className="backgammon-dice2"
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : 1,
                display: (game?.phase === 'game-start' && !isPlayerTurn)  ? 'none' : 'block', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/OpponentDice-${dice[1]}.png`} // Black dice for opponent
                alt={`Opponent Dice ${dice[1]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>
    </div>
)}



                        {/* Roll Dice Button (For Current Player) OR Waiting Button (For Opponent) */}
                        {game?.phase === 'roll-dice' && game?.phase !== 'opponent-move' && !isPlayerTurn && (
    <div
        className="backgammon-waiting-button"
        style={{
            cursor: 'default',
            left: '19.5%', // Position for opponent waiting
        }}
    >
        <img
            src="/backgammonAssets/waiting-button.png"
            alt="Waiting for Opponent"
            style={{ width: '35px', height: '35px', opacity: 0.6 }}
        />
    </div>
)}
{renderVoidConfirmButtons(game)}


                    </div>
                </div>
            </div>
        </div>
    );
};

    
    
    
    

    const renderPlayerArea = (game, user) => {
        if (!user) {
            return null; 
        }

        const stepsToWin = calculateStepsToWin(game, user._id);
    
        return (
            <div className={`backgammon-player-area ${game.turn === user._id ? 'highlighted-turn' : ''}`}>
                 {renderBackgammonPlayerMessage()}
                <div className="backgammon-player-avatar">
                    {renderAvatar(user.nickname, user.photoUrl)}
                    <svg className="backgammon-timer-circleV"> {/* Use the same class name as in Carrom */}
            <defs>
                <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <circle className="backgammon-base" cx="50%" cy="50%" r="45%" />
            <circle className="backgammon-timerP" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
        </svg>
                </div>
    
                <div className="backgammon-player-info">
                    <div className="backgammon-name-and-flag">
                        <span className="backgammon-player-name">{user.nickname}</span>
                        {user.country && (
                            <img
                                src={`/flags/${user.country.toLowerCase()}.svg`}
                                alt={`${user.country} flag`}
                                className="backgammon-country-flag"
                            />
                        )}
                    </div>
                    <span className="backgammon-player-score">Score: {user.score}</span>
                    <span className="backgammon-player-checkers">Steps to Win: {stepsToWin}</span>
                </div>
                {renderBackgammonControlButtons()}
            </div>
        );
    };



        // Render cancel popup
        const renderPopup = () => {
            if (ShowCancelPopup) {
                return (
                    <div className="popup">
                        <div className="popup-inner">
                            <h3>Game Canceled</h3>
                            <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                            <button onPointerDown={() => setShowCancelPopup(false)}>Close</button>
                        </div>
                    </div>
                );
            }
        };
    
    
        if (!game) {
            return (
                <div className="backgammon-container">
                    <h2>Loading game...</h2>
                </div>
            );
        }
        

    return (
        <div className="backgammon-container">
            {renderOpponentArea(game, user, opponents)}
            
            {renderBoardArea()}
            
    
            <div className="backgammon-player-wrapper">
                {renderPlayerArea(game, user)}
            </div>
    
            {renderPopup()}
        </div>
    );
    
};

export default BackgammonGame;
