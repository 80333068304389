import React, { useEffect, useState, useContext, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './blotpage-styles.css';
import './hand-styles.css';
import './PlayerLabel.css';
import './GamePoints.css';
import './LastPlayedHand.css';
import gsap from 'gsap';
import API_BASE_URL from '../apiConfig';
import audioManager from './AudioManager';



const BlotPage = ({ setBalance }) => {
    const [game, setGame] = useState(null);
    const messageRef = useRef('');
    const [opponents, setOpponents] = useState([]);
    const [popupMessage, setPopupMessage] = useState('');
    const [selectedCard, setSelectedCard] = useState(null);
    const navigate = useNavigate();
    const { user,socket } = useContext(AuthContext);
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const [showEndGamePopup, setShowEndGamePopup] = useState(false);
    const [showTrumpDecisionPopup, setShowTrumpDecisionPopup] = useState(false);
    const [showTrumpSelectionPopup, setShowTrumpSelectionPopup] = useState(false);
    const [showRoundCanceledPopup, setShowRoundCanceledPopup] = useState(false);
    const [showTrumpFinalDecisionPopup, setShowTrumpFinalDecisionPopup] = useState(false);
    const [showRoundResultsPopup, setShowRoundResultsPopup] = useState(false);    
    const [showAnnounceCancelPopup, setShowAnnounceCancelPopup] = useState(true); 
    const localPlayedCards = useRef([]);
    const animations = useRef([]);
    const [initialDeck, setInitialDeck] = useState([]);  
    const deckInitialized = useRef(false);
    const [isMoveInProgress, setisMoveInProgress] = useState(false); 
    const playAreaRef = useRef(null);
    const playerCardsRef = useRef({});
    const initialDeckCardsRef = useRef([]);
    const allCardsAllowedRef = useRef({});
    const opponent = opponents.find(opponent => opponent._id !== user._id);
    const currentlyAnimatingCardId = useRef(null);
    const currentlyAnimatingOpponentCardId = useRef(null);
    const playerCombinationDecision = useRef('announce');
    const [renderedPlayedCards, setRenderedPlayedCards] = useState([]);
    const [combinationPopupFlags, setCombinationPopupFlags] = useState({});
    const [blotPopupFlags, setBlotPopupFlags] = useState({});
    const [showCombination, setShowCombination] = useState({});
 
    const [beloteShowTextMenu, setBeloteShowTextMenu] = useState(false);
    const [beloteShowSettingsMenu, setBeloteShowSettingsMenu] = useState(false);
    
    const [belotePlayerMessage, setBelotePlayerMessage] = useState(null);
    const [beloteOpponentMessage, setBeloteOpponentMessage] = useState(null);
    
    const [isSoundOn, setIsSoundOn] = useState(true); 
    const [isLeaveGamePopupVisible, setIsLeaveGamePopupVisible] = useState(false);
    audioManager.setSoundOn(isSoundOn);
    audioManager.enableSoundOnUserInteraction();

    const DEFAULT_VOLUME = 0.8; // Set this to the desired default volume

function playBeloteSound(soundName) {
    audioManager.setVolume(DEFAULT_VOLUME);
    audioManager.playSound(soundName);
}
    
    useEffect(() => {
        let isTouchMove = false;
    
        const handleTouchMove = (event) => {
            isTouchMove = true;
        };
    
        const handleTouchStart = (event) => {
      
            if (
                event.target.closest(".belote-dropdown-menu") ||
                event.target.closest(".belote-settings-expanded")
            ) {
                event.stopPropagation();
            }
        };
    
        const handleClickOutside = (event) => {
            if (isTouchMove) {
                isTouchMove = false; 
                return;
            }

            if (
                !event.target.closest(".belote-dropdown-menu") &&
                !event.target.closest(".belote-textIcon-buttonIcon") &&
                !event.target.closest(".belote-settingsIcon-buttonIcon") &&
                !event.target.closest(".belote-settings-expanded")
            ) {
                setBeloteShowTextMenu(false);
                setBeloteShowSettingsMenu(false);
            }
        };
    
        document.addEventListener("pointerdown", handleClickOutside);
        document.addEventListener("touchend", handleClickOutside);
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchstart", handleTouchStart, { passive: false });
    
        return () => {
            document.removeEventListener("pointerdown", handleClickOutside);
            document.removeEventListener("touchend", handleClickOutside);
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchstart", handleTouchStart);
        };
    }, []);
    

    useEffect(() => {
        socket.on('belote-message', ({ message, userID }) => {
            if(user._id !== userID){
                setBeloteOpponentMessage(message);
                setTimeout(() => setBeloteOpponentMessage(null), 3000);
            }
        });
    
        return () => {
            socket.off('belote-message');
        };
    }, []);
    
    const handleBeloteMessageSelect = (message) => {

        setBelotePlayerMessage(message);
        setTimeout(() => setBelotePlayerMessage(null), 3000);

        socket.emit('belote-message', { message, gameId: game._id, userID: user._id });
    
        setBeloteShowTextMenu(false);
    };
    
    const handleSoundToggle = (e) => {
        e.stopPropagation(); // Prevent settings from closing
        setIsSoundOn((prev) => !prev);
    };
    
    const handleLeaveGame = (e) => {
        e.stopPropagation(); // Prevent settings from closing
        setIsLeaveGamePopupVisible(true); // Show leave game popup
    };
    
    const handlePopupClose = () => {
        setIsLeaveGamePopupVisible(false); // Close the popup
    };
    
    const handleConfirmLeave = async (gameId) => {
        if (!gameId) {
            setIsLeaveGamePopupVisible(false);
            return;
        } 
    
        try {
            const response = await fetch('/api/leave-belote-game', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
               
                setIsLeaveGamePopupVisible(false);
                // Redirect player or update UI
            } else {
                console.error('Failed to leave game:', data.message);
            }
        } catch (error) {
            console.error('Error leaving game:', error);
        }
    };
    
    const renderBeloteControlButtons = () => {
        if (!game) return null;
    
        return (
            <div className="belote-game-buttonsIcons">
                {/* Text Button */}
                <button
                    className="belote-textIcon-buttonIcon"
                    onPointerDown={(e) => {
                        e.stopPropagation();
                        setBeloteShowTextMenu(!beloteShowTextMenu);
                    }}
                >
                    <img src={`/icons/icon-text.png`} alt="Text" />
                </button>
    
                {beloteShowTextMenu && (
                    <div className="belote-dropdown-menu belote-text-menu">
                        <ul>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Hello!"); }}>Hello!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Good luck!"); }}>Good luck!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Well played!"); }}>Well played!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Thanks!"); }}>Thanks!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Oops!"); }}>Oops!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Too easy!"); }}>Too easy!</li>
                            <li onPointerUp={(e) => { e.stopPropagation(); handleBeloteMessageSelect("Hurry up!"); }}>Hurry up!</li>
                        </ul>
                    </div>
                )}
    
                {/* Settings Button */}
                <button
                    className={`belote-settingsIcon-buttonIcon ${beloteShowSettingsMenu ? "active" : ""}`}
                    onPointerDown={(e) => {
                        e.stopPropagation();
                        setBeloteShowSettingsMenu(!beloteShowSettingsMenu);
                    }}
                >
                    <img src={`/icons/icon-settings.png`} alt="Settings" />
                </button>
    
                {/* Extra Buttons (Only Show When Settings is Active) */}
                {beloteShowSettingsMenu && (
                    <div className="belote-settings-expanded">
                        {/* Leave Game Button */}
                        <button
                            className="belote-leaveGame-buttonExt"
                            onPointerDown={(e) => {
                                e.stopPropagation();
                                handleLeaveGame(e);
                            }}
                        >
                            <img src="/icons/icon-exit.png" alt="Leave Game" />
                        </button>
    
                        {/* Sound Toggle Button */}
                        <button
                            className="belote-soundToggle-buttonExt"
                            onPointerDown={(e) => {
                                e.stopPropagation();
                                handleSoundToggle(e);
                            }}
                        >
                            <img src={isSoundOn ? "/icons/icon-sound.png" : "/icons/icon-sound-off.png"} alt="Toggle Sound" />
                        </button>
                    </div>
                )}
            </div>
        );
    };
    
    const renderBelotePlayerMessage = () => {
        return (
            belotePlayerMessage && (
                <div className="belote-message-popup belote-player-message">
                    <div className="belote-message-popup-inner belote-player-message-inner">
                        {belotePlayerMessage}
                    </div>
                </div>
            )
        );
    };
    
    const renderBeloteOpponentMessage = () => {
        return (
            beloteOpponentMessage && (
                <div className="belote-message-popup belote-opponent-message">
                    <div className="belote-message-popup-inner belote-opponent-message-inner">
                        {beloteOpponentMessage}
                    </div>
                </div>
            )
        );
    };
    
    const renderLeaveGamePopup = () => {
        const handleButtonClick = (event, callback) => {
            event.currentTarget.disabled = true; // Disable the clicked button
            callback();
        };
    
        return (
            isLeaveGamePopupVisible && (
                <div className="belote-leave-game-popup">
                    <div className="belote-leave-game-header">
                        <h3>Are you sure you want to leave the game?</h3>
                        <p>If you leave, your opponent will be declared the winner.</p>
                    </div>
                    <div className="belote-leave-game-buttons">
                        <button
                            className="belote-leave-game-btn"
                            onPointerDown={(e) => handleButtonClick(e, () => handleConfirmLeave(game?._id))}
                        >
                            Leave
                        </button>
                        <button
                            className="belote-leave-game-btn"
                            onPointerDown={(e) => handleButtonClick(e, handlePopupClose)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )
        );
    };
    
  

    useEffect(() => {

        document.body.style.overflow = 'hidden';
    
       
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);



    

    const getPlayArea = () => {
        const playArea = document.querySelector('.playarea');
        if (playArea) {
            playAreaRef.current = playArea;
           
        } 
    };

    useEffect(() => {
        if (game?.status === 'in-progress') {
            if (!playAreaRef.current) {
                getPlayArea(); 
            }
        }
    }, [game]);
    
   
    
    useEffect(() => {
        if (Object.keys(playerCardsRef.current).length >= 8) {
        return;
        }
    
        const playerCards = document.querySelectorAll('.player-section .card');
    
        playerCardsRef.current = {}; 
    
        playerCards.forEach(card => {
            const cardId = card.getAttribute('data-id');
            playerCardsRef.current[cardId] = card;
        });
    
        
    
    }, [game?.hands]);
    

    useEffect(() => {
        if (!game || !game.phase) {
            return;
        }
    
       
        requestAnimationFrame(() => {
            if (isMoveInProgress || !['card-played', 'trick-completed', 'in-progress'].includes(game.phase)) {
                disablePlayerCards();
            } else {
                enablePlayerCards();
            }
        });
    }, [game, isMoveInProgress]);
    
    const disablePlayerCards = () => {

        Object.values(playerCardsRef.current).forEach(card => {
            card.classList.add('disabled');
        });
    };
    
    const enablePlayerCards = () => {
      
        Object.values(playerCardsRef.current).forEach(card => {
            card.classList.remove('disabled');
        });
    };
    
  
    useEffect(() => {
        if (!game || !['initial-deal', 'deal-initial-cards', 'face-up-card-taken', 'face-up-card-skiped', 'deal-remaining-cards', 'trump-decision', 'trump-selection', 'trump-final-selection'].includes(game.phase)) {
            return; 
        }
    
        if (initialDeck.length === 0) {
 
            initializeInitialDeck(); 
        }
    
        cacheInitialDeck(game);
        
    }, [game]);
    
    const cacheInitialDeck = (game) => {

        if (game.phase !== 'deal-initial-cards' && initialDeckCardsRef.current.length > 0) {
    
            return; 
        }

        
        const initialDeckCards = document.querySelectorAll('.initial-deck-card');
    
        if (initialDeckCards.length > 0) {
            initialDeckCardsRef.current = Array.from(initialDeckCards);
 
        } else {
           
            requestAnimationFrame(cacheInitialDeck);
        }
    };

    useEffect(() => {
        const opponentId = opponents.find(opponent => opponent._id !== user._id)?._id;
        
        if (!game || !game.hands || !game.hands[user._id] || !game.hands[opponentId] || game.hands[user._id].length < 7) {
            return; 
        }
        

        if (game?.playedCards?.length === 1 || game?.playedCards?.length === 2) {
            const lastPlayedCard = game.playedCards[game.playedCards.length - 1];
    
            if (lastPlayedCard) {
                
                const cardPlayerId = lastPlayedCard.player;
    
                
                if (user._id === cardPlayerId && game.hands[user._id].length >= 7 ) {
                    showCombinationPopup(user._id); 
                }
    
                
                if (user._id !== cardPlayerId && game.hands[opponentId].length >= 7 ) {
                    showCombinationPopup(opponentId);  
                }
            }
        }
    }, [game?.playedCards]);

    useEffect(() => {
        if (!game){return;};

        if (game.blotAnnounced) {
            showBlotPopup();  
        }
    }, [game?.blotAnnounced]);
    
    
    
    useEffect(() => {
        if (!game){return;};

        if (game.showCombinations) {
            showWinerCombinations();  
        }
    }, [game?.showCombinations]);
     
        



       
        
        useEffect(() => {
        if (!user) {
                navigate('/login');
                return;
            }
            socket.on('connect', () => {
                fetchGameState();
            });
      
            socket.on('game-update', handleGameUpdate);
                                           
                return () => {
      
                socket.off('game-update', handleGameUpdate);
                socket.off('connect');
                };
        }, [user, socket, navigate]);
    
         
    

    

    
    const resettimerAnimation = (playerId) => {

    
 
        const playerElement = document.querySelector(`.player-section[data-player-id="${playerId}"], .opponent-section[data-player-id="${playerId}"]`);
        
        if (playerElement) {
   
    
            playerElement.classList.remove('waiting-for-move');
      

            void playerElement.offsetWidth;
    
       
            playerElement.classList.add('waiting-for-move');

        } 
    };
    
    
    

    const handleGameUpdate = async (data) => {

        if (!data.game) {
        return;
        }
        

    const normalizedOpponents = data.opponents.map(opponent => ({
        ...opponent,
        _id: opponent._id || opponent.id,
        nickname: opponent.nickname,
        country: opponent.country || ''
    }));

    
  
    setGame(data.game);
    setOpponents(normalizedOpponents);
    const currentTurn = data.game?.turn;



    if (currentTurn) {
        if (data.game.phase === 'in-progress') {
            if (currentTurn === user._id) {
                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {
                    resettimerAnimation(opponent._id);
                } 
            }
        }
        
     
        else if (data.game.phase === 'trump-decision') {
            if (currentTurn === user._id) {
                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {
                  
                    resettimerAnimation(opponent._id);
                } 
            }
        }

        else if (data.game.phase === 'trick-completed') {
            if (currentTurn === user._id) {

                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {

                    resettimerAnimation(opponent._id);
                }
            }
        }
    } 
    
    

        if (data.game.phase === 'game-canceled' || data.game.status === 'canceled' ) {
        setShowCancelPopup(true); 
        setShowTrumpDecisionPopup(false);
        setShowTrumpSelectionPopup(false);
        setShowTrumpFinalDecisionPopup(false);
        setInitialDeck([]);
        initialDeckCardsRef.current = []; 
        
        
        setTimeout(() => {
            setShowCancelPopup(false);
            navigate('/game'); 
        }, 4000);

        return;
         }

        
         if (data.game.status === 'in-progress' && data.game.phase === 'deal-initial-cards') {            
            playerCardsRef.current = {};
            localPlayedCards.current = [];
            await new Promise(resolve => setTimeout(resolve, 1000));
            const deckCards = initialDeckCardsRef.current;
            if (deckCards.length > 0) {
 
                animateCardDealing(data.game, normalizedOpponents);
            } else {
                
                const initialDeckCards = document.querySelectorAll('.initial-deck-card');
                if (initialDeckCards.length > 0) {
                    initialDeckCardsRef.current = Array.from(initialDeckCards);
                   
                    animateCardDealing(data.game, normalizedOpponents);
                } 
            }

        }

        
        if (data.game.status === 'in-progress' && data.game.phase === 'face-up-card-taken'){
            
            
            animateFaceUpCardToPlayer(data.game, normalizedOpponents);
           
        }

        if (data.game.status === 'in-progress' && data.game.phase === 'face-up-card-skeeped'){
            
            animateFaceUpCardToRightSide(data.game);
           
        }

        if (data.game.status === 'in-progress' && data.game.phase === 'deal-remaining-cards') {

             if (hasanimateRemainingCardDealingAnimationRun.current) {
                return;
            }
            const deckCards = initialDeckCardsRef.current;
            if (deckCards.length > 0) {
                
                animateRemainingCardDealing(data.game, normalizedOpponents);
            } 
            
        }

        

        
    
        if (data.game.phase === 'card-played' || data.game.phase === 'trick-in-progress') { 
             
            handleCardPlayedPhase(data.game); 
            removeDeck(); 
        }

        
    
    
        if (data.game.phase === 'trick-completed') {
            handleTrickCompletion(data.game);
        }

       
        if (data.game.phase === 'round-completed') {
            handleRoundCompletion(data.game);
            setShowRoundResultsPopup(true);
        }

        if (data.game.phase === 'game-completed') {
            handleTrickCompletion(data.game);
            handleGameEnded(data.game)
        }

        if (data.game.phase === 'round-canceled') {
            setPopupMessage('Round canceled. Starting a new round...');
            handleRoundCanceled();
        }

    
    };

    useEffect(() => {
        if (game) {
            handlePopups(game); 
        }
    }, [game]);

   
    const initializeInitialDeck = async () => {
    if (deckInitialized.current) {
        return;
    }
    
    const deckCards = [];
    const totalCards = 24;
    for (let i = 0; i < totalCards; i++) {
        deckCards.push({
            id: i,
            zIndex: 100 - i,
            transform: `translateY(${(totalCards - i - 1) * -0.5}px) translateX(${(totalCards - i - 1) * -0.5}px)`,
        });
    }
    setInitialDeck(deckCards); 
    deckInitialized.current = true;
    
};

    


const handleCardPlayedPhase = async (game, isInitialLoad = false) => {




if (isInitialLoad) {
    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
        ...playedCard,
        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
    }));
 } else {
    const playedCard = game.playedCards[game.playedCards.length - 1];
    const cardElement = playerCardsRef.current[playedCard._id];
    
    if (cardElement) {      
        if (playedCard.autoPlayed && !document.hidden) {

            try {
             
                playBeloteSound('opponentCardPlay');

                currentlyAnimatingCardId.current = playedCard._id;

                await moveToPlayArea(game, cardElement, playedCard, false, null,null,true );  

            } catch (error) {
                console.error('Error during auto-play card move animation:', error);
            }
        }
    }

    
                 const isOpponent = playedCard.player !== user._id;
                 if (isOpponent) {      
          
                
            
     
         const cardSrc = `/cards/${playedCard.value}_of_${playedCard.suit}.png`;
     
        
         const opponentCardElement = document.createElement('img');
         opponentCardElement.src = cardSrc;
         opponentCardElement.className = 'card in-play animating disable-hover';
         opponentCardElement.style.position = 'absolute';
         opponentCardElement.style.left = `50%`;
         opponentCardElement.style.transform = `translateX(-50%)`;
         opponentCardElement.style.top = `-50px`;
           

         document.body.appendChild(opponentCardElement);
  
         const rect = opponentCardElement.getBoundingClientRect();
         opponentCardElement.remove();
     
                  if (opponentCardElement) {
     
     
                    try {
                       
                        playBeloteSound('opponentCardPlay');
                        currentlyAnimatingOpponentCardId.current = playedCard._id;             
                        await moveToPlayArea(game, opponentCardElement, playedCard, true, cardSrc, rect,);
        
                    } catch (error) {
                        console.error('Error during auto-play card move animation:', error);
                    } 
                 
                 
                  setTimeout(() => {
                    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                        ...playedCard,
                        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
                    }));
                }, 200);
                 
             } else {
                 console.error('Opponent card element not found');
             
                 setTimeout(() => {
                    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                        ...playedCard,
                        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
                    }));
                }, 100);
             }
     
         }else {

        setTimeout(() => {
            localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                ...playedCard,
                positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
            }));
        }, 100);
    }
              
 }
 };
 
    const handleRoundCompletion = async (game) => {
       const results = game?.roundResults;
    
       if (results?.winnerId === user._id )
       {
        playBeloteSound('win');
       }
       
       if (results?.winnerId != user._id)
        {
            playBeloteSound('lose');
       }
        await resetAnimationFlags();
        await handleTrickCompletion(game); 
        vibrate(150);
        
        };
    

    const resetAnimationFlags = async () => {

        
        currentlyAnimatingFaceUpCard.current = false;
        hasanimateRemainingCardDealingAnimationRun.current = false;
        deckInitialized.current = false;
        currentlyAnimatingCardId.current = null; 
        currentlyAnimatingOpponentCardId.current = null;
        playerCardsRef.current = {};
        setCombinationPopupFlags({});
        playerCombinationDecision.current = 'announce';
        setShowAnnounceCancelPopup(true);
 

    };
    
    
    

    

    const vibrate = (pattern) => {
        if ('vibrate' in navigator) {
            navigator.vibrate(pattern);
        }
    };
 
    

    const handleGameEnded = async (game) => {
        
        const { winners = [], losers = [], winnerBalances = {}, loserBalances = {} } = game;
    
        if (user && user._id) {
    
            let message = '';
            if (winners.includes(user._id)) {
                message = 'You have won!';
                playBeloteSound('win');
                setBalance(winnerBalances[user._id]);  
            } else if (losers.includes(user._id)) {
                message = 'You lost the game';
                playBeloteSound('lose');
                setBalance(loserBalances[user._id]);  
            }

            setShowRoundResultsPopup(true);
            await new Promise(resolve => setTimeout(resolve, 3000));
            setShowRoundResultsPopup(false);
    
       
            setPopupMessage(message);
            setShowEndGamePopup(true);
            setShowTrumpDecisionPopup(false);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
    
            await resetAnimationFlags();  
            
            
            vibrate([400,50,100]);
            
            await new Promise(resolve => setTimeout(resolve, 6000)); 
    
           
            setShowEndGamePopup(false);
            
            navigate('/game');
            
           
            setGame(null);
        } else {
            console.error('User context is missing or user ID is undefined');
        }
    };
    


    const getAllowedCards = (playerHand, firstCardPlayed, trumpSuit) => {
        if (!firstCardPlayed) {
          
            return playerHand;
        }
    
        const firstCardSuit = firstCardPlayed.suit;
        const hasSuitInHand = playerHand.some(card => card.suit === firstCardSuit);
        const hasTrumpInHand = playerHand.some(card => card.suit === trumpSuit);
    
       
        if (firstCardSuit === trumpSuit) {
        
            const higherTrumpCards = playerHand.filter(card => card.suit === trumpSuit && getCardValue(card, trumpSuit) > getCardValue(firstCardPlayed, trumpSuit));
            if (higherTrumpCards.length > 0) {
                return higherTrumpCards;
            }
       
            if (hasTrumpInHand) {
                return playerHand.filter(card => card.suit === trumpSuit);
            }
       
            return playerHand;
        }
    
      
        if (hasSuitInHand) {
         
            return playerHand.filter(card => card.suit === firstCardSuit);
        }
    
        if (hasTrumpInHand) {
      
            return playerHand.filter(card => card.suit === trumpSuit);
        }
    
   
        return playerHand;
    };
    
    const getCardValue = (card, trumpSuit) => {
        if (trumpSuit === 'ANXOZ') {
           
            return {
                '9': 0, 'J': 2, 'Q': 3, 'K': 4, '10': 10, 'A': 19
            }[card.value];
        }
    
        if (card.suit === trumpSuit) {
          
            return {
                '9': 14, 'J': 20, 'Q': 3, 'K': 4, '10': 10, 'A': 11
            }[card.value];
        }
    
       
        return {
            '9': 0, 'J': 2, 'Q': 3, 'K': 4, '10': 10, 'A': 11
        }[card.value];
    };

    const showCombinationPopup = (userId) => {
        setCombinationPopupFlags((prevState) => ({
            ...prevState,
            [userId]: true, 
        }));
    
        
        setTimeout(() => {
            setCombinationPopupFlags((prevState) => ({
                ...prevState,
                [userId]: false,
            }));
        }, 3200);
    };
    

    const showBlotPopup = () => {
      
        setBlotPopupFlags({
            player: true,   
            opponent: true  
        });
    
       
        setTimeout(() => {
            setBlotPopupFlags({
                player: false,   
                opponent: false 
            });
        }, 1800);
    };


    const showWinerCombinations = () => {

        setShowCombination({
            player: true,   
            opponent: true  
        });
    
   
        setTimeout(() => {
            setShowCombination({
                player: false,   
                opponent: false 
            });
        }, 2800);
    };
    
    
    const isCardClicked = useRef(false);

    const handleCardClick = async (game,card) => {      
          
        if (isMoveInProgress) {
      
            return;
        }    

        if (isCardClicked.current) {
           return;
        }

         
         



        if (game.turn !== user._id) {
            messageRef.current = 'It is not your turn';
            return;
        }
        
 
        
        const allowedCards = getAllowedCards(game.hands[user._id], game.playedCards[0], game.trumpSuit);
        const isAllowedCard = allowedCards.some(
        (allowedCard) => allowedCard.suit === card.suit && allowedCard.value === card.value
        );

        if (!isAllowedCard) {
        return; 
        }        

    
        playBeloteSound('playerCardPlay');

        allCardsAllowedRef.current[user._id] = true;      
    
        const cardElement = playerCardsRef.current[card._id];

        if (cardElement) { 
            isCardClicked.current = true;          
         
            currentlyAnimatingCardId.current = card._id; 
                        
            try {
          
             
                const updatedHands = { ...game.hands };
                updatedHands[user._id] = updatedHands[user._id].filter(c => c._id !== card._id);
                setGame({ ...game, hands: updatedHands });                
                await moveToPlayArea(game, cardElement, card);  
                await handleMove(card);  
                isCardClicked.current = false;
                            
                               
            } catch (error) {
                console.error('Error during card move:', error);               
            } finally {              
                allCardsAllowedRef.current[user._id] = false;
             
                isCardClicked.current = false;
            }
        } else {
            console.error('Card element not found for animation');
        }
    };

    const handleMove = async (card) => {

    try {

    
        const response = await apiRequest(`${API_BASE_URL}/api/blot/make-move`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gameId: game._id, card, combinationDecision: playerCombinationDecision.current })
        });

        const data = await response.json();
        if (response.ok) {
            messageRef.current = 'Move made successfully';
            setSelectedCard(null); 
            setGame(data.game);
     
        } else {
            messageRef.current = 'Failed to make move';
        }
    } catch (err) {
        messageRef.current = 'An error occurred';
    }
};




  

const moveToPlayArea = async (game, cardElement, playedCard, isOpponentCard = false, cardSrc = null, initialRect = null, isBotMove = false) => {
 
    return new Promise(resolve => {
        let clonedCard;

        const playArea = playAreaRef.current;

        if (!playArea) {
        console.error('Play area not found');
        
        if (clonedCard && document.body.contains(clonedCard)) {
            clonedCard.remove();
            clonedCard.classList.remove('animating', 'disable-hover'); // Clean up CSS classes
        }
        resolve();
        return;
        };      
              
        let isSecondCard;

        if (isBotMove) {
            isSecondCard = (game.moveCounter % 2 === 1); 
        } else if (!isOpponentCard) {
            isSecondCard = game.moveCounter % 2 === 0; 
        } else {
            isSecondCard = (game.moveCounter - 1) % 2 === 0; 
        }

        if (document.hidden) {
            if (clonedCard && document.body.contains(clonedCard)) {
            clonedCard.remove();
            clonedCard.classList.remove('animating', 'disable-hover'); // Clean up CSS classes
            }
            if (!isSecondCard) {
                setisMoveInProgress(false);  
            }
            resolve();
            return;
        }      
        
       
        if (isOpponentCard && cardSrc && initialRect) {
            // If it's an opponent card, use the src and rect provided
            clonedCard = cardElement.cloneNode(true);
            clonedCard.src = cardSrc; // Set the front face for the opponent
            clonedCard.className = `card in-play ${isSecondCard ? 'second-card-position' : 'first-card-position'} animating disable-hover`;
            clonedCard.style.position = 'absolute';
            clonedCard.style.left = `${initialRect.left}px`;
            clonedCard.style.top = `${initialRect.top}px`;
            clonedCard.style.width = `${initialRect.width}px`;
            clonedCard.style.height = `${initialRect.height}px`;
            clonedCard.style.zIndex = '1';
            clonedCard.style.transform = 'none'; // Reset transform to avoid conflicts
            document.body.appendChild(clonedCard);
        } else {
                clonedCard = cardElement.cloneNode(true);
                clonedCard.className = `card in-play ${isSecondCard ? 'second-card-position' : 'first-card-position'} animating disable-hover`;
                const rect = cardElement.getBoundingClientRect();
                const isValidPosition = rect && rect.left !== 0 && rect.top !== 0;
                if (isValidPosition) {
                    clonedCard.style.left = `${rect.left}px`;
                    clonedCard.style.top = `${rect.top}px`;
                } else {
                    clonedCard.style.left = '50%';
                    clonedCard.style.top = '90%';
                    clonedCard.style.transform = 'translateX(-50%)';
                }
                clonedCard.style.position = 'absolute';
                clonedCard.style.zIndex = '1';
                document.body.appendChild(clonedCard);
        }      
        setisMoveInProgress(true);

        requestAnimationFrame(() => {
            const playAreaRect = playArea.getBoundingClientRect();
            const rect = clonedCard.getBoundingClientRect();

            const xOffset = playAreaRect.left - rect.left + (playAreaRect.width / 2 - rect.width / 2);
            const yOffset = playAreaRect.top - rect.top + (playAreaRect.height / 2 - rect.height / 2);

            const xOffsetAdjusted = isSecondCard ? xOffset + 20 : xOffset;
            const yOffsetAdjusted = isSecondCard ? yOffset + 10 : yOffset;
            const rotationAngle = isSecondCard ? 15 : 0;

            // Add CSS transition for smooth animation
            clonedCard.style.transition = 'transform 0.38s ease-in-out';
            clonedCard.style.transform = `translate(${xOffsetAdjusted}px, ${yOffsetAdjusted}px) rotate(${rotationAngle}deg)`;

            // Wait for the transition to complete
            const onTransitionEnd = () => {
                clonedCard.removeEventListener('transitionend', onTransitionEnd);

                if (!isSecondCard) {
                    setisMoveInProgress(false);
                }

                if (!localPlayedCards.current.some(card => card._id === playedCard._id)) {
                    localPlayedCards.current = [
                        ...localPlayedCards.current,
                        {
                            ...playedCard,
                            positionClass: !isSecondCard ? 'first-card-position' : 'second-card-position'
                        }
                    ];
                }

                resolve();

                setTimeout(() => {
                    if (clonedCard && document.body.contains(clonedCard)) {
                        setRenderedPlayedCards([...localPlayedCards.current]);
                        clonedCard.remove();
                        clonedCard.classList.remove('animating', 'disable-hover');
                    }
                }, 0);
            };

            clonedCard.addEventListener('transitionend', onTransitionEnd, { once: true });
        });
    });
};


const handleTrickCompletion = async (game) => {
    setisMoveInProgress(false);
    
    const lastPlayedCards = game.lastPlayedCards;

    if (lastPlayedCards.length > 1) {

        const playCards = lastPlayedCards.map(card => ({
            ...card.card,
            playerId: card.playerId 
        }));

        try {

            await animateCardsToWinner(playCards, game);           
        } catch (error) {
            console.error('Error during trick completion:', error);
        }
    }
};


const animateCardsToWinner = async (playCards, game) => {
    try {        
        const trickWinnerId = game.trickWinner;
        
        if (document.hidden) {

            return;
        }

        const windowHeight = window.innerHeight;
        const targetY = trickWinnerId === user._id ? windowHeight : -windowHeight;

        const animations = playCards.map(card => {
            const cardElement = document.querySelector(`.playarea .card[data-id="${card._id}"]`);
            if (cardElement) {
             
                return new Promise(resolve => {
                    cardElement.style.transition = 'transform 0.8s ease-out, opacity 0.8s ease-out';
                    cardElement.style.transform = `translate(0, ${targetY}px)`;
                    cardElement.style.opacity = '0';

                    const onTransitionEnd = () => {
                      
                        cardElement.removeEventListener('transitionend', onTransitionEnd);
                        resolve();
                    };
                    cardElement.addEventListener('transitionend', onTransitionEnd, { once: true });
                });
            } else {
                console.warn('Card element not found:', card._id);
                return Promise.resolve();
            }
        });

        await Promise.all(animations);

    } catch (error) {
        console.error('Error during card animations:', error);
    }finally {
   
      //  localPlayedCards.current = [];

    }
};






    const handlePopups = (game) => {
        if (game.phase === 'trump-decision') {
            setShowTrumpDecisionPopup(true);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else if (game.phase === 'trump-selection') {
            setShowTrumpSelectionPopup(true);
            setShowTrumpDecisionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else if (game.phase === 'trump-final-selection') {
            setShowTrumpFinalDecisionPopup(true);
            setShowTrumpSelectionPopup(false);
            setShowTrumpDecisionPopup(false);
        } else if (game.phase === 'round-canceled') {
            setShowTrumpSelectionPopup(false);
            setShowTrumpDecisionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else {
            setShowTrumpDecisionPopup(false);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        }
    };

    const handleRoundCanceled = () => {
        setShowRoundCanceledPopup(true);
        
        // resetAnimationFlags();
        currentlyAnimatingFaceUpCard.current = false;
        hasanimateRemainingCardDealingAnimationRun.current = false;
        deckInitialized.current = false;
        currentlyAnimatingCardId.current = null; 
        currentlyAnimatingOpponentCardId.current = null;
        playerCardsRef.current = {};
        setCombinationPopupFlags({});
        


        setTimeout(() => {
            setShowRoundCanceledPopup(false);
        }, 3000); 
    };

    const handleTrumpDecision = async (takeCard) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/trump-decision` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, takeCard }) // Ensure `takeCard` is sent
            });

            const data = await response.json();
            if (response.ok) {
                messageRef.current = 'Trump decision made successfully';
                setGame(data.game); 
            } else {
                messageRef.current = data.message || 'Failed to make trump decision';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
            console.error(err);
        }
    };

    const handleTrumpSelection = async (suit) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/trump-selection` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, suit }) 
            });

            const data = await response.json();
            if (response.ok) {
                messageRef.current = 'Trump selection made successfully';
                setGame(data.game); 

                
                if (suit === 'pass') {
                    setShowTrumpSelectionPopup(true); 
                } else {
                    setShowTrumpSelectionPopup(false);
                }
            } else {
                messageRef.current = data.message || 'Failed to select trump';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
        }
    };

    const handleFinalTrumpDecision = async (withCard) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/final-trump-decision` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, withCard })
            });

            const data = await response.json();
            if (response.ok) {
                setGame(data.game); 
                setPopupMessage('');
                setShowTrumpFinalDecisionPopup(false); 
            } else {
                messageRef.current = data.message || 'Failed to make final trump decision';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
        }
    };



    const animateCardDealing = (game, normalizedOpponents) => {
        if (!game) return;
    
        const turnPlayerId = game.turn;
        const opponentId = normalizedOpponents.find(opponent => opponent._id !== turnPlayerId)?._id;
    
        if (document.hidden) {
 
            return;
        }
    
        if (!opponentId) {
            console.error('Opponent ID is undefined. Opponents:', normalizedOpponents);
            return;
        }
    
        const dealCardToPlayer = (playerId, index, delay) => {
            const deckCards = initialDeckCardsRef.current;
            const cardElement = deckCards[index];  
            if (!cardElement) return;

           
    
            const animationClass = playerId === user._id
                ? 'ppbet-dealCardToPlayer'
                : 'ppbet-dealCardToOpponent';
    
         
            cardElement.style.animation = `${animationClass} 0.9s ${delay}s ease-in-out forwards`;
    
            return new Promise((resolve) => {
               
                cardElement.addEventListener('animationend', () => {
                    if (document.body.contains(cardElement)) {
                        cardElement.remove();
                        initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== cardElement);
                    }
                    resolve();
                });
            });
        };
    
        const dealCards = () => {
            
    
            playBeloteSound('opponentDealCard');
            for (let i = 0; i < 5; i++) {
                dealCardToPlayer(turnPlayerId, i, i * 0.06);
            }
      
          setTimeout(() =>  playBeloteSound('playerDealCard'), 1200);
            for (let i = 0; i < 5; i++) {
                dealCardToPlayer(opponentId, i + 5, i * 0.06 + 1.2);
            }
        };
    
        dealCards();
    };
    
    


const currentlyAnimatingFaceUpCard = useRef(false);
    

    
const animateFaceUpCardToPlayer = (game, normalizedOpponents) => {
   
    if (!game){
        return;
    }
    

    const playerId = game.faceUpCardTakenBy;
    const faceUpCardElement = document.querySelector('.face-up-card .Fcard'); 
    
    if (!faceUpCardElement || currentlyAnimatingFaceUpCard.current) return;




    if (document.hidden) {
  
        currentlyAnimatingFaceUpCard.current = false;
        return;  
    }

    playBeloteSound('faceCardSound');
   

    currentlyAnimatingFaceUpCard.current = true;

const rect = faceUpCardElement.getBoundingClientRect();


const clonedCard = faceUpCardElement.cloneNode(true);
clonedCard.style.position = 'absolute';
clonedCard.style.width = '70px';
clonedCard.style.height = '110px';

clonedCard.style.left = `${rect.left}px`;
clonedCard.style.top = `${rect.top}px`;
clonedCard.style.transform = 'rotate(90deg)';  
clonedCard.style.transformOrigin = 'center';
clonedCard.style.zIndex = '0';

document.body.appendChild(clonedCard);

const targetPosition = playerId === user._id
            ? { x: -0.5 * window.innerWidth, y: window.innerHeight } 
            : { x: -0.5 * window.innerWidth, y: -1 * window.innerHeight }; 




const animation = gsap.timeline()
    .to(clonedCard, {
        duration: 0.2,
        rotate: '0deg',
        ease: 'power2.inOut',
    })
    .to(clonedCard, {
        duration: 0.96,
        x: targetPosition.x,
        y: targetPosition.y,
        ease: 'power2.inOut',
    })
    .eventCallback('onComplete', () => {
        currentlyAnimatingFaceUpCard.current = false;

        if (document.body.contains(clonedCard)) {
            clonedCard.remove();
        }

    });

animations.current.push(animation);
};

const animateFaceUpCardToRightSide = (game) => {
    const faceUpCardElement = document.querySelector('.face-up-card .Fcard');

    if (!faceUpCardElement || currentlyAnimatingFaceUpCard.current) return;

    if (document.hidden) {

        currentlyAnimatingFaceUpCard.current = false;
        return; 
    }

    currentlyAnimatingFaceUpCard.current = true;

    const rect = faceUpCardElement.getBoundingClientRect();


    const clonedCard = faceUpCardElement.cloneNode(true);
    clonedCard.style.position = 'absolute';
    clonedCard.style.width = '70px';
    clonedCard.style.height = '110px';
    clonedCard.style.left = `${rect.left}px`;
    clonedCard.style.top = `${rect.top}px`;
    clonedCard.style.transform = 'rotate(90deg)';
    clonedCard.style.transformOrigin = 'center';
    clonedCard.style.zIndex = '0';

    document.body.appendChild(clonedCard);


    clonedCard.style.animation = `ppbet-rotateAndMoveOffScreen 1.2s ease-in-out forwards`;

    clonedCard.addEventListener('animationend', () => {
        currentlyAnimatingFaceUpCard.current = false;
        if (document.body.contains(clonedCard)) {
            clonedCard.remove(); 
        }
    });
};





const hasanimateRemainingCardDealingAnimationRun = useRef(false);

const animateRemainingCardDealing = async (game, normalizedOpponents) => {
    if (!game) return;

    try {
        const turnPlayerId = game.turn;
        const opponentId = normalizedOpponents.find(opponent => opponent._id !== turnPlayerId)?._id;

        if (!opponentId) {
            console.error('Opponent ID is undefined.');
            return;
        }

        if (hasanimateRemainingCardDealingAnimationRun.current) {
       
            return;
        }

        if (document.hidden) {

            hasanimateRemainingCardDealingAnimationRun.current = true;
            setInitialDeck([]);
            initialDeckCardsRef.current = []; 
            return;
        }

        const deckCards = initialDeckCardsRef.current;

        hasanimateRemainingCardDealingAnimationRun.current = true;

        const dealCardToPlayer = (playerId, index, delay) => {
            const cardElement = deckCards[index];  
            if (!cardElement) return;


            const animationClass = playerId === user._id
                ? 'ppbet-dealCardToPlayer'  
                : 'ppbet-dealCardToOpponent'; 

            cardElement.style.animation = `${animationClass} 0.9s ${delay}s ease-in-out forwards`;

            return new Promise((resolve) => {
                cardElement.addEventListener('animationend', () => {
                    if (document.body.contains(cardElement)) {
                        cardElement.remove();
                        initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== cardElement);
                    }
                    resolve(); 
                });
            });
        };

        const dealCards = () => {
            const animations = []; 
        
            let dealerId = game.dealer.toString();
            let nonDealerId = game.userIds.find(id => id !== dealerId).toString();
        
        
            let numCardsForNonDealer = game.faceUpCardTakenBy === nonDealerId ? 2 : 3;
            let numCardsForDealer = game.faceUpCardTakenBy === nonDealerId ? 3 : 2;
        
  

            playBeloteSound('opponentDealRem_card');

            for (let i = 0; i < numCardsForNonDealer; i++) {
                animations.push(dealCardToPlayer(nonDealerId, i, i * 0.06)); 
            }

           setTimeout(() => playBeloteSound('playerDealRem_card'), 1200);
            // Deal cards to the dealer after
            for (let i = 0; i < numCardsForDealer; i++) {
                animations.push(dealCardToPlayer(dealerId, i + numCardsForNonDealer, i * 0.06 + 1.2)); 
            }
        
            return Promise.all(animations); 
        };
        

        await dealCards(); 
        const removeDeckWithAnimation = async () => {
            const remainingDeckCards = initialDeckCardsRef.current;

            if (remainingDeckCards.length === 0) {
                console.error('No remaining deck cards found to animate.');
                return;
            }

            await Promise.all(remainingDeckCards.map(card => {
                return new Promise((resolve) => {
                 
                    card.style.animation = `ppbet-removeDeckCard 1.1s ease-in-out forwards`;

                    card.addEventListener('animationend', () => {
                        if (card.parentNode) {
                            card.remove();
                            initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== card);
                        }
                        resolve(); 
                    });
                });
            }));

          
        };

        await removeDeckWithAnimation(); 

    } catch (error) {
        console.error('Error in animateRemainingCardDealing:', error);
    } finally {
  
        setInitialDeck([]);
        initialDeckCardsRef.current = []; 
    }
};





const removeDeck = () => {
    setInitialDeck([]);
    initialDeckCardsRef.current = []; 

};



const renderFaceUpCard = () => {
    if(!game || !game.phase){
        return
    };

    if (currentlyAnimatingFaceUpCard.current) return null; // Prevent rendering during animation

    if (
        game.phase === 'trump-decision' || 
        game.phase === 'trump-selection' || 
        game.phase === 'trump-final-selection' || 
        game.phase === 'face-up-card-taken' ||
        game.phase === 'face-up-card-skeeped'
    ){
    return (
        <div className="face-up-card">
            <div className="Fcard">
                <img
                    src={`/cards/${game.faceUpCardCopy.value}_of_${game.faceUpCardCopy.suit}.png`}
                    alt={`${game.faceUpCardCopy.value} of ${game.faceUpCardCopy.suit}`}
                />
            </div>
        </div>
    );
};
};

    
    
const renderPlayerHand = () => {

    if(!game)return;

    const userId = user._id;
    const playerHand = game.hands[userId] || [];
    const initialCards = game.initialCards[userId] || [];
    const faceUpCard = game.faceUpCardCopy || null;

    if (game.phase === 'deal-initial-cards') {
        return null;
    }


    const hideRemainingCards = game.phase === 'deal-remaining-cards';
    const hideFaceUpCard = game.phase === 'face-up-card-taken' || currentlyAnimatingFaceUpCard.current;

    const filteredInitialHand = initialCards.filter(card => {
        return faceUpCard ? (card.suit !== faceUpCard.suit || card.value !== faceUpCard.value) : true;
    });

    let visibleHand;

    if (hideRemainingCards) {

        visibleHand = initialCards;
    } else if (hideFaceUpCard) {

        visibleHand = filteredInitialHand;
    } else {

        visibleHand = playerHand;
    }

    if (!playerHand || !Array.isArray(playerHand)) {
        return null; 
    }



    const allowedCards = (game.turn === userId && game.playedCards.length === 1 && !allCardsAllowedRef.current[userId])
        ? getAllowedCards(visibleHand, game.playedCards[0], game.trumpSuit)
        : visibleHand;


    const playerCombination = game?.winnerCombinations?.[userId];
    const winningCards = playerCombination
    ? ['fifty', 'tierce', 'hundred', 'fourX'].flatMap(type => 
        playerCombination[type]?.flatMap(combo => combo.cards) || []
      )
    : [];

    
    return (
        <div className={`player-hand ${
            visibleHand.length === 8 ? 'eight-cards' :
            visibleHand.length === 7 ? 'seven-cards' :
            visibleHand.length === 6 ? 'six-cards' :
            visibleHand.length === 5 ? 'five-cards' :
            visibleHand.length === 4 ? 'four-cards' :
            visibleHand.length === 3 ? 'three-cards' :
            visibleHand.length === 2 ? 'two-cards' :
            visibleHand.length === 1 ? 'one-cards' : ''
        }`}>
            {visibleHand.map((card,index) => {
             
                const isAllowed = allowedCards.some(
                    allowedCard => allowedCard.suit === card.suit && allowedCard.value === card.value
                );

                const isWinningCard = winningCards.some(
                    (winningCard) => winningCard._id === card._id
                );

                
                const isDealingCards = (visibleHand.length === 5 && game.trumpSuit === null) || visibleHand.length === 8;
                const entryAnimation = isDealingCards ? 'cardEntryAnimation' : '';


                const animationClass = (showCombination.player && isWinningCard && game?.moveCounter <= 5 ) 
                 ? 'card-animate-updown' 
                 : '';

                const rotationDegrees = getCardRotation(visibleHand.length, index); 
                const translateYValue = getCardTranslateY(visibleHand.length, index); 


                if (currentlyAnimatingCardId.current === card._id) return null;

                return (
                    <div
                        key={card._id}
                        className={`card ${selectedCard === card ? 'selected' : ''} ${isAllowed ? 'allowed-card' : 'not-allowed-card'} ${animationClass} ${entryAnimation}`}
                        style={{
                            '--card-rotation': `${rotationDegrees}deg`,
                            '--card-translateY': `${translateYValue}px`
                        }}
                        onTouchStart={isAllowed ? () => handleCardClick(game, card) : null} 
                        onClick={isAllowed ? () => handleCardClick(game, card) : null} 
                        data-id={card._id}
                    >
                        <img
                            src={`/cards/${card.value}_of_${card.suit}.png`}
                            alt={`${card.value} of ${card.suit}`}
                        />
                    </div>
                );
                
            })}
            {renderAnnounceCancelButtons()}
            
            
            {combinationPopupFlags[user._id] && renderCombinationPopupDialogForPlayer(user._id)}

            {game.combinations[user._id]?.blot && blotPopupFlags.player && 
localPlayedCards.current.some(playedCard => 
    playedCard.suit === game.trumpSuit && (playedCard.value === 'K' || playedCard.value === 'Q')
) && (
    <div className="player-popupComb">
        <div className="dialog-popup-inner player">
            <h3>Belote</h3>
        </div>
    </div>
)}

            


            {renderTrumpDecisionPopupButtons()}
            {renderTrumpDecisionPopupDialogForPlayer()}

            {renderTrumpSelectionPopupButtons()}
            {renderTrumpSelectionPopupDialogForPlayer()}

            {renderTrumpFinalSelectionPopupButtons()}
         {/*  {renderTrumpFinalSelectionPopupDialogForPlayer()}  */}

            
        </div>
    );
};
const getCardRotation = (handSize, cardIndex) => {
    const rotations = {
        8: [-9.6, -6.4, -3.2, -1.4, 1.4, 3.2, 6.4, 9.6],
        7: [-9.6, -6.4, -3.2, 0, 3.2, 6.4, 9.6],
        6: [-8, -4, -1.28, 1.28, 4, 8],
        5: [-8, -4, 0, 4, 8],
        4: [-6.4, -2.5, 2.5, 6.4],
        3: [-6.4, 0, 6.4],
        2: [-2.5, 2.5],
        1: [0]
    };
    return rotations[handSize][cardIndex];
};

const getCardTranslateY = (handSize, cardIndex) => {
    const translations = {
        8: [12.8, 6.4, 3.2, 0.5, 0, 2, 6.4, 12.8],
        7: [12.8, 6.4, 3.2, 2, 3.2, 6.4, 14],
        6: [10.2, 5.1, 2, 2, 5.1, 10.2],
        5: [10.2, 5.1, 2.5, 5.1, 10.2],
        4: [8.5, 4.25, 4.25, 8.5],
        3: [8.5, 4, 8.5],
        2: [6, 6],
        1: [0]
    };
    return translations[handSize][cardIndex];
};




const renderOpponentHand = () => {



    const opponentId = opponents.find(opponent => opponent._id !== user._id)?._id;
    if (!opponentId) return null;

    const opponentHand = game.hands[opponentId] || [];
    const initialCards = game.initialCards[opponentId] || [];
    const faceUpCard = game.faceUpCardCopy;


    if (game.phase === 'deal-initial-cards') {
        return null;
    }


    const hideRemainingCards = game.phase === 'deal-remaining-cards' ;


    const hideFaceUpCard = game.phase === 'face-up-card-taken' || currentlyAnimatingFaceUpCard.current;

    const filteredInitialHand = initialCards.filter(card => {
        return faceUpCard ? (card.suit !== faceUpCard.suit || card.value !== faceUpCard.value) : true;
    });

    let visibleHand;

    if (hideRemainingCards) {

        visibleHand = initialCards;
    } else if (hideFaceUpCard) {

        visibleHand = filteredInitialHand;
    } else {

        visibleHand = opponentHand;
    }

    const animatingCardId = currentlyAnimatingOpponentCardId.current;
    if (animatingCardId) {
        visibleHand = visibleHand.filter(card => card._id !== animatingCardId);
    }
    
    const isDealingCards = (visibleHand.length === 5 && game.trumpSuit === null) || visibleHand.length === 8;
    const entryAnimation = isDealingCards ? 'cardEntryAnimation' : '';

    return (
        <div className="opponent-hand">
            {opponentHand.map((card, index) => (
                <div 
                    key={card._id} 
                    className={`card-back ${entryAnimation}`} 
                    data-id={card._id}
                >
                    <img src="/cards/card_back.png" alt="Card Back" />
                </div>
            ))}
            
            {combinationPopupFlags[opponentId] && renderCombinationPopupDialogForOpponent(opponentId)}

            {!game.combinations[user._id]?.blot && blotPopupFlags.opponent && 
            localPlayedCards.current.some(playedCard => 
                playedCard.suit === game.trumpSuit && (playedCard.value === 'K' || playedCard.value === 'Q')
            ) && (
                <div className="opponent-popup">
                    <div className="dialog-popup-inner opponent">
                        <h3>Belote</h3>
                    </div>
                </div>
            )}
            
            {renderBeloteOpponentMessage()}
            {renderOpponentCombination()}
            {renderTrumpDecisionPopupDialogForOpponentSection()}
            {renderTrumpSelectionPopupDialogForOpponentSection()}
         {/*{renderTrumpFinalSelectionPopupDialogForOpponentSection()} */}
        </div>   
    );
};


const renderGamePoints = () => {
    if (!game || !game.totalGamePoints) return null;


    const playerGamePoints = game.totalGamePoints[user._id] || 0;
    const opponentGamePoints = game.totalGamePoints[opponent?._id] || 0;

    return (
        <div className="game-points-container">
            <div className="game-points-header">
                <span>Me</span>
                <span>You</span>
            </div>
            <div className="game-points-values">
                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{playerGamePoints}</span>
                /<span style={{ fontWeight: 'normal', fontSize: '11px' }}>101</span>
                <div className="game-points-divider"></div> {/* Divider */}
                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{opponentGamePoints}</span>
                /<span style={{ fontWeight: 'normal', fontSize: '11px' }}>101</span>
            </div>
        </div>
    );
    
    
};

const renderLastPlayedHand = () => {

    if (!game) {
        return null;
    }

    const activePhases = [/*'in-progress',*/ 'card-played','trick-in-progress', 'trick-completed'];
    if (!activePhases.includes(game.phase)) {
        return null; 
    }


    const lastPlayedCards = game.lastPlayedCards; 

    return (
        <>
            {lastPlayedCards.length === 2 && ( 
                <div className="last-played-hand-container">
                    <div className="last-played-hand-cards">
                        {lastPlayedCards.map((playedCard) => (
                            <div
                                key={playedCard.card._id}
                                className="last-played-card"
                                style={{
                                    backgroundImage: `url(/cards/${playedCard.card.value}_of_${playedCard.card.suit}.png)`,
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
    
   
};


const calculateWinnerGain = (betAmount) => {
    switch (betAmount) {
        case 5:
            return 9;
        case 10:
            return 18;
        case 20:
            return 36;
        default:
            return 0; 
    }
};
    
    
    
const renderPopup = () => {

    if (!game || !game.turn) {
      
        return null;
    }

    
    if (ShowCancelPopup) {
        return (
            <div className="popup">
                <div className="popup-inner">
                    <h3>Game Canceled</h3>
                    <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                    <button onClick={() => setShowCancelPopup(false)}>Close</button>
                </div>
            </div>
        );
    }

    if (showEndGamePopup && game?.status === 'completed') {
        const winnersArray = game.winners || [];
        const losersArray = game.losers || []; 
    
        if (winnersArray.length === 0 || losersArray.length === 0) {
            console.error("Winners or losers array is empty.");
            return null;
        }
    
        const winnerId = winnersArray[0];
        const loserId = losersArray[0];
        const winnerPoints = game.totalGamePoints[winnerId] || 0;
        const loserPoints = game.totalGamePoints[loserId] || 0;
        const isUserWinner = user._id === winnerId;
        const endReason = game.endReason || null; 
       
    
        return (
            <div className="ppbet-end-game-popup">
                <div className="ppbet-end-game-header">
                <h3 className={isUserWinner ? "ppbet-win" : "ppbet-lose"}>
        {isUserWinner ? (
            <>
                <span className="end-game-emoji">🏆</span> You Won the Game!
            </>
        ) : (
            <>
                <span className="end-game-emoji">😞</span> You Lost the Game
            </>
        )}
    </h3>
    <div className="ppbet-end-game-results-table">
    <div className="ppbet-end-game-results-row ppbet-end-game-header">
        <div>Game Result</div>
    </div>
    <div className="ppbet-end-game-results-row ppbet-end-game-score">
        <div className={user._id === winnerId ? "ppbet-end-game-winner-score" : "ppbet-end-game-loser-score"}>
            {user._id === winnerId ? winnerPoints : loserPoints}
        </div>
        <span className="ppbet-end-game-colon">:</span>
        <div className={user._id === winnerId ? "ppbet-end-game-loser-score" : "ppbet-end-game-winner-score"}>
            {user._id === winnerId ? loserPoints : winnerPoints}
        </div>
    </div>


    <div className="ppbet-endgame-results-table">
    <div className="ppbet-results-row ppbet-score-row">
        <div className="ppbet-results-left">
            <div className="ppbet-score-icon">
                <img src="/icons/score-icon.svg" alt="Score" />
            </div>
            <div className="ppbet-current-score">
                 {user.score}
            </div>
        </div>
        <div className="ppbet-results-right">
            <div className={`ppbet-score-result ${isUserWinner ? 'ppbet-score-positive' : 'ppbet-score-negative'}`}>
                {isUserWinner ? "+15" : "-10"}
            </div>
        </div>
    </div>

    <div className="ppbet-results-row ppbet-bet-row">
        <div className="ppbet-results-left">Bet Amount</div>
        <div className="ppbet-results-right">
            <span className="ppbet-dollar-signBET">$</span>
            <span className="ppbet-bet-amount">{game.betAmount}</span>
        </div>
    </div>

    {isUserWinner && game.betAmount > 0 && (
        <div className="ppbet-results-row ppbet-winning-row">
            <div className="ppbet-results-left">Winnings</div>
            <div className="ppbet-results-right">
                <span className="ppbet-dollar-sign">$</span> 
                <span className="ppbet-winning-amount">
                    {calculateWinnerGain(game.betAmount)}
                </span>
            </div>
        </div>
                    )}
                    </div>
                    </div>
                    {isUserWinner && endReason && (
    <div className="belote-end-reason-container">
        <span className="belote-end-reason-icon">📝</span>
        <span className="belote-end-reason-text">
            <strong>Reason for End: </strong> {endReason}
        </span>
    </div>
)}
                </div>
            </div>
        );
    }
    


    if (showRoundCanceledPopup) {
        return (
            <div className="popup">
                <div className="popup-inner">
                    <div style={{ margin: '0', padding: '0', color: '#000' }}>Round canceled. New round starting...</div>
                </div>
            </div>
        );
    }

    if (showRoundResultsPopup && (game.phase === 'round-completed' || game?.status === 'completed')) {
        
        const results = game?.roundResults;
        const isUserTrumpSelector = user?._id === game.trumpSuitSelectedBy?.toString();
        
        
        const playerNickname = user.nickname || 'Me';
        const opponentNickname = Object.entries(game?.usernames)
        .filter(([key]) => key !== user._id.toString())
        .map(([_, value]) => value)[0] || 'Opponent';
        
        // 🎉  🙂
        return (
            <div className="ppbet-round-results-popup">
                <div className="ppbet-result-header">
                    {results.winnerId === user._id ? (
                        <div className="ppbet-round-status ppbet-win">
                            <h3>Round Won! <span className="end-round-emoji">😊</span></h3>
                        </div>
                    ) : (
                        <div className="ppbet-round-status ppbet-lose">
                            <h3>Round Lost <span className="end-round-emoji">😞</span></h3>
                        </div>
                    )}
                </div>
                <div className="ppbet-results-table">
                    <div className="ppbet-results-row ppbet-header">
                        <div></div>
                        <div title={playerNickname}>{playerNickname}</div>
                        <div title={opponentNickname}>{opponentNickname}</div>
                    </div>
                    {results.details.map((detail, index) => (
    <div className="ppbet-results-row" key={index}>
        <div>{detail.name}</div>
        <div>
            {detail.name === "Taken"
                ? isUserTrumpSelector
                    ? detail.mePoints
                    : detail.opponentPoints
                : isUserTrumpSelector
                    ? detail.mePoints !== "-" 
                        ? detail.mePoints / 10 
                        : detail.mePoints
                    : detail.opponentPoints !== "-"
                        ? detail.opponentPoints / 10
                        : detail.opponentPoints}
        </div>
        <div>
            {detail.name === "Taken"
                ? isUserTrumpSelector
                    ? detail.opponentPoints
                    : detail.mePoints
                : isUserTrumpSelector
                    ? detail.opponentPoints !== "-" 
                        ? detail.opponentPoints / 10 
                        : detail.opponentPoints
                    : detail.mePoints !== "-"
                        ? detail.mePoints / 10
                        : detail.mePoints}
        </div>
    </div>
))}
<div className="ppbet-results-row ppbet-total">
    <div>Total</div>
    <div>{isUserTrumpSelector ? results.totalMe : results.totalOpponent}</div>
    <div>{isUserTrumpSelector ? results.totalOpponent : results.totalMe}</div>
</div>
                </div>
            </div>
        );
    }


    

    return null;

    
};



const renderTrumpDecisionPopupButtons = () => {
    if (!game || !game.turn) {
        return null;
    }

    const isCurrentPlayer = game.turn === user._id;
    const isPassDisabled = game.faceUpCardCopy?.value === 'J' && game.dealer === user._id;

    const handleDecision = (event, decision) => {
        event.currentTarget.disabled = true;  // Disable the clicked button
        handleTrumpDecision(decision);
    };

    if (showTrumpDecisionPopup && isCurrentPlayer) {
        return (
            <div className="PlayerTrumpDecision-popUp">
                <button
                    className="take-btn"
                    onClick={(e) => handleDecision(e, true)}
                >
                    Take
                </button>
                <button
                    className="pass-btn"
                    onClick={(e) => {
                        playBeloteSound('buttonClick');
                        handleDecision(e, false);
                    }}
                    disabled={isPassDisabled}
                >
                    Pass
                </button>
            </div>
        );
    }

    return null;
};




const renderTrumpDecisionPopupDialogForOpponentSection = () =>{
    if (!game || !game.turn) {
        
        return null;
    }
    const isCurrentPlayer = game.turn === user._id;
    const opponentId = game.userIds.find(id => id !== user._id);
    

    if (showTrumpDecisionPopup && isCurrentPlayer) {
        return (
            <>
                
                <div className="opponent-popup"> 
                    <div className="dialog-popup-inner opponent">
                    <h3>{game.playerActions[opponentId] === 'pass' ? 'Pass' : 'Taking?'}</h3>
                    </div>
                </div>
            </>
        );
    } 

}

const renderTrumpDecisionPopupDialogForPlayer = () =>{

    if (!game || !game.turn) {
       
        return null;
    }

    const isCurrentPlayer = game.turn === user._id; 
    
    

    if (showTrumpDecisionPopup && !isCurrentPlayer) {
        return (
            <>
               
                <div className="player-popup-inOtherScreen"> 
                    <div className="dialog-popup-inner player">
                    <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Taking?'}</h3>
                    </div>
                </div>
            </>
        );
    }

}

const renderTrumpSelectionPopupButtons = () => {
    if (!game || !game.turn) {
        return null;
    }

    const faceUpCardSuit = game.faceUpCardCopy?.suit;
    const isCurrentPlayer = game.turn === user._id;

    const handleSelection = (event, suit) => {
        event.currentTarget.disabled = true;  // Disable the clicked button
        handleTrumpSelection(suit);
    };

    if (showTrumpSelectionPopup && isCurrentPlayer) {
        return (
            <div className="PlayerTrumpSelection-popUp">
                <div className="button-container">
                    {faceUpCardSuit !== 'hearts' && (
                        <button
                            className="hearts"
                            onClick={(e) => handleSelection(e, 'hearts')}
                        >
                            <img src="/icons/hearts.svg" alt="Hearts Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'diamonds' && (
                        <button
                            className="diamonds"
                            onClick={(e) => handleSelection(e, 'diamonds')}
                        >
                            <img src="/icons/diamonds.svg" alt="Diamonds Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'clubs' && (
                        <button
                            className="clubs"
                            onClick={(e) => handleSelection(e, 'clubs')}
                        >
                            <img src="/icons/clubs.svg" alt="Clubs Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'spades' && (
                        <button
                            className="spades"
                            onClick={(e) => handleSelection(e, 'spades')}
                        >
                            <img src="/icons/spades.svg" alt="Spades Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'anxoz' && (
                        <button
                            className="anxoz"
                            onClick={(e) => handleSelection(e, 'anxoz')}
                        >
                            <img src="/icons/anxoz.svg" alt="Anxoz Icon" />
                        </button>
                    )}
                </div>
                <button
                    className="btn"
                    onClick={(e) => {
                        playBeloteSound('buttonClick');
                        handleSelection(e, 'pass');
                    }}
                >
                    Pass
                </button>
            </div>
        );
    }

    return null;
};



const renderTrumpSelectionPopupDialogForPlayer = () => {
    if (!game || !game.turn) {
       
        return null;
    }

    const isCurrentPlayer = game.turn === user._id;
    

    if (showTrumpSelectionPopup && !isCurrentPlayer) {

    return (
        <div className="player-popup-inOtherScreen"> 
                <div className="dialog-popup-inner player">
                <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Any other?'}</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpSelectionPopupDialogForOpponentSection = () => {
    if (!game || !game.turn) {
       
        return null;
    }
    
    const isCurrentPlayer = game.turn === user._id; 

    if (showTrumpSelectionPopup && isCurrentPlayer) {

    return (
        <div className="opponent-popup"> 
                <div className="dialog-popup-inner opponent">
                <h3>Pass</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpFinalSelectionPopupButtons = () => {
    if (!game || !game.turn) {
        return null; 
    }

    const isCurrentPlayer = game.turn === user._id;

    const handleFinalDecision = (event, decision) => {
        event.currentTarget.disabled = true; // Disable the clicked button
        handleFinalTrumpDecision(decision);
    };

    if (showTrumpFinalDecisionPopup && isCurrentPlayer) {
        return (
            <div className="trump-final-decision-popup">
                {game.trumpSuit && (
                    <div className="selected-suit-icon">
                        <img src={`/icons/${game.trumpSuit}.svg`} alt={`${game.trumpSuit} icon`} />
                    </div>
                )}

                <div className="button-container">
                    <button className="btn" onClick={(e) => handleFinalDecision(e, true)}>With Card</button>
                    <button className="btn" onClick={(e) => handleFinalDecision(e, false)}>Without</button>
                </div>
            </div>
        );
    }

    return null;
};


const renderTrumpFinalSelectionPopupDialogForPlayer = () => {
    if (!game || !game.turn) {
       
        return null;
    }

    const isCurrentPlayer = game.turn === user._id;
    

    if (showTrumpFinalDecisionPopup && !isCurrentPlayer) {

    return (
        <div className="player-popup-inOtherScreen">
                <div className="dialog-popup-inner player">
                <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Any other?'}</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpFinalSelectionPopupDialogForOpponentSection = () => {
    if (!game || !game.turn) {
       
        return null;
    }
    
    const isCurrentPlayer = game.turn === user._id; 

    if (showTrumpFinalDecisionPopup && isCurrentPlayer) {

    return (
        <div className="opponent-popup"> 
                <div className="dialog-popup-inner opponent">
                <h3>Pass</h3>
            </div>
        </div>
    );
    }
};

const renderAnnounceCancelButtons = () => {
    if (!game || !game.turn) {
        return null; 
    }
    
    const playerCombinations = game.combinations?.[user._id];
    const isCurrentPlayer = game.turn === user._id;
    const playerHand = game.hands?.[user._id] || null;

  
    if ((game.phase === 'in-progress' || game.phase === 'card-played') && showAnnounceCancelPopup && playerHand?.length === 8 && isCurrentPlayer && hasValidCombinations(playerCombinations)) {
        return (
            <div className="PlayerAnnounceCancel-popup">
                <div className="button-container">
                    <button className="announce-btn" onClick={() => handleAnnounceCancel('announce')}>Announce</button>
                    <button className="cancel-btn" onClick={() => handleAnnounceCancel('cancel')}>Cancel</button>
                </div>
            </div>
        );
    }

    return null; 
};



const renderCombinationPopupDialogForPlayer = (userId) => {

  
    if (combinationPopupFlags[userId]) {
        const playerCombinations = game.combinations[userId];

       

        const hasValidCombinations = (
            playerCombinations.tierce.length > 0 ||
            playerCombinations.fifty.length > 0 ||
            playerCombinations.hundred.length > 0 ||
            playerCombinations.fourX.length > 0
        );
        
        if (!game || !hasValidCombinations || playerCombinationDecision.current === 'cancel') {
           
            return null;
        }


        const combinationText = generateCombinationText(playerCombinations); 

        return (
            <div className="player-popupComb">
                <div className="dialog-popup-inner player">
                    <h3>{combinationText}</h3>
                </div>
            </div>
        );
    }

    return null; 
};

const generateCombinationText = (playerCombinations) => {
    if (!playerCombinations) return "";

    let text = "";
    if (playerCombinations.tierce.length) text += playerCombinations.tierce.length > 1 ? `Tierce (${playerCombinations.tierce.length}x) ` : "Tierce ";
    if (playerCombinations.fifty.length) text += playerCombinations.fifty.length > 1 ? `50 (${playerCombinations.fifty.length}x) ` : "50 ";
    if (playerCombinations.hundred.length) text += playerCombinations.hundred.length > 1 ? `100 (${playerCombinations.hundred.length}x) ` : "100 ";
    if (playerCombinations.fourX.length) text += playerCombinations.fourX.length > 1 ? `4x (${playerCombinations.fourX.length}x) ` : "4x ";

    return text ;  
};


const renderCombinationPopupDialogForOpponent = (opponentId) => {


    const opponentCombinations = game.combinations[opponentId];

    const hasValidCombinations = (
        opponentCombinations.tierce.length > 0 ||
        opponentCombinations.fifty.length > 0 ||
        opponentCombinations.hundred.length > 0 ||
        opponentCombinations.fourX.length > 0
    );

    if (!game || !hasValidCombinations || game.skipCombinationDecisions[opponentId]===true) {
        return null;
    }

    const combinationText = generateCombinationText(opponentCombinations);

    return (
        <div className="opponent-popup">
            <div className="dialog-popup-inner opponent">
                <h3>{combinationText}</h3>
            </div>
        </div>
    );
};




const handleAnnounceCancel = (choice) => {
    
    playerCombinationDecision.current = choice;
    setShowAnnounceCancelPopup(false);

};

const hasValidCombinations = (combinations) => {
    if (!combinations) return false;
    
    
    const { tierce, fifty, fourX, hundred } = combinations;
    
    return (
        (tierce && tierce.length > 0) ||
        (fifty && fifty.length > 0) ||
        (fourX && fourX.length > 0) ||
        (hundred && hundred.length > 0)
    );
};




const renderOpponentCombination = () => {
    if (!showCombination.opponent) return null;
    const userId = user._id;
    const winnerCombinations = game.winnerCombinations;

  

    if (game?.moveCounter > 5) {
        return null;
    }

   
    const winnerId = Object.keys(winnerCombinations)[0]; 

    
    if (userId === winnerId) {
        return null;
    }


    const opponentCombination = winnerCombinations[winnerId];

    if (!opponentCombination) {
        
        return null;
    }


    const combinationCards = ['fifty', 'tierce', 'hundred', 'fourX'].flatMap(type =>
        opponentCombination[type]?.flatMap(combo => combo.cards) || []
    );

    return (
        <div className="opponent-combination">
            {combinationCards.map((card, index) => (
                <div
                    key={card._id}
                    className="combination-card"
                    style={{
                        '--card-rotation': '0deg', 
                        '--card-translateY': '0px' 
                    }}
                >
                    <img
                        src={`/cards/${card.value}_of_${card.suit}.png`}
                        alt={`${card.value} of ${card.suit}`}
                    />
                </div>
            ))}
        </div>
    );
};






const renderInitialDeck = () => {
    if (!game || !game.turn) {
        return null;
    }
    if (initialDeck.length === 0) return null; 

    return (
        <div className="initial-deck">
            {initialDeck.map((card, index) => (
                <div
                    key={index}
                    className="initial-deck-card"
                    style={{
                        position: 'absolute',
                        zIndex: card.zIndex,
                        transform: `translateY(${(initialDeck.length - index - 1) * -0.5}px) translateX(${(initialDeck.length - index - 1) * -0.5}px)`, // Slight offset upward and to the right
                    }}
                >
                    <img
                        src="/cards/card_back.png"
                        alt="Card Back"
                        style={{ width: '70px', height: '110px' }}
                    />
                </div>
            ))}
        </div>
    );
};

const renderDealerSign = (playerId) => {
    if (game.dealer === playerId) {
        return (
            <div className="dealer-sign">
                <img src="/icons/dealer-icon.svg" alt="Dealer Icon" />
            </div>
        );
    }
    return null;
};



 const fetchGameState = useCallback(async () => {
    try {
        const response = await apiRequest(`${API_BASE_URL}/api/current-game` , {
            method: 'GET',
            credentials: 'include', 
        });

        const data = await response.json();




        if (response.ok && data.game) {
            const game = data.game;

           
            if (game.status === 'completed') {
                setInitialDeck([]);
                initialDeckCardsRef.current = []; 
                navigate('/game');
                return; 
            }

            
            handleCardPlayedPhase(game, true); 
            handleGameUpdate(data); 
        } else {
 
            navigate('/game');
        }
    } catch (error) {
        console.error('Error fetching game state:', error);
    }
}, [navigate]);


useEffect(() => {
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            fetchGameState();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [fetchGameState]); 


useEffect(() => {
    fetchGameState(); 
}, [fetchGameState]); 





return (
    <div className="blot-page">
       
        {renderGamePoints()}
        {renderLastPlayedHand()}
        {game && game.hands && (
            <>
          
            <div className={`opponent-section ${game.turn === opponent?._id && !['initial-deal', 'deal-initial-cards','deal-remaining-cards','face-up-card-taken', 'face-up-card-skeeped', 'round-canceled', 'round-completed', 'game-completed', 'game-canceled','trick-in-progress'].includes(game.phase) ? 'highlighted waiting-for-move' : ''}`} data-player-id={opponent?._id}>
         
            {renderDealerSign(opponent?._id)}
               
                <div className="player-label opponent">
                    <div className="player-icon">
                    {opponent.photoUrl ? (
                    <img src={opponent.photoUrl} alt="Player Avatar" />
                    ) : (
                    <span className="player-initial">
                    {opponent.nickname?.charAt(0).toUpperCase() || "?"}
                    </span>
                        )}
                        <svg className="timer-circleX">
                          <defs>
                            <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                              <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                              <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                            </linearGradient>
                          </defs>
                          <circle className="base" cx="50%" cy="50%" r="45%" />
                          <circle className="timer" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
                        </svg>
                    </div>

                    {game.trumpSuit && game.trumpSuitSelectedBy === opponent?._id && (
                        <div className="trump-icon">
                            <img src={`/icons/${game.trumpSuit}.svg`} alt="Trump Icon" />
                        </div>
                    )}

                    <div className="player-info-text">
                        <div className="name-and-flag">
                            <span className="player-name">
                                {opponent?.nickname}
                            </span>
                            {opponent?.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="country-flagL"
                                />
                            )}
                        </div>
                        <span className="player-score">
                            {opponent?.score || 0}
                        </span>
                        <span className="round-points">
                            {game.roundPoints && game.roundPoints[opponent?._id] ? game.roundPoints[opponent._id] : 0}
                        </span>
                    </div>
                </div>

                {renderOpponentHand()}
            </div>

            {game && game.hands && (
                <>
                    <div className="playarea">
                        {localPlayedCards.current.map((playedCardObj) => (
                            <div 
                                key={playedCardObj._id} 
                                className={`card in-play ${playedCardObj.positionClass}`} 
                                data-id={playedCardObj._id} 
                                data-player-id={playedCardObj.player}
                            >
                                <img
                                    src={`/cards/${playedCardObj.value}_of_${playedCardObj.suit}.png`}
                                    alt={`${playedCardObj.value} of ${playedCardObj.suit}`}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={`player-section ${game.turn === user._id && !['initial-deal', 'deal-initial-cards','deal-remaining-cards','face-up-card-taken', 'face-up-card-skeeped', 'round-canceled', 'round-completed', 'game-completed', 'game-canceled','trick-in-progress'].includes(game.phase) ? 'highlighted waiting-for-move' : ''}`} data-player-id={user._id}>
                        
                    {renderDealerSign(user._id)}
                    {renderBeloteControlButtons()}
                    {renderBelotePlayerMessage()}

                        <div className="player-label player">
                            <div className="player-icon">
                            {user.photoUrl ? (
            <img src={user.photoUrl} alt="Player Avatar" />
        ) : (
            <span className="player-initial">
                {user.nickname?.charAt(0).toUpperCase() || "?"}
            </span>
        )}
                                <svg className="timer-circleX">
                                  <defs>
                                    <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                                      <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                                      <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                                    </linearGradient>
                                  </defs>
                                  <circle className="base" cx="50%" cy="50%" r="45%" />
                                  <circle className="timer" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
                                </svg>
                            </div>

                            {game.trumpSuit && game.trumpSuitSelectedBy === user._id && (
                                <div className="trump-icon">
                                    <img src={`/icons/${game.trumpSuit}.svg`} alt="Trump Icon" />
                                </div>
                            )}
                            
                            <div className="player-info-text">
                                <div className="name-and-flag">
                                    <span className="player-name">
                                        {user.nickname}
                                    </span>
                                    {user.country && (
                                        <img
                                            src={`/flags/${user.country.toLowerCase()}.svg`}
                                            alt={`${user.country} flag`}
                                            className="country-flagL"
                                        />
                                    )}
                                </div>
                                <span className="player-score">
                                    {user.score || 0}
                                </span>
                                <span className="round-points">
                                    {game.roundPoints && game.roundPoints[user._id] ? game.roundPoints[user._id] : 0}
                                </span>
                            </div>
                        </div>

                        {renderPlayerHand()}
                    </div>

                    <div className="deck-container">
                  
                        {(game.phase === 'initial-deal' || 
                        game.phase === 'deal-initial-cards' || 
                        game.phase === 'face-up-card-taken' || 
                        game.phase === 'in-progress' ||
                        game.phase === 'face-up-card-skeeped' || 
                        game.phase === 'deal-remaining-cards' || 
                        game.phase === 'trump-decision' || 
                        game.phase === 'trump-selection' || 
                        game.phase === 'trump-final-selection') && renderInitialDeck()}

                        {renderFaceUpCard()}
                    </div>
                </>
            )}
            </>
        )}
        {renderPopup()}
        {renderLeaveGamePopup()}
    </div>
);

};

export default BlotPage;


