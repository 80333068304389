import React, { useEffect, useState, useContext, useCallback,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './CarromGame.css';
import audioManager from './AudioManager';


const CarromGame = () => {
    const { socket, user } = useContext(AuthContext);

    // State variables
    const [renderTrigger, setRenderTrigger] = useState(false);
    const [game, setGame] = useState(null);
    const navigate = useNavigate();
    const [opponents, setOpponents] = useState([]);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [currentRound, setCurrentRound] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const STRIKER_SIZE = 30;
    const COIN_SIZE = 20;
    const STRIKER_RADIUS = STRIKER_SIZE / 2; 
    const COIN_RADIUS = COIN_SIZE / 2;
    const BOARD_WIDTH = 300;
    const BOARD_HEIGHT = 300; 
    const SENSITIVITY = 4;
    const FIXED_DELTA_TIME = 1 / 45; // Fixed time step in seconds (60 FPS)
    const MS_PER_FRAME = 1000 * FIXED_DELTA_TIME; // Convert to milliseconds 
    const collisionCooldownMs = 120; // Cooldown period in milliseconds
    const coinCollisionCooldownMs = 60; // Cooldown period in milliseconds
    const minVelocityThreshold = 0.2; // Define a threshold for significant velocity

    const [coins, setCoins] = useState([]);
    const [strikerPosition, setStrikerPosition] = useState({});
    const [isDragging, setIsDragging] = useState(false); // Dragging state
    const [handlePosition, setHandlePosition] = useState(100); // Horizontal position of the handle in the controller


    const controllerWidth = 200; // Width of the controller
    const handleWidth = 30; // Width of the handle
    const boardMinX = 65; // Minimum striker X on the board
    const boardMaxX = 235; // Maximum striker X on the board
    
    const [isTouchingStriker, setIsTouchingStriker] = useState(false);
    const [isControllerActive, setIsControllerActive] = useState(false);
    const [dragDots, setDragDots] = useState([]); // Dots array for striker interaction
    const [circleScale, setCircleScale] = useState(1); // Initial scale
    const [hitArrow, setHitArrow] = useState({ angle: 0, length: 0 });
    const [whiteDots, setWhiteDots] = useState([]);
    const [isFalling, setIsFalling] = useState(false);
    const [hitStrength, setHitStrength] = useState(0); // 0 to MAX_DISTANCE
    const [strikerVelocity, setStrikerVelocity] = useState({ x: 0, y: 0 }); // Striker velocity
    const hitStrengthRef = useRef(0);
    const hitArrowRef = useRef({ angle: 0, length: 0 });
    const coinsRef = useRef(coins);
    const strikerVelocityRef = useRef({ x: 0, y: 0 });
    const assignedPositions = useRef([]);

    const isFallingRef = useRef(false);
    const [isFoulPopupVisible, setIsFoulPopupVisible] = useState(false);
    const [isLastCoinPopupVisible, setIsLastCoinPopupVisible] = useState(false);
    const isClientReadyRef = useRef(false);
    const [timerStartTime, setTimerStartTime] = useState(null);

    
    const [syncGameOnUpdat, setSyncGameOnUpdat] = useState(false); 
    const [syncGameOnReady, setSyncGameOnReady] = useState(false);
    const isClientSyncedRef = useRef(false);       
    const [timerExecuted, setTimerExecuted] = useState(false);    

    const isFirstRender = useRef(true);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isOverlapping, setIsOverlapping] = useState(false);
    const strikerPositionBeforeHitRef = useRef(null);
    
    const [carromShowTextMenu, setCarromShowTextMenu] = useState(false);
    const [carromShowSettingsMenu, setCarromShowSettingsMenu] = useState(false);

    const [carromPlayerMessage, setCarromPlayerMessage] = useState(null);
    const [carromOpponentMessage, setCarromOpponentMessage] = useState(null);

    const [isSoundOn, setIsSoundOn] = useState(true); // Default to sound on
    const [isLeaveGamePopupVisible, setIsLeaveGamePopupVisible] = useState(false);
    audioManager.setSoundOn(isSoundOn);
    audioManager.enableSoundOnUserInteraction();
    

    

    useEffect(() => {
        let isTouchMove = false;
      
        const handleTouchMove = (event) => {
          isTouchMove = true;
        };
      
        const handleTouchStart = (event) => {
          // Prevent default touch behavior only inside dropdown or settings
          if (
            event.target.closest(".carrom-dropdown-menu") ||
            event.target.closest(".carrom-settings-expanded")
          ) {
            event.stopPropagation();
          }
        };
      
        const handleClickOutside = (event) => {
          if (isTouchMove) {
            isTouchMove = false; // Reset flag after scroll
            return; // Don't close dropdown if scrolling
          }
      
          // Only close the menus if the click is outside the settings and text menus
          if (
            !event.target.closest(".carrom-dropdown-menu") &&
            !event.target.closest(".carrom-textIcon-buttonIcon") &&
            !event.target.closest(".carrom-settingsIcon-buttonIcon") &&
            !event.target.closest(".carrom-settings-expanded")
          ) {
            setCarromShowTextMenu(false);
            setCarromShowSettingsMenu(false);
          }
        };
      
        document.addEventListener("pointerdown", handleClickOutside);
        document.addEventListener("touchend", handleClickOutside);
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchstart", handleTouchStart, { passive: false });
      
        return () => {
          document.removeEventListener("pointerdown", handleClickOutside);
          document.removeEventListener("touchend", handleClickOutside);
          document.removeEventListener("touchmove", handleTouchMove);
          document.removeEventListener("touchstart", handleTouchStart);
        };
      }, []);
      
  

  // Listen for opponent's message
  useEffect(() => {
    socket.on('carrom-message', ({ message, userID }) => {
        if(user._id !== userID){
      setCarromOpponentMessage(message);
      setTimeout(() => setCarromOpponentMessage(null), 2500);
    }
    });

    return () => {
      socket.off('carrom-message');
    };
  }, []);

  

  const handleCarromMessageSelect = (message) => {
    // Show message for the current player
    setCarromPlayerMessage(message);
    setTimeout(() => setCarromPlayerMessage(null), 2500);

    // Emit the message to the opponent via WebSocket
    socket.emit('carrom-message', { message, gameId: game._id, userID: user._id });

    setCarromShowTextMenu(false);
  };

  const handleSoundToggle = (e) => {
    e.stopPropagation(); // Prevent settings from closing
    setIsSoundOn((prev) => !prev);
  };

  const handleLeaveGame = (e) => {
    e.stopPropagation(); // Prevent settings from closing
    setIsLeaveGamePopupVisible(true); // Show leave game popup
  };

  const handlePopupClose = () => {
    setIsLeaveGamePopupVisible(false); // Close the popup
  };


    const handleConfirmLeave = async (gameId) => {
        if (!gameId) {
            setIsLeaveGamePopupVisible(false);
            return;
        } 
       
        try {
            const response = await fetch('/api/leave-carrom-game', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setIsLeaveGamePopupVisible(false);
     
            } else {
                console.error('Failed to leave game:', data.message);
            }
        } catch (error) {
            console.error('Error leaving game:', error);
        }
    };
    
  

  const renderCarromControlButtons = () => {
    if (!game) return null;
  
    return (
      <div className="carrom-game-buttonsIcons">
        {/* Text Button */}
        <button
          className="carrom-textIcon-buttonIcon"
          onPointerDown={(e) => {
            e.stopPropagation();
            setCarromShowTextMenu(!carromShowTextMenu);
          }}
        >
          <img src={`/icons/icon-text.png`} alt="Text" />
        </button>
  
        {carromShowTextMenu && (
          <div className="carrom-dropdown-menu carrom-text-menu">
            <ul>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Hello!"); }}>Hello!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Good luck!"); }}>Good luck!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Well played!"); }}>Well played!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Thanks!"); }}>Thanks!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Oops!"); }}>Oops!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Too easy!"); }}>Too easy!</li>
              <li onPointerUp={(e) => { e.stopPropagation(); handleCarromMessageSelect("Hurry up!"); }}>Hurry up!</li>
            </ul>
          </div>
        )}
  
        {/* Settings Button */}
        <button
          className={`carrom-settingsIcon-buttonIcon ${carromShowSettingsMenu ? "active" : ""}`}
          onPointerDown={(e) => {
            e.stopPropagation();
            setCarromShowSettingsMenu(!carromShowSettingsMenu);
          }}
        >
          <img src={`/icons/icon-settings.png`} alt="Settings" />
        </button>
  
        {/* Extra Buttons (Only Show When Settings is Active) */}
        {carromShowSettingsMenu && (
          <div className="carrom-settings-expanded">
            {/* Leave Game Button */}
            <button
              className="carrom-leaveGame-buttonExt"
              onPointerDown={(e) => {
                e.stopPropagation();
                handleLeaveGame(e);
              }}
            >
              <img src="/icons/icon-exit.png" alt="Leave Game" />
            </button>
  
            {/* Sound Toggle Button */}
            <button
              className="carrom-soundToggle-buttonExt"
              onPointerDown={(e) => {
                e.stopPropagation();
                handleSoundToggle(e);
              }}
            >
              <img src={isSoundOn ? "/icons/icon-sound.png" : "/icons/icon-sound-off.png"} alt="Toggle Sound" />
            </button>
          </div>
        )}
      </div>
    );
  };
  


const renderCarromPlayerMessage = () => {
    return (
      carromPlayerMessage && (
        <div className="carrom-message-popup carrom-player-message">
          <div className="carrom-message-popup-inner carrom-player-message-inner">
            {carromPlayerMessage}
          </div>
        </div>
      )
    );
  };


const renderCarromOpponentMessage = () => {
    return (
      carromOpponentMessage && (
        <div className="carrom-message-popup carrom-opponent-message">
          <div className="carrom-message-popup-inner carrom-opponent-message-inner">
            {carromOpponentMessage}
          </div>
        </div>
      )
    );
  }

  const renderLeaveGamePopup = () => {
    return (
      isLeaveGamePopupVisible && (
        <div className="carrom-leave-game-popup">
          <div className="carrom-leave-game-header">
            <h3>Are you sure you want to leave the game?</h3>
            <p>If you leave, your opponent will be declared the winner.</p>
          </div>
          <div className="carrom-leave-game-buttons">
            <button
              className="carrom-leave-game-btn"
              onPointerDown={() => handleConfirmLeave(game?._id)} // Pass game._id properly
            >
              Leave
            </button>
            <button
              className="carrom-leave-game-btn"
              onPointerDown={handlePopupClose} // Use pointer event for mobile compatibility
            >
              Cancel
            </button>
          </div>
        </div>
      )
    );
  };
  


    useEffect(() => {
        if (!game || !timerStartTime || !user) return;
    
       
        
            const opponentId = game.userIds.find((id) => id !== user._id);
            const isPlayerTurn = game.turn === user._id && (game.phase === 'player-turn' || game.phase === 'opponent-turn');
            const isOpponentTurn = game.turn === opponentId && (game.phase === 'player-turn' || game.phase === 'opponent-turn');
    
            const playerTimerCircle = document.querySelector('.carrom-player-avatar .Carrom-timerP');
            const opponentTimerCircle = document.querySelector('.carrom-opponent-avatar .Carrom-timerO');
    

            const calculateRemainingTime = () => {
                const elapsedTime = Date.now() - timerStartTime;
                return elapsedTime > 0 ? Math.max(0, 22000 - elapsedTime) : 20000;
            };

           

            if (playerTimerCircle) {
                if (isPlayerTurn && !game.strikerBeenHit) {
                    const remainingTime = calculateRemainingTime();
    
                    playerTimerCircle.style.transition = `stroke-dashoffset ${remainingTime / 1000}s linear`;
                    setTimeout(() => {
                        playerTimerCircle.style.strokeDashoffset = '0';
                    }, 20); 
                   
                } else {     
                    playerTimerCircle.style.strokeDashoffset = '188';
                    playerTimerCircle.style.transition = 'none';
                }
            }
    
 
            if (opponentTimerCircle) {
                if (isOpponentTurn && !game.strikerBeenHit) {
                    const remainingTime = calculateRemainingTime();
      
                    opponentTimerCircle.style.transition = `stroke-dashoffset ${remainingTime / 1000}s linear`;
                    setTimeout(() => {
                        opponentTimerCircle.style.strokeDashoffset = '0';
                    }, 20); 
                   
                } else {
                    opponentTimerCircle.style.strokeDashoffset = '188';
                    opponentTimerCircle.style.transition = 'none';
                }
            }
 
    

    }, [game?.turn, game?.strikerBeenHit]);
    
    
    
    


    const HOLES = [
        { x: 16, y: 16, radius: 16 },  // Top-left corner
        { x: 284, y: 16, radius: 16 }, // Top-right corner
        { x: 16, y: 284, radius: 16 }, // Bottom-left corner
        { x: 284, y: 284, radius: 16 } // Bottom-right corner
    ];
    

    
    
   

useEffect(() => {
    if (!game || !user || game.phase === "move-execution" || game.strikerBeenHit || isDragging) return;
    

    const whitePlayerID = Object.keys(game.playerColors).find(
        (id) => game.playerColors[id] === "white"
    );

    const newStrikerPosition =
        game.turn === whitePlayerID
            ? { x: 150, y: 257 } // Position for white player's turn
            : { x: 150, y: 43 }; // Position for black player's turn

    strikerPositionBeforeHitRef.current = newStrikerPosition;

    setStrikerPosition((prevPosition) => {
        
        // Skip animation on first render
        if (isFirstRender.current) {
            
            strikerVelocityRef.current = { x: 0, y: 0 };
            isFallingRef.current = false;
            setIsFalling(false);
            const isPlayerTurn = game?.turn === user?._id;
            setIsControllerActive(isPlayerTurn);
            isFirstRender.current = false;
            if (isOverlappingWithCoins(newStrikerPosition, coinsRef.current)) {
                const adjustedPosition = findNearestValidPosition(newStrikerPosition, coinsRef.current);
                if (isOverlapping) setIsOverlapping(false);
                strikerPositionBeforeHitRef.current = adjustedPosition;
                return adjustedPosition;
            }else {
                if (isOverlapping) setIsOverlapping(false);
                strikerPositionBeforeHitRef.current = newStrikerPosition;
                return newStrikerPosition; // Update the position state
            }
        }

        // Only animate if striker hasn't been hit, is not being dragged, and position changes
        if (
            !game.strikerBeenHit &&
            !isDragging &&
            (prevPosition.x !== newStrikerPosition.x ||
                prevPosition.y !== newStrikerPosition.y)
        ) {
            
            const strikerElement = document.querySelector(".carrom-striker");
            if (strikerElement) {
                setIsTransitioning(true);
                setIsFalling(false);
                
                
                strikerElement.style.transition = "top 0.6s ease, left 0.6s ease";
                strikerElement.style.top = `${newStrikerPosition.y}px`;
                strikerElement.style.left = `${newStrikerPosition.x}px`;

                // Clear transition styles after animation ends
                strikerElement.addEventListener(
                    "transitionend",
                    () => {
                        strikerElement.style.transition = "";
                        setIsTransitioning(false);
                        

                    },
                    { once: true }
                );
            } else {
                
                
            }
        }
        if (isOverlappingWithCoins(newStrikerPosition, coinsRef.current)) {
            const adjustedPosition = findNearestValidPosition(newStrikerPosition, coinsRef.current);
            if (isOverlapping) setIsOverlapping(false);
            strikerPositionBeforeHitRef.current = adjustedPosition;
            return adjustedPosition;
        }else {
            if (isOverlapping) setIsOverlapping(false);
            strikerPositionBeforeHitRef.current = newStrikerPosition;
            return newStrikerPosition; // Update the position state
        };
        
    });
}, [game, user]);


const isOverlappingWithCoins = (strikerPosition, coins = coinsRef.current) => {
    return coins.some((coin) => {
        const dx = strikerPosition.x - coin.x;
        const dy = strikerPosition.y - coin.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < STRIKER_RADIUS + COIN_RADIUS; // Adjust radius as needed
    });
};

const findNearestValidPosition = (
    position, 
    coins = coinsRef.current, 
    controllerWidth = 200
) => {
    const step = 5; // Small adjustment step
    const controllerMinX = boardMinX; // Minimum controller x-coordinate
    const controllerMaxX = boardMaxX; // Maximum controller x-coordinate
    const coinRadius = 10; // Example coin radius
    const strikerRadius = 15; // Example striker radius

    let leftPosition = { ...position }; // Start from the current position for left adjustment
    let rightPosition = { ...position }; // Start from the current position for right adjustment
    let leftTries = 0; // Counter for left-side adjustments
    let rightTries = 0; // Counter for right-side adjustments
    let maxTries = Math.ceil(controllerWidth / step); // Limit tries to controller width

    // Check if a position is valid (not overlapping with coins and within bounds)
    const isValidPosition = (pos) =>
        !coins.some((coin) => {
            const dx = pos.x - coin.x;
            const dy = pos.y - coin.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            return distance < coinRadius + strikerRadius;
        }) &&
        pos.x >= controllerMinX &&
        pos.x <= controllerMaxX;

    // Find the nearest clear position to the left
    while (leftTries < maxTries) {
        if (isValidPosition(leftPosition)) break;
        leftPosition.x -= step; // Shift left
        leftTries++;
    }

    // Find the nearest clear position to the right
    while (rightTries < maxTries) {
        if (isValidPosition(rightPosition)) break;
        rightPosition.x += step; // Shift right
        rightTries++;
    }

    // Decide which side required fewer adjustments and return that position
    if (leftTries <= rightTries && leftTries < maxTries) {
        return leftPosition; // Left side is closer and valid
    } else if (rightTries < maxTries) {
        return rightPosition; // Right side is closer and valid
    } else {
        console.error("Unable to find a valid position for the striker!");
        return position; // Return original position as a fallback
    }
};



    const fetchGameState = useCallback(async () => {
        try {
            const response = await apiRequest('/api/current-game', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            if (response.ok && data.game && data.game.phase !== "move-execution") {
                if (data.game.status === 'completed') {
                    navigate('/game');
                    return;
                }
                if (!timerStartTime) setTimerStartTime(Date.now());
                
                isClientSyncedRef.current = true;
                isFallingRef.current = false;
                setIsFalling(false);
                isFirstRender.current = true;
                
                setOpponents(data.opponents || []);
                if(data.game.phase === "opponent-turn"){
                isClientReadyRef.current = true;
                }
            
                handleGamePhase(data.game);
            } else if (response.ok && data.game && data.game.phase === "move-execution") {
                setGame(null);
                isClientSyncedRef.current = true;
                isFallingRef.current = false;
                setIsFalling(false);
                isFirstRender.current = true;
                setIsFoulPopupVisible(false);
                setIsLastCoinPopupVisible(false);

                coinsRef.current.forEach((coin) => {
                coin.velocity.x = 0;
                coin.velocity.y = 0;
                });

                strikerVelocityRef.current.x = 0;
                strikerVelocityRef.current.y = 0;
               
                return;
            } else {
                setGame(null);
                navigate('/game');
                return;
            }
        } catch (error) {
            console.error('Error fetching game state:', error);
            setGame(null);
            navigate('/game');
            return;
        }
    }, [navigate]);


    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }

        socket.on('connect', () => {
            fetchGameState();
        });

        

        socket.on('game-update', (data) => {
            if (!data.game) {
                navigate('/game');
                return;
            }

            
            setOpponents(data.opponents || []);
            if (data.timeoutTriggered){
                setTimerExecuted(true);  
            };

            if (data.timerStartTime && data.game.phase !== 'move-execution') {
                setTimerStartTime(data.timerStartTime);
            };
            
            setIsDragging(false);
            handleGamePhase(data.game, data.timeoutTriggered);

        });

        return () => {
            socket.off('game-update');
            socket.off('connect');
            
        };
    }, [socket, user, navigate]);

 

    const dataGameSyncRef = useRef(null);
    const gameReadyRef = useRef(false);

   
    const animateStrikerToPosition = (targetPosition, game) => {
        return new Promise((resolve) => {

            const whitePlayerID = Object.keys(game.playerColors).find(
                (id) => game.playerColors[id] === "white"
            );
        
            const newStrikerPosition =
                game.turn === whitePlayerID
                    ? { x: 150, y: 257 } 
                    : { x: 150, y: 43 }; 
        
          

            const currentPos = strikerPositionBeforeHitRef.current || newStrikerPosition;

    
            const duration = 300; 
            const startTime = performance.now();
    
            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
    
               
                const newX = currentPos.x + (targetPosition.x - currentPos.x) * progress;
                const newY = currentPos.y + (targetPosition.y - currentPos.y) * progress;
    
                setStrikerPosition({ x: newX, y: newY });
    
                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    resolve(); 
                }
            };
    
            requestAnimationFrame(animate);
        });
    };
    const uilockRef = useRef(false);

    const handleGamePhase = (gameData, timerExecuted = false) => {
        const isPlayerTurn = gameData.turn === user._id;
        
        
    
        switch (gameData.phase) {
            case 'move-execution':
    
                if (!isPlayerTurn) {
                    
                    checkAndExecuteMove(gameData);
                    
                }
                break;

                case 'opponent-turn': 
                        
                        dataGameSyncRef.current = gameData;
                        gameReadyRef.current = true;
                        
                        if (timerExecuted){
                            isClientReadyRef.current = true; 
                        }
                        setSyncGameOnUpdat((prev) => !prev);
                        break;
                
                
                    case 'player-turn':
                        if (isGameEnd) break;
                        setIsControllerActive(isPlayerTurn);
                        setGame(gameData);
                        coinsRef.current = gameData.boardState.coins;
                        setCoins([...coinsRef.current]);
                        break;
    
            case 'game-canceled':
                
                setShowCancelPopup(true);
                setTimeout(() => {
                    setShowCancelPopup(false);
                    navigate('/game');
                }, 4000);
                break;
    
            case 'game-completed':
            
                setGame(gameData);
                setIsGameEnd(true);
                break;
    
            default:
                console.warn(`Unhandled game phase: ${gameData.phase}`);
        }
    };

    const MAX_RETRIES_onExecute = 20;
    let retryCount_onExecute = 0;

    const checkAndExecuteMove = (gameData) => {
        if (isClientSyncedRef.current) {
            handleMoveExecution(gameData);
            
        } else if (retryCount_onExecute < MAX_RETRIES_onExecute) {
            retryCount_onExecute++;
            setTimeout(() => checkAndExecuteMove(gameData), 200); 
        } else {
                coinsRef.current.forEach((coin) => {
                coin.velocity.x = 0;
                coin.velocity.y = 0;
            });
    
 
            strikerVelocityRef.current.x = 0;
            strikerVelocityRef.current.y = 0;
    
           
        setTimeout(() => {
            isFallingRef.current = false;
            setIsFalling(false);
            fetchGameState();
        }, 1000); 
        }
    };

    const handleMoveExecution = (gameData) => {
        uilockRef.current = true;
        
        if (isGameEnd) return ;
        
        retryCount_onExecute = 0;
        setGame(gameData);
        isClientSyncedRef.current = false;
        const { lastMove } = gameData;
        endTurn();
        assignedPositions.current = [];

        const hitPosition = {x:lastMove.strikerPosition.x, y:lastMove.strikerPosition.y};
        animateStrikerToPosition(hitPosition, gameData).then(() => {
            strikerVelocityRef.current = {
                x: Math.cos(lastMove.angle) * lastMove.hitStrength / 10,
                y: Math.sin(lastMove.angle) * lastMove.hitStrength / 10,
            };
            
            showHitEffect(gameData);
            setTimeout(() => {  
                setIsTouchingStriker(false);
                setDragDots([]);
                setCircleScale(1);
                setHitStrength(0);

                startStrikerMovement(gameData); 
            }, 1500);
            
        });
    };

    const showHitEffect = (hitData) => {
        setWhiteDots([]);
        setIsTouchingStriker(true);
    
        const { strikerPosition, angle, hitStrength } = hitData.lastMove;
        const distance = (hitStrength / 200) * MAX_DISTANCE;

        const calculatedStrength = distance < 18 ? 0 : (distance / MAX_DISTANCE) * 200;
        hitStrengthRef.current = calculatedStrength;
    

        const oppositeAngle = angle + Math.PI;  
 
        const newDarkDots = [];
        const steps = Math.round(distance / 10);
        
        for (let i = 1; i <= steps; i++) {
            const offsetX = Math.cos(oppositeAngle) * (distance / steps) * i;
            const offsetY = Math.sin(oppositeAngle) * (distance / steps) * i;
            
            newDarkDots.push({
                x: strikerPosition.x + offsetX,
                y: strikerPosition.y + offsetY,
            });
        }
    
        setDragDots(newDarkDots);
    
  
        setHitArrow({
            angle: (angle * 180) / Math.PI,
            length: distance,
        });
    
  
        const newScale = distance / 11;
        setCircleScale(newScale);
    };
    
   

 

 useEffect(() => {
    if (!gameReadyRef.current || !isClientReadyRef.current || !dataGameSyncRef.current){
        return;
    }
    if (isGameEnd) return;

        
         coinsRef.current.forEach((coin) => {
            coin.velocity.x = 0;
            coin.velocity.y = 0;
        });

       
        strikerVelocityRef.current.x = 0;
        strikerVelocityRef.current.y = 0;
        
        const checkPlayerTurn = dataGameSyncRef.current.turn === user._id;
        setIsControllerActive(checkPlayerTurn);

    
        setGame(dataGameSyncRef.current);
        setIsTouchingStriker(false);
        coinsRef.current = dataGameSyncRef.current.boardState.coins;
        setCoins([...coinsRef.current]);

        
        isClientReadyRef.current = false; 
        gameReadyRef.current = false;
        dataGameSyncRef.current = null;
        isFallingRef.current = false;
        isClientSyncedRef.current = true;
        uilockRef.current = false;

        if(timerExecuted){
            setTimeout(() => {  
                setTimerExecuted(false);    
            }, 500);  
        }
        
        
                       
}, [syncGameOnUpdat,syncGameOnReady]);


useEffect(() => {
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            fetchGameState();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [fetchGameState]); 

    useEffect(() => {
        fetchGameState();
    }, [fetchGameState]);


    const triggerConfettiAnimation = () => {
        const confettiContainer = document.createElement('div');
        confettiContainer.className = 'confetti-container';
    
        for (let i = 0; i < 200; i++) { 
            const confetti = document.createElement('div');
            confetti.className = 'confetti-piece';
            
          
            confetti.style.left = '50%';
            confetti.style.top = '50%'; 
            confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`; 
            confetti.style.transform = `rotate(${Math.random() * 360}deg)`; 
    
      
            const randomX = (Math.random() - 0.5) * window.innerWidth * 2; 
            const randomY = (Math.random() - 0.5) * window.innerHeight * 2;
            const duration = Math.random() * 1.4 + 0.8; 
    
            confetti.style.animation = `explode ${duration}s ease-out forwards`;
            confetti.style.setProperty('--randomX', `${randomX}px`); 
            confetti.style.setProperty('--randomY', `${randomY}px`); 
    
            confettiContainer.appendChild(confetti);
        }
    
        document.body.appendChild(confettiContainer);
    
        setTimeout(() => {
            document.body.removeChild(confettiContainer);
        }, 4000);  
    };

    useEffect(() => {
        if (game && user && isGameEnd) {
            if (game.winners?.includes(user._id)) {
                triggerConfettiAnimation();
                audioManager.setVolume(0.8);
                audioManager.playSound('win');
            } else {
                audioManager.setVolume(0.8);
                audioManager.playSound('lose');
            }

            const timeout = setTimeout(() => {
                navigate('/game');
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [isGameEnd, game, user, navigate]);

    const renderGameEndPopup = () => {
        if (!isGameEnd || !game) return null;
    
        const winnersArray = game.winners || [];
        const losersArray = game.losers || [];
        const winnerId = winnersArray[0] || null;
        const loserId = losersArray[0] || null;
        const winnerPoints = game.totalGamePoints[winnerId] || 0;
        const loserPoints = game.totalGamePoints[loserId] || 0;
        const isUserWinner = winnerId === user._id;
        const endReason = game.endReason || null; 
  
    
        return (
            <div className="carrom-end-game-popup">
                <div className="carrom-end-game-header">
                    <h3 className={isUserWinner ? "carrom-win" : "carrom-lose"}>
                        {isUserWinner ? (
                            <>
                                <span className="carrom-end-game-emoji">🏆</span> You Won the Game!
                            </>
                        ) : (
                            <>
                                <span className="carrom-end-game-emoji">😞</span> {game.usernames[winnerId]} Won the Game
                            </>
                        ) }
                    </h3>
                </div>
    
                <div className="carrom-end-game-results-table">
                    <div className="carrom-end-game-results-row carrom-end-game-header">
                        <div>Game Result</div>
                    </div>
                    <div className="carrom-end-game-results-row carrom-end-game-score">
                        <div className={isUserWinner ? "carrom-end-game-winner-score" : "carrom-end-game-loser-score"}>
                            {isUserWinner ? winnerPoints : loserPoints}
                        </div>
                        <span className="carrom-end-game-colon">:</span>
                        <div className={isUserWinner ? "carrom-end-game-loser-score" : "carrom-end-game-winner-score"}>
                            {isUserWinner ? loserPoints : winnerPoints}
                        </div>
                    </div>
    
                    <div className="carrom-results-row carrom-score-row">
                        <div className="carrom-results-left">
                            <div className="carrom-score-icon">
                                <img src="/icons/score-icon.svg" alt="Score" />
                            </div>
                            <div className="carrom-current-score">{user.score}</div>
                        </div>
                        <div className={`carrom-score-result ${isUserWinner ? 'carrom-score-positive' : 'carrom-score-negative'}`}>
                            {isUserWinner ? "+10" : "-5"}
                        </div>
                    </div>
    
                    <div className="carrom-results-row carrom-bet-row">
                        <div className="carrom-results-left">Bet Amount</div>
                        <div className="carrom-results-right">
                            <span className="carrom-dollar-signBET">$</span>
                            <span className="carrom-bet-amount">{game.betAmount}</span>
                        </div>
                    </div>
    
                    {isUserWinner && game.betAmount > 0 && (
                        <div className="carrom-results-row carrom-winning-row">
                            <div className="carrom-results-left">Winnings</div>
                            <div className="carrom-results-right">
                                <span className="carrom-dollar-sign">$</span>
                                <span className="carrom-winning-amount">{calculateWinnerGain(game.betAmount)}</span>
                            </div>
                        </div>
                    )}
                   
                    {isUserWinner && endReason && (
    <div className="carrom-end-reason-container">
        <span className="carrom-end-reason-icon">📝</span>
        <span className="carrom-end-reason-text">
            <strong>Reason for End: </strong> {endReason}
        </span>
    </div>
)}
                </div>
            </div>
        );
    };

    const calculateWinnerGain = (betAmount) => {
        switch (betAmount) {
            case 1:
                return 1.8;
            case 3:
                return 5;
            case 5:
                return 9;
            default:
                return 0; 
        }
    };

    

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="carrom-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase();
        return <div className="carrom-avatar-placeholder">{initial}</div>;
    };
    
    const renderOpponentArea = (game, user, opponents) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);
    
        if (!opponent) {
            return null; 
        }
    
        const opponentColor = game.playerColors[opponent._id]; 
    
        return (
            <div className={`carrom-opponent-wrapper ${game.turn === opponent._id && !game.strikerBeenHit  ? 'highlighted waiting-for-move' : ''}`}>
                <div className="carrom-opponent-area">
                
                    {renderCarromOpponentMessage()}
                    <div className="carrom-opponent-avatar">
                        {renderAvatar(opponent.nickname, opponent.photoUrl)}
                        
                        <svg className="Carrom-timer-circleV">
                <defs>
                    <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
                <circle className="Carrom-base" cx="50%" cy="50%" r="45%" />
                <circle className="Carrom-timerO" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
            </svg>
                    </div>
    
                
                    <div className="carrom-opponent-info">
                        <div className="carrom-name-and-flag">
                            <span className="carrom-opponent-name">{opponent.nickname}</span>
                            {opponent.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="carrom-country-flag"
                                />
                            )}
                        </div>
                        <span className="carrom-opponent-score">Score: {opponent.score}</span>
                        <span className="carrom-opponent-betAmmount">Bet: $ {game?.betAmount}</span>
                    </div>
    
                    <div className="carrom-opponent-coins">
               
                        {game.pocketedPieces[opponent._id]?.some(coin => coin.type === 'queen') && (
                            <div
                                className="carrom-opponent-coin-icon"
                                style={{
                                    backgroundImage: `url(/carromAssets/queen-coin.png)`,
                                    opacity: game.queenState === "secured" ? 1 : 0.3,
                                }}
                            ></div>
                        )}
    
                        <div
                            className="carrom-opponent-coin-icon"
                            style={{
                                backgroundImage: `url(/carromAssets/${opponentColor}-coin.png)`,
                            }}
                        ></div>
                        <div className="carrom-opponent-coin-count">
                            {game.pocketedPieces[opponent._id]?.filter(coin => coin.type === opponentColor && coin.type !== 'queen').length || 0}
                        </div>
                    </div>
                </div>
                
            </div>
        );
    };
    


    useEffect(() => {
     if (!isControllerActive) return;
    const initialHandlePosition =
        ((strikerPosition.x - boardMinX) / (boardMaxX - boardMinX)) * (controllerWidth - handleWidth);
    setHandlePosition(initialHandlePosition);
    }, [strikerPosition.x, game]);

    const handleDragStart = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const strikerPositionOnDrugEndRef = useRef({});

    const handleDragMove = (event, game) => {
        if (!isDragging || !isControllerActive) return;

        // Check if the current player is the second player
        const isSecondPlayer = game?.playerColors[user._id] === "black";
    
        // Get event coordinates (for both mouse and touch)
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
    
        // Get controller dimensions
        const controller = document.querySelector(".striker-controller");
        const controllerRect = controller.getBoundingClientRect();
        const controllerLeft = controllerRect.left;
    
        // Calculate relative position of the handle
        const relativeX = clientX - controllerLeft - handleWidth / 2; // Adjust for handle's center
        const constrainedX = Math.max(0, Math.min(controllerWidth - handleWidth, relativeX));

         // Adjust for second player's mirrored perspective
        const adjustedConstrainedX = isSecondPlayer
        ? controllerWidth - handleWidth - constrainedX
        : constrainedX;

    
        // Update handle position in the controller
        setHandlePosition(adjustedConstrainedX);
    
        // Map controller handle position to board striker position
        const mappedX =
            boardMinX + (adjustedConstrainedX / (controllerWidth - handleWidth)) * (boardMaxX - boardMinX);

        const newPosition = { x: mappedX, y: strikerPosition.y };

        const isOverlapping = isOverlappingWithCoins(newPosition, coinsRef.current);
        if (isOverlapping) {
            // Add red styling to the striker animation
            setIsOverlapping(true);
        } else {
            // Remove red styling
            setIsOverlapping(false);
        }
    
        strikerPositionOnDrugEndRef.current = newPosition
        // Update striker position on the board
        setStrikerPosition(newPosition);
    };


    
    const handleDragEnd = () => {
        let finalPosition = strikerPositionOnDrugEndRef.current;
        if (isOverlappingWithCoins(finalPosition, coinsRef.current)) {
            const adjustedPosition = findNearestValidPosition(strikerPositionOnDrugEndRef.current, coinsRef.current);


            setStrikerPosition(adjustedPosition);
            setIsOverlapping(false);
            
            strikerPositionOnDrugEndRef.current = adjustedPosition;

        }

    
        setIsDragging(false);
    };
    
    










    
        
    const handleStrikerTouchStart = (event, game) => {
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0 || game.turn != user._id || game.strikerBeenHit || timerExecuted) return; 
        if (uilockRef.current) return;
        event.preventDefault();
       
        setIsTouchingStriker(true);
        setHitArrow({
            angle: 0,
            length: 0,
        });
        hitArrowRef.current = { angle: 0, length: 0 };
        setWhiteDots([]);
        setDragDots([]);
        setIsControllerActive(false);
    };
    
    

    const MAX_DISTANCE = 60;

    const handleStrikerTouchMove = (event, game) => {
        event.preventDefault();
        if (!isTouchingStriker) return;
        if (uilockRef.current) return;
    
        const board = document.querySelector(".carrom-board");
        const boardRect = board.getBoundingClientRect();
    
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
        const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;

        const isSecondPlayer = game?.playerColors[user._id] === "black";
    
        const relativeX = isSecondPlayer
        ? boardRect.right - clientX
        : clientX - boardRect.left;
        const relativeY = isSecondPlayer
        ? boardRect.bottom - clientY
        : clientY - boardRect.top;
    
        const dx = relativeX - strikerPosition.x;
        const dy = relativeY - strikerPosition.y;
        const distance = Math.min(Math.sqrt(dx * dx + dy * dy), MAX_DISTANCE);
    

        const calculatedStrength = distance < 18 ? 0 : (distance / MAX_DISTANCE) * 200;
        hitStrengthRef.current = calculatedStrength;

    
        const cappedDx = (dx / Math.sqrt(dx * dx + dy * dy)) * distance;
        const cappedDy = (dy / Math.sqrt(dx * dx + dy * dy)) * distance;
    
        const newDarkDots = [];
        const steps = Math.round(distance / 10);
        for (let i = 1; i <= steps; i++) {
            newDarkDots.push({
                x: strikerPosition.x + (cappedDx / steps) * i,
                y: strikerPosition.y + (cappedDy / steps) * i,
            });
        }
        setDragDots(newDarkDots);
    
        const newScale = distance / 11;
        setCircleScale(newScale);
    
        const arrowAngle = (Math.atan2(-cappedDy, -cappedDx) * (180 / Math.PI) + 360) % 360;
        const arrowLength = Math.min(newScale * 11, distance);
    
        setHitArrow({
            angle: arrowAngle,
            length: arrowLength,
        });
        hitArrowRef.current = { angle: arrowAngle, length: arrowLength };
    
        const maxWhiteDots = 7;
const whiteDotsSpacing = 11;
const whiteDots = [];
for (let i = 1; i <= maxWhiteDots; i++) {
    const arrowX = strikerPosition.x + (-cappedDx / distance) * arrowLength;
    const arrowY = strikerPosition.y + (-cappedDy / distance) * arrowLength;
    whiteDots.push({
        x: arrowX + (-cappedDx / distance) * whiteDotsSpacing * i,
        y: arrowY + (-cappedDy / distance) * whiteDotsSpacing * i,
        opacity: Math.max(0.8, 1 - i * 0.04), 
    });
}
        setWhiteDots(whiteDots);
    };
    
    
    
    
    
    
    const handleStrikerTouchEnd = async (game) => {
        if (uilockRef.current) return;
        const strength = hitStrengthRef.current; 
        const { angle } = hitArrowRef.current; 


      
    
        if (strength > 0 && !timerExecuted) {
            
           
           
            const angleInRadians = angle * (Math.PI / 180);
            const speed = strength / 10;
    
            const velocityX = Math.cos(angleInRadians) * speed;
            const velocityY = Math.sin(angleInRadians) * speed;
    
            
      
             
            const moveData = {
            playerId: user._id,
            strikerPosition,
            angle: angleInRadians,
            speed,
            hitStrength: strength,
            };


            strikerVelocityRef.current = { x: velocityX, y: velocityY };
            
            endTurn();
            assignedPositions.current = [];
            game.strikerBeenHit = true;


            startStrikerMovement();
    
          

            setIsTouchingStriker(false);
            setHitArrow({
                angle: 0,
                length: 0,
            });
            hitArrowRef.current = { angle: 0, length: 0 };
            setDragDots([]);
            setWhiteDots([]);
            setCircleScale(1);
            setHitStrength(0);
            
            

          
        
    
            try {
         
                const response = await apiRequest('/api/make-carrom-move', {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ gameId: game._id, move: moveData }),
                });
               
                if (!response.ok) {
                    
                        
                        coinsRef.current.forEach((coin) => {
                            coin.velocity.x = 0;
                            coin.velocity.y = 0;
                        });
                
            
                        strikerVelocityRef.current.x = 0;
                        strikerVelocityRef.current.y = 0;
                
            
                    setTimeout(() => {
                        isFallingRef.current = false;
                        setIsFalling(false);
                        fetchGameState();
                    }, 3000); 
                    
                }
          
            }  catch (error) {
                console.error('Unexpected error during API call:', error);
                isFallingRef.current = false;
                setIsFalling(false);
                fetchGameState();
            }
        } else {
            setIsTouchingStriker(false);
            setDragDots([]);
            setIsControllerActive(true);
           
        }
        
    };
    
    


    const collisionTrackerRef = useRef(new Map());
    const coinCollisionTrackerRef = useRef(new Map());
    


const startStrikerMovement = (currentGame = game) => {
   // audioManager.playSound("strikerHit");

    
    let accumulatedTime = 0;
    let lastTimestamp = performance.now(); 
    

     collisionTrackerRef.current = new Map();
     coinCollisionTrackerRef.current = new Map();

     let totalSimulatedTime = 0;
    
     const pocketedCoins = [];
     
    const updatePosition = (timestamp) => {
        const delta = timestamp - lastTimestamp;

        totalSimulatedTime += delta;

        lastTimestamp = timestamp;
        accumulatedTime += delta;

        
        
        while (accumulatedTime >= MS_PER_FRAME) {
        accumulatedTime -= MS_PER_FRAME;
        if (!isFallingRef.current) {
        
        setStrikerPosition((prev) => {
            const velocity = strikerVelocityRef.current;

            let newX = prev.x + velocity.x ;
            let newY = prev.y + velocity.y ;

            let newVelocityX = velocity.x;
            let newVelocityY = velocity.y;

            // Wall collision logic for striker
            if (newX - STRIKER_RADIUS <= 0) {
                newX = STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newX + STRIKER_RADIUS >= 300) {
                newX = 300 - STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newY - STRIKER_RADIUS <= 0) {
                newY = STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }
            if (newY + STRIKER_RADIUS >= 300) {
                newY = 300 - STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }

            // Apply friction to the striker
            const friction = 0.975;
            newVelocityX *= friction;
            newVelocityY *= friction;

            // Stop if velocity is negligible
            if (Math.abs(newVelocityX) < 0.2) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.2) newVelocityY = 0;

            // Update striker velocity reference
            strikerVelocityRef.current = { x: newVelocityX, y: newVelocityY };

            // Check for striker-coin collisions
            checkStrikerCoinCollision(newX, newY, collisionTrackerRef.current);

            // Check if striker is falling into a hole
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius + SENSITIVITY) {
                    handleStrikerFallIntoHole(hole);
                    
                    return { x: hole.x, y: hole.y }; // Place striker at the hole's center
                }
            }

            return { x: newX, y: newY };
        });
         } 

        
        
   
        resolveCoinCollisions(coinCollisionTrackerRef.current );

   
        coinsRef.current = coinsRef.current.map((coin) => {
            
            let newX = coin.x + coin.velocity.x ;
            let newY = coin.y + coin.velocity.y ;
        
            let newVelocityX = coin.velocity.x;
            let newVelocityY = coin.velocity.y;
        
      
            if (newX - COIN_RADIUS <= 0) {
                newX = COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newX + COIN_RADIUS >= 300) {
                newX = 300 - COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newY - COIN_RADIUS <= 0) {
                newY = COIN_RADIUS;
                newVelocityY *= -1;
            }
            if (newY + COIN_RADIUS >= 300) {
                newY = 300 - COIN_RADIUS;
                newVelocityY *= -1;
            }
       
            const friction = 0.975;
            newVelocityX *= friction;
            newVelocityY *= friction;
        
  
            if (Math.abs(newVelocityX) < 0.2) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.2) newVelocityY = 0;
        
        
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius + SENSITIVITY) {
                    
                    if (!coin.isProcessed) {
                        pocketedCoins.push({ coin, hole });
                        coin.velocity = { x: 0, y: 0 };
                        newVelocityX = 0;
                        newVelocityY = 0;
                        coin.x = hole.x;
                        coin.y = hole.y;
                        coin.isProcessed = true; 
                

                        const coinElement = document.querySelector(`#coin-${coin.id}`);
                        if (coinElement) {
                            coinElement.style.transform = "translate(-50%, -50%) scale(0.7)";
                            coinElement.style.transition = "transform 0.8s ease"; 
                        }
                        audioManager.setVolume(0.5);
                        audioManager.playSound("coinPocketed");
                        return coin;
                    }
                }
            }

   
            return { ...coin, x: newX, y: newY, velocity: { x: newVelocityX, y: newVelocityY } };
            
        });
        
        
    }


        setCoins([...coinsRef.current.filter((coin) => !coin.isProcessed)]);

        if (
            Math.abs(strikerVelocityRef.current.x) > 0 ||
            Math.abs(strikerVelocityRef.current.y) > 0 ||
            coinsRef.current.some(
                (coin) => Math.abs(coin.velocity.x) > 0 || Math.abs(coin.velocity.y) > 0
            )
        ) {
            requestAnimationFrame(updatePosition);
        }else {
           
            
                setTimeout(() => {
                    
                    handlePocketedCoins(pocketedCoins, currentGame);
                    
                    
                }, 600);

        }
    };
    
    requestAnimationFrame(updatePosition);
   
};

    
    
const triggerCoinPocketAnimation = (game, coin, hole, destination, onComplete) => {
    return new Promise((resolve) => {
    const coinElement = document.querySelector(`#coin-${coin.id}`); 
    const isSecondPlayer = game.playerColors[user._id] === "black";
    if (!coinElement) {

        if (onComplete) onComplete(); 
        setTimeout(() => {
            resolve(); 
        }, 2000); 
        return;
    }

    const board = document.querySelector(".carrom-board"); 
    const boardRect = board.getBoundingClientRect();
    

    
    const adjustedDestinationX = destination.left - boardRect.left + COIN_RADIUS;
    const adjustedDestinationY = destination.top - boardRect.top + COIN_RADIUS;

    const holeCenterX = hole.x;
    const holeCenterY = hole.y;

    const dx = adjustedDestinationX - holeCenterX;
    const dy = adjustedDestinationY - holeCenterY;


    coinElement.style.zIndex = "100"; 
    coinElement.style.transform = "translate(-50%, -50%) scale(1)"; 
    coinElement.style.transition = "transform 0.6s ease, top 0.6s ease, left 0.6s ease";
    coinElement.style.top = `${holeCenterY + dy}px`;
    coinElement.style.left = `${holeCenterX + dx}px`;

    coinElement.addEventListener(
        "transitionend",
        () => {
            
            if(!isSecondPlayer){
                lastPositionsRef.current.set(coin.type, { x: holeCenterX + dx, y: holeCenterY + dy });
                coin.x = holeCenterX + dx;
                coin.y = holeCenterY + dy;
            } else {
                lastPositionsRef.current.set(coin.type, { x: 300 - (holeCenterX + dx), y: 300 - (holeCenterY + dy) });
                coin.x = 300 - (holeCenterX + dx);
                coin.y = 300 - (holeCenterY + dy);
            }

            if (onComplete) onComplete();
        },
        { once: true }
    );
    setTimeout(() => {
  
        resolve(); 
    }, 3000); 
});
};



let isProcessingPocketedCoins = false; 
let accumulatedPocketedCoinsForRound = [];
let currentAnimationFrame = null;

const handlePocketedCoins = (pocketedCoins, game) => {
    if (!game){
        isClientReadyRef.current = true; 
        setSyncGameOnReady((prev) => !prev);
        return;
    }
    accumulatedPocketedCoinsForRound = [...accumulatedPocketedCoinsForRound, ...pocketedCoins];

   

    const checkIfAllStopped = async () => {
        const allObjectsStopped =
            coinsRef.current.every(
                (coin) => Math.abs(coin.velocity.x) === 0 && Math.abs(coin.velocity.y) === 0
            ) &&
            Math.abs(strikerVelocityRef.current.x) === 0 &&
            Math.abs(strikerVelocityRef.current.y) === 0;

        if (allObjectsStopped && !isProcessingPocketedCoins) {
            isProcessingPocketedCoins = true; // Set flag to prevent reprocessing
            setTimeout(async () => {
               
                await processPocketedCoins(accumulatedPocketedCoinsForRound, game);
          
                await checkQueenLogic(game, pocketedCoins, game.turn);
       
              
                accumulatedPocketedCoinsForRound = [];
                isProcessingPocketedCoins = false;

                isClientReadyRef.current = true; 
                setSyncGameOnReady((prev) => !prev);
                
               
            }, 20); // Small delay to ensure stability
           
            return;
        } else {
            currentAnimationFrame = requestAnimationFrame(checkIfAllStopped);
        }
    };


    if (currentAnimationFrame) {
        cancelAnimationFrame(currentAnimationFrame);
    }

    checkIfAllStopped();

};


const lastPositionsRef = useRef(new Map());

const processPocketedCoins = async (pocketedCoins, game) => {
    if (!game) {
        return;
    }

    let animations = [];

    const strikerFell = isFallingRef.current;

    const currentPlayerId = game.turn; 
    const opponentId = game.userIds.find((id) => id !== currentPlayerId);
    

    const coinsToReturn = [];
    const remainingCoins = [];
    let penalizedCoins = 0;
    const alreadyProcessed = new Set();
    let queenPocketed = false;

    const currentPlayerPocketed = game.pocketedPieces[currentPlayerId]?.filter(
        (c) => c.type === game.playerColors[currentPlayerId]
    ).length || 0;

    const opponentPocketed = game.pocketedPieces[opponentId]?.filter(
        (c) => c.type === game.playerColors[opponentId]
    ).length || 0;

    const currentTurnPlayerCoins = pocketedCoins.filter(
        ({ coin }) => coin.type === game.playerColors[currentPlayerId]
    ).length;

    const currentTurnOpponentCoins = pocketedCoins.filter(
        ({ coin }) => coin.type === game.playerColors[opponentId]
    ).length;

    const playerCoinsOnBoard = 9 - currentPlayerPocketed - currentTurnPlayerCoins;
    const opponentCoinsOnBoard = 9 - opponentPocketed - currentTurnOpponentCoins;



    pocketedCoins.forEach(({ coin, hole }) => {
        if (alreadyProcessed.has(coin.id)) {
            return;
        }


        if (coin.type === "queen") {
            if (!strikerFell) {
                queenPocketed = true;
                remainingCoins.push({ coin, hole });

                
            } else {
                coin.isProcessed = false;
                coinsToReturn.push(coin);
            }
            alreadyProcessed.add(coin.id);
            return;
        }

        if (coin.type === game.playerColors[currentPlayerId]) {
            if ((playerCoinsOnBoard === 0 && game.queenState === "onBoard") || strikerFell) {
                coinsToReturn.push(coin);
                coin.isProcessed = false;
             
            } else {
            
                remainingCoins.push({ coin, hole });
               
            }
        } else {
           
            if (opponentCoinsOnBoard === 0) {
              
                coinsToReturn.push(coin);
                coin.isProcessed = false;
             
                penalizedCoins++;
            
            } else {
               
                remainingCoins.push({ coin, hole });

            }
        }
        alreadyProcessed.add(coin.id);
    });

    if (coinsToReturn.length > 0) {
        animations.push(returnCoinsToCenter(coinsToReturn, game));
    }


    if (penalizedCoins > 0) {
    animations.push(applyPenalty(game, game.turn));
    };

    if(strikerFell){
    animations.push(applyPenalty(game, game.turn, true));
    }

    remainingCoins.forEach(({ coin, hole }) => {
        const destination = determineDestination(coin, game);
      
        animations.push(
        triggerCoinPocketAnimation(game, coin, hole, destination, () => {
            const ownerId =
                coin.type === "queen" || coin.type === game.playerColors[currentPlayerId]
                    ? currentPlayerId
                    : opponentId;

          
                game.pocketedPieces[ownerId] = [
                    ...(game.pocketedPieces[ownerId] || []),
                    coin,
                ];

      
                coinsRef.current = coinsRef.current.filter((c) => c.id !== coin.id);
                setCoins([...coinsRef.current]);

                game.strikerBeenHit = true;
                setRenderTrigger((prev) => !prev);
           
        })

        );
    });


    await Promise.all(animations);

};


const checkQueenLogic = async (game, pocketedCoins, currentPlayerId) => {
    if (!game){
        return;
    }
 

    const animations = [];

    
    const queenOnBoard = game.boardState.coins.find(coin => coin.type === 'queen');
    let playerWithQueen = null;

    
    
    game.userIds.forEach(player => {
        const queenFound = game.pocketedPieces[player]?.find(coin => coin.type === "queen");
        if (queenFound) {
            playerWithQueen = player;
        }
    });

    if (game.queenState === "onBoard" && !queenOnBoard) { 
        if (playerWithQueen) {

            animations.push(returnQueenToBoard(game, playerWithQueen));
        }
    }
    
    
    const strikerFell = isFallingRef.current;
   
    if (game.queenState === "onBoard" && pocketedCoins.some(({ coin }) => coin.type === "queen")) {

        const securedImmediately = pocketedCoins.some(
            ({ coin }) => coin.type === game.playerColors[currentPlayerId]
        );

        if (securedImmediately && !strikerFell) {

            game.queenState = "secured";
            
            
        } else {
            if(!strikerFell){
                game.queenState = "pocketed";
                game.queenPocketedBy = currentPlayerId;
               
            }
           
        }
       
    } else if (
        game.queenState === "pocketed" &&
        game.queenPocketedBy === currentPlayerId
    ) {
     
        const secured = pocketedCoins.some(
            ({ coin }) => coin.type === game.playerColors[currentPlayerId]
        );

        if (secured && !strikerFell) {
            game.queenState = "secured";
            
            
           
        } else if (!secured || strikerFell) {
            
            
            if (playerWithQueen) {
                // Animation to return queen to the board from pocketed pieces
                animations.push(returnQueenToBoard(game, playerWithQueen));
            }

        } 
    }
    await Promise.all(animations);
   

};




const returnQueenToBoard = (game, queenPocketedPlayer) => {
    return new Promise((resolve) => {

    const queenOwnerId = queenPocketedPlayer ;
    const queen = game.pocketedPieces[queenOwnerId]?.find((coin) => coin.type === "queen");
    const LastKnownPosition = lastPositionsRef.current.get(queen.type) || { x: 400, y: 150 };



    if (queen) {
        coinsRef.current.push(queen);

        if (queenOwnerId){
            game.pocketedPieces[queenOwnerId] = game.pocketedPieces[queenOwnerId].filter(
                (coin) => coin.id !== queen.id
            );
        } else {
            resolve();
        }
       
    
        

        queen.x = LastKnownPosition.x;
        queen.y = LastKnownPosition.y;

        lastPositionsRef.current.delete(queen.type);

        queen.velocity = { x: 0, y: 0 };
        queen.isProcessed = false;
        

        setCoins([...coinsRef.current]);
        setRenderTrigger((prev) => !prev);

        

        setTimeout(() => {
            returnCoinsToCenter([queen], game);
        }, 300); 
    } else {
        resolve(); 
    }

    game.queenState = "onBoard";
    game.queenPocketedBy = null;
   
    setTimeout(() => {
        resolve(); 
    }, 3300); 
});
};




const determineDestination = (coin, game) => {
    const isPlayerCoin = coin.type === game.playerColors[user._id];
    const isQueen = coin.type === "queen";


    if (isQueen) {
        if (game.turn === user._id) {
            return getPlayerDestination(coin, game); 
        } else {
            return getOpponentDestination(coin, game);
        }
    }


    if (isPlayerCoin) {
        return getPlayerDestination(coin, game); 
    } else {
        return getOpponentDestination(coin, game);
        
    }
};

const getPlayerDestination = (coin, game) => {
    const queenPocketed = game.pocketedPieces[user._id]?.some(
        (coin) => coin.type === "queen"
    );

    const playerCoinsContainer = document.querySelector(".carrom-player-coins");
    if (!playerCoinsContainer) {
        console.warn("Player coins container not found!");
        return null;
    }

    if (coin.type === "queen" || !queenPocketed) {
        return playerCoinsContainer.querySelector(".carrom-coin-icon").getBoundingClientRect();
    } else {
        return playerCoinsContainer.querySelector(".carrom-coin-icon:nth-child(2)").getBoundingClientRect();
    }
};

const getOpponentDestination = (coin, game) => {

    const opponentId = game.userIds.find((id) => id !== user._id);

    const queenPocketed = game.pocketedPieces[opponentId]?.some(
        (coin) => coin.type === "queen"
    );

    const opponentCoinsContainer = document.querySelector(".carrom-opponent-coins");
    if (!opponentCoinsContainer) {
        return null;
    }

    if (coin.type === "queen" || !queenPocketed) {
        return opponentCoinsContainer.querySelector(".carrom-opponent-coin-icon").getBoundingClientRect();
    } else {
        return opponentCoinsContainer.querySelector(".carrom-opponent-coin-icon:nth-child(2)").getBoundingClientRect();
    }
};



    
    

  
    
    
    
    
    
   

const quantize = (value, precision = 6) => Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

const checkStrikerCoinCollision = (newX, newY , collisionTracker) => {
    const updatedCoins = coinsRef.current.map((coin) => {
        if (coin.isProcessed) return coin; 

        const dx = coin.x - newX;
        const dy = coin.y - newY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const collisionKey = `striker-coin${coin.id}`;
        const now = performance.now();

        if (distance < STRIKER_RADIUS + COIN_RADIUS) {
          
            if (collisionTracker.has(collisionKey)) {
                const lastCollisionTime = collisionTracker.get(collisionKey);
                if (now - lastCollisionTime < collisionCooldownMs &&
                    distance > STRIKER_RADIUS + COIN_RADIUS
                ) {
                    return coin; 
                }
            }
            

            collisionTracker.set(collisionKey, now);

const relativeVelocity = {
    x: strikerVelocityRef.current.x - coin.velocity.x,
    y: strikerVelocityRef.current.y - coin.velocity.y,
};
const relativeSpeed = Math.sqrt(relativeVelocity.x ** 2 + relativeVelocity.y ** 2);


const minCollisionSpeedForSound = 1.0; 
if (relativeSpeed > minCollisionSpeedForSound) {
    
    const minVolume = 0.05;  
    const maxVolume = 1.0;   

    // Normalize the speed for cubic scaling
    const normalizedSpeed = Math.min(1, (relativeSpeed - minCollisionSpeedForSound) / 4);
    const volume = Math.min(maxVolume, Math.max(minVolume, normalizedSpeed ** 3)); 

    audioManager.setVolume(volume);
    audioManager.playSound("coinCollision");
}



            const overlap = STRIKER_RADIUS + COIN_RADIUS - distance;
            const angle = Math.atan2(dy, dx);
            const normal = { x: Math.cos(angle), y: Math.sin(angle) };

            const strikerMass = 2; 
            const coinMass = 1;
            const totalMass = strikerMass + coinMass;

            coin.x += normal.x * (overlap * (strikerMass / totalMass));
            coin.y += normal.y * (overlap * (strikerMass / totalMass));
            newX -= normal.x * (overlap * (coinMass / totalMass));
            newY -= normal.y * (overlap * (coinMass / totalMass));

        
            coin.x = quantize(coin.x);
            coin.y = quantize(coin.y);
            newX = quantize(newX);
            newY = quantize(newY);

       
            const tangent = { x: -normal.y, y: normal.x };
            const strikerNormalSpeed =
                strikerVelocityRef.current.x * normal.x +
                strikerVelocityRef.current.y * normal.y;
            const strikerTangentSpeed =
                strikerVelocityRef.current.x * tangent.x +
                strikerVelocityRef.current.y * tangent.y;

            const coinNormalSpeed = coin.velocity.x * normal.x + coin.velocity.y * normal.y;
            const coinTangentSpeed = coin.velocity.x * tangent.x + coin.velocity.y * tangent.y;

            const energyLossFactor = 0.8; 
            const newStrikerNormalSpeed = coinNormalSpeed * energyLossFactor;
            const newCoinNormalSpeed = strikerNormalSpeed * energyLossFactor;

            const newStrikerVelocity = {
                x: newStrikerNormalSpeed * normal.x + strikerTangentSpeed * tangent.x,
                y: newStrikerNormalSpeed * normal.y + strikerTangentSpeed * tangent.y,
            };
            const newCoinVelocity = {
                x: newCoinNormalSpeed * normal.x + coinTangentSpeed * tangent.x,
                y: newCoinNormalSpeed * normal.y + coinTangentSpeed * tangent.y,
            };

            const minSeparationVelocity = 0.2;
            if (
                Math.abs(newStrikerVelocity.x - newCoinVelocity.x) < minSeparationVelocity &&
                Math.abs(newStrikerVelocity.y - newCoinVelocity.y) < minSeparationVelocity
            ) {
                const adjustment = 0.3; 
                newStrikerVelocity.x += adjustment * tangent.x;
                newStrikerVelocity.y += adjustment * tangent.y;
                newCoinVelocity.x -= adjustment * tangent.x;
                newCoinVelocity.y -= adjustment * tangent.y;
            }

           
            strikerVelocityRef.current = {
                x: quantize(newStrikerVelocity.x),
                y: quantize(newStrikerVelocity.y),
            };
            coin.velocity = {
                x: quantize(newCoinVelocity.x),
                y: quantize(newCoinVelocity.y),
            };
        } 
        
        return coin;
    });

  
    coinsRef.current = updatedCoins;

    setCoins([...updatedCoins]);
};



    
    
    
    
    
    
    useEffect(() => {
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0) {
            strikerVelocityRef.current = strikerVelocity;
        }
    }, [strikerVelocity]);
    

    
    
    const resolveCoinCollisions = (coinCollisionTracker) => {
        const updatedCoins = [...coinsRef.current];
        const quantize = (value, precision = 6) => Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
   
        updatedCoins.sort((a, b) => a.id - b.id);
    
        for (let i = 0; i < updatedCoins.length; i++) {
            const coinA = updatedCoins[i];
            if (coinA.isProcessed) continue;
    
            for (let j = i + 1; j < updatedCoins.length; j++) {
                const coinB = updatedCoins[j];
                if (coinB.isProcessed) continue; 
    
            const collisionKey = `coin${Math.min(coinA.id, coinB.id)}-coin${Math.max(coinA.id, coinB.id)}`;
           
            const now = Date.now();

            const dx = coinB.x - coinA.x;
            const dy = coinB.y - coinA.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (coinCollisionTracker.has(collisionKey)) {
                const lastCollisionTime = coinCollisionTracker.get(collisionKey);
                if (now - lastCollisionTime < coinCollisionCooldownMs && 
                    distance >= COIN_RADIUS * 2
                ) {
                    continue; 
                }
            }


    
               
    
                if (distance < COIN_RADIUS * 2) {
                    
                const isCoinAMoving = Math.abs(coinA.velocity.x) > minVelocityThreshold || Math.abs(coinA.velocity.y) > minVelocityThreshold;
                const isCoinBMoving = Math.abs(coinB.velocity.x) > minVelocityThreshold || Math.abs(coinB.velocity.y) > minVelocityThreshold;

                if (!isCoinAMoving && !isCoinBMoving) {
                continue; 
                }
                    coinCollisionTracker.set(collisionKey, now);

            
                  
const relativeVelocity = {
    x: coinA.velocity.x - coinB.velocity.x,
    y: coinA.velocity.y - coinB.velocity.y,
};
const relativeSpeed = Math.sqrt(relativeVelocity.x ** 2 + relativeVelocity.y ** 2);


const minCollisionSpeedForSound = 1.0; 
if (relativeSpeed > minCollisionSpeedForSound) {
    
    const minVolume = 0.05;
    const maxVolume = 1.0; 


    const normalizedSpeed = Math.min(1, (relativeSpeed - minCollisionSpeedForSound) / 4); // Normalize between 0 and 1
    const volume = Math.min(maxVolume, Math.max(minVolume, normalizedSpeed ** 3)); // Cubic scaling for sharper contrast

    audioManager.setVolume(volume);
    audioManager.playSound("coinCollision");
}


                    const overlap = COIN_RADIUS * 2 - distance;
    
                 
                    const angle = Math.atan2(dy, dx);
                    const normal = { x: Math.cos(angle), y: Math.sin(angle) };
    
                    coinA.x -= normal.x * (overlap / 2);
                    coinA.y -= normal.y * (overlap / 2);
                    coinB.x += normal.x * (overlap / 2);
                    coinB.y += normal.y * (overlap / 2);
    
                    coinA.x = quantize(coinA.x);
                    coinA.y = quantize(coinA.y);
                    coinB.x = quantize(coinB.x);
                    coinB.y = quantize(coinB.y);
    
                    const tangent = { x: -normal.y, y: normal.x };
                    const vA_normal = coinA.velocity.x * normal.x + coinA.velocity.y * normal.y;
                    const vA_tangent = coinA.velocity.x * tangent.x + coinA.velocity.y * tangent.y;
    
                    const vB_normal = coinB.velocity.x * normal.x + coinB.velocity.y * normal.y;
                    const vB_tangent = coinB.velocity.x * tangent.x + coinB.velocity.y * tangent.y;
    
                  
                    const energyLossFactor = 0.98; // 98% energy retained
                    const vA_normal_final = vB_normal * energyLossFactor;
                    const vB_normal_final = vA_normal * energyLossFactor;
    
                    const newVelocityA = {
                        x: vA_normal_final * normal.x + vA_tangent * tangent.x,
                        y: vA_normal_final * normal.y + vA_tangent * tangent.y,
                    };
                    const newVelocityB = {
                        x: vB_normal_final * normal.x + vB_tangent * tangent.x,
                        y: vB_normal_final * normal.y + vB_tangent * tangent.y,
                    };
    
                
                    const angularThreshold = 0.05;
                    const velocitySimilarity =
                        Math.abs(newVelocityA.x - newVelocityB.x) +
                        Math.abs(newVelocityA.y - newVelocityB.y);

                    
                    const bothMoving =
                    (Math.abs(newVelocityA.x) > minVelocityThreshold || Math.abs(newVelocityA.y) > minVelocityThreshold) &&
                    (Math.abs(newVelocityB.x) > minVelocityThreshold || Math.abs(newVelocityB.y) > minVelocityThreshold);
    
                    if (velocitySimilarity < angularThreshold && bothMoving) {
                        const angularAdjustment = 0.1;
                        newVelocityA.x += angularAdjustment * tangent.x;
                        newVelocityA.y += angularAdjustment * tangent.y;
                        newVelocityB.x -= angularAdjustment * tangent.x;
                        newVelocityB.y -= angularAdjustment * tangent.y;
                    }
    
                
                    coinA.velocity = {
                        x: quantize(newVelocityA.x),
                        y: quantize(newVelocityA.y),
                    };
                    coinB.velocity = {
                        x: quantize(newVelocityB.x),
                        y: quantize(newVelocityB.y),
                    };
                }
            }
        }
     
        coinsRef.current = updatedCoins;
    
        setCoins([...updatedCoins]);
    };
    
    
    
    

    
    





const handleStrikerFallIntoHole = (hole) => {
  

    isFallingRef.current = true;
    strikerVelocityRef.current = { x: 0, y: 0 };

    setStrikerPosition({
        x: hole.x,
        y: hole.y,
    });

    setIsFalling(true);
    audioManager.setVolume(0.5);
    audioManager.playSound("coinPocketed");
   
};





const applyPenalty = (game, currentPlayerId, isStrikerFellToHole = false) => {
    return new Promise((resolve) => {

if (isStrikerFellToHole){
    setIsFoulPopupVisible(true);

} else {
    setIsLastCoinPopupVisible(true);
}



    const penaltyCoins = [];

    if (game.pocketedPieces[currentPlayerId]?.length > 0) {
    
        const nonQueenCoins = game.pocketedPieces[currentPlayerId].filter(
            (coin) => coin.type !== "queen"
        );

        if (nonQueenCoins.length > 0) {
          
            const penalizedCoin = nonQueenCoins.pop();

           
            game.pocketedPieces[currentPlayerId] = game.pocketedPieces[currentPlayerId].filter(
                (coin) => coin.id !== penalizedCoin.id
            );
            if (penalizedCoin) {
                const LastKnownPosition = lastPositionsRef.current.get(penalizedCoin.type) || { x: 400, y: 150 };
              
               
           
                penalizedCoin.x = LastKnownPosition.x;
                penalizedCoin.y = LastKnownPosition.y;

    
            
                penalizedCoin.velocity = { x: 0, y: 0 };
                penalizedCoin.isProcessed = false;

                
            
                coinsRef.current.push(penalizedCoin);
             

                setCoins([...coinsRef.current]);
                setRenderTrigger((prev) => !prev);
                
                
              
                penaltyCoins.push(penalizedCoin);
                
            }
            
        }
    }

    if (penaltyCoins.length > 0) {
 
        setTimeout(() => {
           setIsFoulPopupVisible(false);
           setIsLastCoinPopupVisible(false);
           returnCoinsToCenter(penaltyCoins, game);          
        }, 2500);
    } else {
        setTimeout(() => {
            setIsFoulPopupVisible(false);
            setIsLastCoinPopupVisible(false);         
        }, 2500); 
    }
    setTimeout(() => {

        resolve(); 
    }, 3500); 
});
};


const endTurn = () => {

    coinsRef.current.forEach((coin) => {
        coin.isPocketedThisTurn = false;
    });
};



const returnCoinsToCenter = (coins, game) => {
    return new Promise((resolve) => {
    const centerX = 150;
    const centerY = 150;
    const safeDistance = COIN_RADIUS * 2 + 1; 

    let angleOffset = 0; 
    

    const quantize = (value, precision = 6) =>
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);


    coins.sort((a, b) => a.id - b.id);

    const findClearPosition = (targetX, targetY, isFirstCoin = false) => {
        if (isFirstCoin) {
            const overlaps = [...coinsRef.current.map((coin) => ({ x: coin.x, y: coin.y })), ...assignedPositions.current].some(
                (pos) => {
                    const dx = pos.x - targetX;
                    const dy = pos.y - targetY;
                    return Math.sqrt(dx * dx + dy * dy) < safeDistance;
                }
            );
            if (!overlaps) {
                const quantizedPosition = {
                    x: quantize(targetX),
                    y: quantize(targetY),
                };
                assignedPositions.current.push(quantizedPosition);
                return quantizedPosition;
            }
        }

        let radius = safeDistance; 
        let angle = angleOffset; 
        let x, y;
        let attempts = 0;

        while (attempts < 1500) {
            x = targetX + Math.cos(angle) * radius;
            y = targetY + Math.sin(angle) * radius;

            const overlaps = [...coinsRef.current.map((coin) => ({ x: coin.x, y: coin.y })), ...assignedPositions.current].some(
                (pos) => {
                    const dx = pos.x - x;
                    const dy = pos.y - y;
                    return Math.sqrt(dx * dx + dy * dy) < safeDistance;
                }
            );

            if (!overlaps) {
                break; 
            }

          
            angle += Math.PI / 6;
            if (angle >= 2 * Math.PI) {
                angle = 0;
                radius += safeDistance; 
            }
            attempts++;
        }

        if (attempts >= 1500) {
            console.warn("Max attempts reached in findClearPosition. Using fallback position.");
        }

        const quantizedPosition = {
            x: quantize(x),
            y: quantize(y),
        };
        assignedPositions.current.push(quantizedPosition);
        angleOffset += Math.PI / 6; 
        return quantizedPosition;
    };

    const destinations = coins.map((coin, index) =>
        findClearPosition(centerX, centerY, index === 0) 
    );


       
    const currentPlayerId = game.turn;
    const opponentId = game.userIds.find((id) => id !== currentPlayerId);

    requestAnimationFrame(() => {
        coins.forEach((coin, index) => {
            const destination = destinations[index];
           
            const adjustedDestination = adjustForRotation(destination, game);
           
            triggerCoinReturnAnimation(game, coin, adjustedDestination, () => {
                game.strikerBeenHit = true;
                
            });
    
            coin.x = destination.x;
            coin.y = destination.y;
            coin.velocity = { x: 0, y: 0 };
            coin.isProcessed = false;
           

            
        });

        setCoins([...coinsRef.current]); 
        setRenderTrigger((prev) => !prev);
    });
    setTimeout(() => {
        resolve(); 
    }, 3000);
});
   
};







    
const triggerCoinReturnAnimation = (game, coin, destination, onComplete) => {


    const coinElement = document.querySelector(`#coin-${coin.id}`); 
    if (!coinElement) {

        if (onComplete) onComplete(); 
        
        return;
    }


    coinElement.style.position = "absolute";


    coinElement.style.transform = "translate(-50%, -50%) scale(1)";
    coinElement.style.transition = "top 0.6s ease, left 0.6s ease";

    requestAnimationFrame(() => {
        coinElement.style.left = `${destination.x}px`;
        coinElement.style.top = `${destination.y}px`;
    });


    coinElement.addEventListener(
        "transitionend",
        () => {
         
            coinElement.style.transition = ""; 
            if (onComplete) onComplete();
        },
        { once: true }
    );
};




        

useEffect(() => {
    if (!game) return ;
    const handleMouseMove = (event) => {
        if (isDragging) handleDragMove(event, game);
        if (isTouchingStriker) handleStrikerTouchMove(event, game);
    };

    const handleMouseUp = () => {
        if (isDragging) handleDragEnd();
        if (isTouchingStriker) handleStrikerTouchEnd(game);
    };

    const preventDefaultTouch = (event) => {
       
        event.preventDefault(); 
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchmove", handleMouseMove, { passive: false });
    window.addEventListener("touchend", handleMouseUp, { passive: false });
    window.addEventListener("touchstart", preventDefaultTouch, { passive: false });

    return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);
        window.removeEventListener("touchstart", preventDefaultTouch);
    };
}, [isDragging, isTouchingStriker, game]);



    

        

    
  
    
    const adjustForRotation = (position, currentGame = game) => {
        const isSecondPlayer = currentGame?.playerColors[user._id] === "black";

        if (!isSecondPlayer) return position;
        return {
            x: 300 - position.x, 
            y: 300 - position.y, 
        };
    };

  
 
    



const renderPlayArea = () => {
    if (!game) return;

    



    const PlayerTurn = game.turn === user._id;
    

    const adjustedStrikerPosition = adjustForRotation(strikerPosition);

    return (
        <div className="carrom-board-wrapper">
            <div className="carrom-board-container">
                <div className="carrom-board-border">
                    <div className="carrom-board">
                        <div className="board-lines"></div>
                        
                        
                        {isFoulPopupVisible && (
                        <div className="striker-foul-popup">
                        <div className="striker-popup-content">
                        <div className="striker-popup-header">Foul!</div>
                        <div className="striker-popup-message">
                        Striker fell into the hole. Penalty applied.
                        </div>
                        </div>
                        </div>
                        )}

                        {isLastCoinPopupVisible && !game.strikerFell && (
                        <div className="striker-foul-popup">
                        <div className="striker-popup-content">
                        <div className="striker-popup-header">Foul!</div>
                        <div className="striker-popup-message">
                        Last opponent coin poketed. Penalty applied.
                        </div>
                        </div>
                        </div>
                        )}

                        {renderGameEndPopup()}
                        {renderLeaveGamePopup()}


                        {/* Render coins */}
                        {coinsRef.current.map((coin) => {
                            const highlightQueen =
                                coin.type === "queen" &&
                                game.turn === user._id &&
                                game.queenState === "onBoard" &&
                                (game.pocketedPieces[user._id]?.length || 0) === 8;

                            const adjustedPosition = adjustForRotation({ x: coin.x, y: coin.y });

                            return (
                                <img
                                    key={coin.id}
                                    id={`coin-${coin.id}`}
                                    src={`/carromAssets/${coin.type}-coin.png`}
                                    alt={`${coin.type} coin`}
                                    className={`carrom-coin ${highlightQueen ? "highlight-queen" : ""}`}
                                    style={{
                                        top: `${adjustedPosition.y}px`,
                                        left: `${adjustedPosition.x}px`,
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            );
                        })}

                        {/* Render striker animation */}
                        {PlayerTurn && isControllerActive && !isTransitioning && !isFalling && (
                            <div
                            className={`carrom-striker-animation ${
                                isDragging ? "carrom-small" : ""
                            } ${isOverlapping ? "carrom-overlap" : ""}`}
                            style={{
                                top: `${adjustedStrikerPosition.y}px`,
                                left: `${adjustedStrikerPosition.x}px`,
                            }}
                            >
                                <div className="carrom-arrow carrom-arrow-top"></div>
                                <div className="carrom-arrow carrom-arrow-bottom"></div>
                                <div className="carrom-arrow carrom-arrow-left"></div>
                                <div className="carrom-arrow carrom-arrow-right"></div>
                            </div>
                        )}

                        {/* Render striker */}
                        <img
                            src="/carromAssets/striker.png"
                            alt="Striker"
                            className={`carrom-striker ${isFalling ? "falling" : ""}`}
                            style={{
                                top: `${adjustedStrikerPosition.y}px`,
                                left: `${adjustedStrikerPosition.x}px`,
                                transform: "translate(-50%, -50%)",
                            }}
                            onMouseDown={(event) => handleStrikerTouchStart(event, game)}
                            onTouchStart={(event) => handleStrikerTouchStart(event, game)}
                        />

                        {/* Hit Circle */}
                        {isTouchingStriker && !timerExecuted && (
                            <div
                                className="carrom-hit-circle"
                                style={{
                                    top: `${adjustedStrikerPosition.y}px`,
                                    left: `${adjustedStrikerPosition.x}px`,
                                    width: `${22 * circleScale}px`,
                                    height: `${22 * circleScale}px`,
                                    transform: "translate(-50%, -50%)",
                                }}
                            ></div>
                        )}

                        {/* Hit Arrow */}
{isTouchingStriker && !timerExecuted && (
    <div
        className="carrom-hit-arrow-wrapper"
        style={{
            top: `${adjustedStrikerPosition.y}px`,
            left: `${adjustedStrikerPosition.x}px`,
            transform: `translate(0, -50%) rotate(${
                game?.playerColors[user._id] === "black" ? (hitArrow.angle + 180) % 360 : hitArrow.angle
            }deg)`,
            transformOrigin: "0% 50%",
        }}
    >
        {/* Parallel Arrow Lines */}
        <div
            className="carrom-hit-arrow-line"
            style={{
                width: `${hitArrow.length}px`,
                background: `linear-gradient(to right, gold, ${
                    hitStrengthRef.current > 140 ? "red" : "gold"
                })`,
                "--arrow-color": hitStrengthRef.current > 140 ? "red" : "gold", // Dynamic color for pseudo-element
            }}
        ></div>
        

       
    </div>
)}


                        {/* Render White Dots */}
                        {!timerExecuted && isTouchingStriker &&
    whiteDots.map((dot, index) => {
        const adjustedDot = adjustForRotation(dot);
        const size = 8 - index * 0.5; 
        return (
            <div
                key={`carrom-white-dot-${index}`}
                className="carrom-striker-dot"
                style={{
                    top: `${adjustedDot.y}px`,
                    left: `${adjustedDot.x}px`,
                    width: `${Math.max(size, 3)}px`, 
                    height: `${Math.max(size, 3)}px`, 
                    opacity: dot.opacity,
                }}
            ></div>
        );
    })}


                        {/* Render Drag Dots */}
                        {isTouchingStriker && !timerExecuted && 
                            dragDots.map((dot, index) => {
                            const adjustedDot = adjustForRotation(dot);
                            return (
                                <div
                                    key={`dot-${index}`}
                                    className="drag-dot"
                                    style={{
                                        position: "absolute",
                                        width: "6px",
                                        height: "6px",
                                        backgroundColor: "black",
                                        opacity: 0.6,
                                        borderRadius: "50%",
                                        top: `${adjustedDot.y}px`,
                                        left: `${adjustedDot.x}px`,
                                        transform: "translate(-50%, -50%)",
                                        boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Striker Controller */}
            <div
                className={`striker-controller ${PlayerTurn && isControllerActive ? "active" : "inactive"}`}
            >
                <div
                    className="striker-handle"
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                    style={{
                        left: `${
                            game?.playerColors[user._id] === "black" // Check if the player is the second player
                                ? controllerWidth - handleWidth - handlePosition // Mirror the position
                                : handlePosition
                        }px`
                    }}
                ></div>
            </div>
        </div>
    );
};


    
    
    
    
    
    


const renderPlayerArea = (game, user) => {
    if (!user) {
        return null; 
    }



    return (
        <div className={`carrom-player-area ${game.turn === user._id && !game.strikerBeenHit  ? 'highlighted waiting-for-move' : ''}`}>
            {renderCarromPlayerMessage()}
            <div className="carrom-player-avatar">
                {renderAvatar(user.nickname, user.photoUrl)}
                <svg className="Carrom-timer-circleV">
            <defs>
                <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <circle className="Carrom-base" cx="50%" cy="50%" r="45%" />
            <circle className="Carrom-timerP" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
        </svg>
            </div>

            <div className="carrom-player-info">
                <div className="carrom-name-and-flag">
                    <span className="carrom-player-name">{user.nickname}</span>
                    {user.country && (
                        <img
                            src={`/flags/${user.country.toLowerCase()}.svg`}
                            alt={`${user.country} flag`}
                            className="carrom-country-flag"
                        />
                    )}
                </div>
                <span className="carrom-player-score">Score: {user.score}</span>
                <span className="carrom-player-betAmmount">Bet: ${game?.betAmount}</span>
            </div>

            <div className="carrom-player-coins">
                {game.pocketedPieces[user._id]?.some(coin => coin.type === 'queen') && (
                    <div
                        className="carrom-coin-icon"
                        style={{
                            backgroundImage: `url(/carromAssets/queen-coin.png)`,
                            opacity: game.queenState === "secured" ? 1 : 0.3,
                        }}
                    ></div>
                )}
                <div
                    className="carrom-coin-icon"
                    style={{
                        backgroundImage: `url(/carromAssets/${game.playerColors[user._id]}-coin.png)`,
                    }}
                ></div>
                <div className="carrom-coin-count">
                    {game.pocketedPieces[user._id]?.filter(coin => coin.type === game.playerColors[user._id] && coin.type !== 'queen').length || 0}
                </div>
            </div>
            {renderCarromControlButtons()}
            
        </div>
    );
};

    


    
    // Render cancel popup
    const renderPopup = () => {
        if (ShowCancelPopup) {
            return (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Game Canceled</h3>
                        <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                        <button onClick={() => setShowCancelPopup(false)}>Close</button>
                    </div>
                </div>
            );
        }
    };

    if (!game) {
        return (
            <div className="carrom-container">
                <h2>Loading game...</h2>
            </div>
        );
    }








    


    
    return (
 
        <div className="carrom-container">

            


            {renderOpponentArea(game, user, opponents)}
            
            {renderPlayArea()}
            


            <div className="carrom-player-wrapper">
            {renderPlayerArea(game, user)}
            </div>


            {renderPopup()}
        
        </div>
    );
};

export default CarromGame;