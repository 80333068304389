class AudioManager {
    constructor() {
        this.sounds = {};           // Stores loaded sound buffers
        this.volume = 0.8;          // Default volume
        this.isSoundOn = true;      // Flag to manage sound state
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.isUserInteracted = false;  // To track if user has interacted
    }

    // Set whether the sound is on or off
    setSoundOn(isSoundOn) {
        this.isSoundOn = isSoundOn;
    }

    // Enable sound on first user interaction
    enableSoundOnUserInteraction() {
        if (!this.isUserInteracted) {
            const enableSound = () => {
                this.setSoundOn(true);
                this.audioContext.resume(); // Resume the audio context
                this.isUserInteracted = true;
            };
            window.addEventListener('click', enableSound, { once: true });
            window.addEventListener('touchstart', enableSound, { once: true });
        }
    }

    // Load sound if not already loaded
    async loadSound(name, path) {
        if (!this.sounds[name]) {
            const response = await fetch(path);
            const arrayBuffer = await response.arrayBuffer();
            const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
            this.sounds[name] = audioBuffer;
        }
    }

    // Play a sound with dynamic volume control
    playSound(name, volume = this.volume) {
        if (!this.isSoundOn || !this.sounds[name]) return;

        const source = this.audioContext.createBufferSource();
        source.buffer = this.sounds[name];

        // Create a GainNode to control the volume
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = volume; // Set the volume for this specific sound

        // Connect the source to the gain node and the gain node to the destination
        source.connect(gainNode);
        gainNode.connect(this.audioContext.destination);

        // Start playing the sound
        source.start(0);
    }

    // Set global volume (affects future sounds)
    setVolume(volume) {
        this.volume = volume;
    }

    // Preload all necessary sounds
    preloadSounds(soundPaths) {
        soundPaths.forEach(({ name, path }) => this.loadSound(name, path));
    }
}

const audioManager = new AudioManager();
export default audioManager;